import { useCallback, useState } from "react";
import axios from "axios";
import { EmailIdentitie } from "../types/api/emailIdentitie";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useEmailIdentitie = () => {
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [EmailIdentities, setEmailIdentities] = useState<Array<EmailIdentitie>>(
    []
  );

  const MAIL_TEMPLATE_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const emailIdentitieAdd = useCallback(
    (identityName: string, tenant: string) => {
      setLoading(true);
      var emailIdentitie = {
        email: identityName,
        tenant: tenant,
      };
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .post<any>(MAIL_TEMPLATE_MANAGER_URL + "/identitie", emailIdentitie, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (res) => {
            if (res.status === 200) {
              console.log(res.data);
              showMessage({ title: "追加しました。", status: "success" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .then(() => {
            getEmailIdentities();
          })
          .catch((msg) => {
            showMessage({
              title: `送信元アドレスが作成できません。(${msg})`,
              status: "error",
            });
            setLoading(false);
          });
      });
    },
    []
  );

  const emailIdentitieDelete = useCallback((IdentityName: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .delete<any>(MAIL_TEMPLATE_MANAGER_URL + "/identitie/" + IdentityName, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status === 204) {
            console.log(res.data);
            showMessage({ title: "削除しました。", status: "success" });
            setLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .then(() => {
          getEmailIdentities();
        })
        .catch((msg) => {
          showMessage({
            title: `送信元アドレスが削除できません(${msg})`,
            status: "error",
          });
          setLoading(false);
        });
    });
  }, []);

  const getEmailIdentities = useCallback(() => {
    setLoading(true);

    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant != "999999") {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .get<{ EmailIdentities: Array<EmailIdentitie> }>(
          MAIL_TEMPLATE_MANAGER_URL + "/identities" + atr,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setEmailIdentities(
            res.data["EmailIdentities"].sort((a, b) => {
              if (a.IdentityName.toLowerCase() > b.IdentityName.toLowerCase())
                return 1;
              else return -1;
            })
          );
        })
        .catch(() =>
          showMessage({
            title: "送信元アドレスを取得できません",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  return {
    getEmailIdentities,
    emailIdentitieAdd,
    emailIdentitieDelete,
    loading,
    EmailIdentities,
  };
};
