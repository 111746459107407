/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from "react";
import axios from "axios";

import { TenantInfo } from "../types/api/tenantTableTypes";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";
/*
curl -H "Content-Type: application/json" \
      -H "Authorization: Bearer eyJraWQiOiJMXC9mRlJQYzdWemdYOCtJZTM0eVdHVUZTWCtiRlN6M3kxRWl3Mkx4VVk1VT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlMDExYTg4Mi1lOTA0LTQ4NjQtYmQxZC0zOWUxNmYzZWY4MzAiLCJjdXN0b206dGllciI6IlN0YW5kYXJkIFRpZXIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtbm9ydGhlYXN0LTEuYW1hem9uYXdzLmNvbVwvYXAtbm9ydGhlYXN0LTFfeEFuRm5KVldyIiwiY29nbml0bzp1c2VybmFtZSI6InNvdWxmdWwubWFuLmpwQGdtYWlsLmNvbSIsImN1c3RvbTp0ZW5hbnRfaWQiOiJURU5BTlRkZjVmYTc1YzMxN2Q0NDFmOTgxODFjNzUzNDY1ZDA3ZCIsImdpdmVuX25hbWUiOiJrYXp1aGl0byIsIm9yaWdpbl9qdGkiOiJjY2UyYWY1NS1kNzI1LTQ4MGMtYThkNi0zYTMzZDJhYjhhYmEiLCJhdWQiOiIycXJwN2VzMGpyNGhnOTJ2aTRmZW5zZTIybCIsImV2ZW50X2lkIjoiNTNiOTQ2ZmUtZjg4NC00MjE1LTkyZjItMjFmOTM4N2I4MDIwIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MzgxMTkwNTIsImV4cCI6MTYzODEyMjY1MiwiY3VzdG9tOnJvbGUiOiJUZW5hbnRBZG1pbiIsImlhdCI6MTYzODExOTA1MiwiZmFtaWx5X25hbWUiOiJpa2VkYSIsImp0aSI6IjVhNDE1YjU2LWMxMTgtNDcwNi1hNzg3LWU2ZjQ5Y2Y5NGM3ZCIsImVtYWlsIjoic291bGZ1bC5tYW4uanBAZ21haWwuY29tIn0.AfsI6yPGGwnGkxwQIivYTibWPHFBVTohgKgHLLUFmfZGRBQYEfQCliCQ27_R29W2YD0PrCpxiTMeiiSJ4Nw-qFqNxTFVa14FTUqeLIo9Wp7S0XdcbujlF2FrrQvIAiOZ18YcppON4wm_GK66zTmMZjhMm4CnINuPSaOTePT0m_9eeUT5h2PVVFLnRnhiHgxMI4T6yyTwWZDBiUHR1txL81TskA9UhwWfUXtaUKYz-DP5OAb0K7chHBk6e8FpO4mH8iXyOPkZarkrp5VfHqlKjJz6gwNvRW2QGvwXe8dYAA0d322q6cneXe7nYsO4SfernalzI2maKQ3v59BE-4p57g"  \
       https://evb7iabjra.execute-api.ap-northeast-1.amazonaws.com/v1/users

[{
  "userName":"soulful.man.jp@gmail.com",
  "enabled":true,
  "confirmedStatus":"CONFIRMED",   <-FORCE_CHANGE_PASSWORD
  "dateCreated":"2021-11-26T09:06:42.498Z",
  "tier":"Standard Tier",
  "firstName":"kazuhito",
  "role":"TenantAdmin",
  "lastName":"ikeda"}]

*/

export const useAllTenants = () => {
  const { showMessage } = useMessage();
  const { loginUser, tokenRefresh } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [tenants, setTenants] = useState<Array<TenantInfo>>([]);

  const TENANTS_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getTenants = useCallback(() => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<Array<TenantInfo>>(TENANTS_MANAGER_URL + "/tenants", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (loginUser?.isAccountAdmin) {
            setTenants(res.data);
          } else if (loginUser?.isAdmin) {
            setTenants(
              res.data.filter((obj) => {
                return loginUser.tenant === obj.tenant_id;
              })
            );
          }
        })
        .catch((e: any) => {
          if (e.response && e.response.status === 403) {
            showMessage({
              title: "権限エラー:" + e.response.data,
              status: "error",
            });
          } else {
            showMessage({
              title: "テナント取得に失敗しました",
              status: "error",
            });
          }
        })
        .finally(() => setLoading(false));
    });
  }, []);

  return { getTenants, loading, tenants };
};
