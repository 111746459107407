import { ChangeEvent, memo, useEffect, useState, FC, useRef } from "react";
import { FormControl, FormLabel, HStack, Radio, RadioGroup, Select, Spinner, Stack } from "@chakra-ui/react";

import { PageDiscription } from "../../atoms/button/PageDiscription";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { useCustomMetrics } from "../../../hooks/useCustomMetrics";
import { ApexOptions } from "apexcharts";
import { DatapointsDrawer } from "../../molecules/DatapointsDrawer";
import { Metric } from "../../../types/api/customMetric";

export const MetricConvertCountEntityType: FC = memo(() => {
  const { getMetrics, getConvertCountMetric, metrics, datapoints, loading } = useCustomMetrics();
  const { loginUser, selectTenant } = useLoginUser();

  type StatisticType = "Average" | "Sum" | "Minimum" | "Maximum";
  const statistics: StatisticType[] = ["Sum"];
  const [series, setSeries] = useState<ApexAxisChartSeries>([{ name: "", data: [{ x: "yyyy-mm-dd", y: 0 }] }]);
  // Define the new MetricWithKey type
  type MetricWithKey = Metric & {
    Key: string;
  };
  const [selectMetric, setSelectMetric] = useState<MetricWithKey | undefined>(undefined);
  const [entitytypemetrics, setEntityTypeMetrics] = useState<Array<MetricWithKey>>([]);

  const didLogRef = useRef(false);
  const [periodset, setPeriodset] = useState<"minit" | "5minits" | "hour" | "day">("5minits");
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      let tenant_id: string = "";
      if (selectTenant) {
        tenant_id = selectTenant;
      } else {
        if (loginUser) tenant_id = loginUser.tenant;
      }

      getMetrics();
    }
  }, []);

  // Bucket の選択　または、メトリクスの変更時に
  useEffect(() => {
    if (selectMetric) {
      const endDate = new Date();
      const startDate = new Date();
      let period: number = 300;
      if (periodset === "minit") period = 60;
      else if (periodset === "5minits") period = 300;
      else if (periodset === "hour") period = 60 * 60;
      else if (periodset === "day") period = 60 * 60 * 24;
      startDate.setDate(endDate.getDate() - (period * 1440) / (60 * 60 * 24));
      getConvertCountMetric(
        selectMetric?.Dimensions.find((dimension) => dimension.Name === "Tenant")?.Value || "",
        selectMetric?.Dimensions.find((dimension) => dimension.Name === "EntityType")?.Value,
        selectMetric?.Dimensions.find((dimension) => dimension.Name === "EntityId")?.Value,
        selectMetric?.Dimensions.find((dimension) => dimension.Name === "DriverName")?.Value,
        startDate.toISOString(),
        endDate.toISOString(),
        period
      );
    }
  }, [selectMetric, periodset]);
  const onChangeMetric = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectMetric = entitytypemetrics?.find((metricWithKey) => metricWithKey.Key === e.target.value);
    setSelectMetric(selectMetric);
  };

  useEffect(() => {
    if (metrics) {
      const filteredMetricsWithKey: MetricWithKey[] = metrics
        .filter(
          (metric) =>
            metric.Dimensions.length === 2 &&
            metric.Dimensions.every((dimension) => dimension.Name === "Tenant" || dimension.Name === "EntityType")
        )
        .map((metric, index) => ({
          ...metric,
          Key: metric.Dimensions.find((dimension) => dimension.Name === "EntityType")?.Value || index.toString(),
        }));
      setEntityTypeMetrics(filteredMetricsWithKey);
    }
  }, [metrics]);
  // データ取得後に、グラフ用のデータを編集
  useEffect(() => {
    if (datapoints) {
      setSeries(
        statistics.map((statistic) => {
          const data: { x: string; y: number }[] = datapoints.map((datapoint) => {
            const point = {
              x: datapoint.Timestamp,
              y: datapoint[statistic] || 0,
            };
            if (statistic in datapoint) {
              if (datapoint.Unit === "Bytes") {
                point.y = Math.ceil((datapoint[statistic] || 0 * 10) / (1024 * 1024)) / 10;
              } else {
                point.y = datapoint[statistic] || 0;
              }
            } else {
              point.y = 0;
            }

            return point;
          });
          return { name: statistic, data: data };
        })
      );
    }
  }, [datapoints]);

  const options: ApexOptions = {
    chart: {
      id: "chart",
      toolbar: {
        export: {
          csv: {
            columnDelimiter: ',',
            headerCategory: 'datetime',
            dateFormatter(timestamp: number) {
              return new Date(timestamp).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
            }
          },
        },
      },
      animations:{
        enabled: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false, // 表示をJSTにする
        datetimeFormatter: {
          year: 'yyyy年',
          month: 'M月',
          day: 'M/d',
          hour: 'H:mm',
          minute: 'H:mm',
        },
        rotate: -15,
        rotateAlways: true,
      },
    },
    yaxis:{
      min: 0,
    },
    tooltip: {
      x: { format: 'yyyy/M/d H:mm' },
    },
  };

  return (
    <Stack maxW={"1200px"}>
      <PageDiscription>エンティティタイプを選択してください。</PageDiscription>
      <FormControl>
        <HStack>
          <FormLabel whiteSpace={"nowrap"}>エンティティタイプ</FormLabel>
          {loading ? (
            <Spinner key="deviceSpinner" color="teal.500" />
          ) : (
            <Select
              onChange={onChangeMetric}
              bg="gray.50"
              value={selectMetric ? selectMetric.Key : ""}
              disabled={loading}
            >
              <option value={""}></option>
              {entitytypemetrics?.map((metric) => (
                <option key={metric.Key} value={metric.Key}>
                  {metric.Key}
                </option>
              ))}
            </Select>
          )}
        </HStack>
      </FormControl>
      <FormControl>
        <RadioGroup
          onChange={(nextValue: "minit" | "5minits" | "hour" | "day") => setPeriodset(nextValue)}
          value={periodset}
        >
          <HStack flexWrap="wrap" spacing="5">
            <Radio value="minit">1分間隔</Radio>
            <Radio value="5minits">5分間隔</Radio>
            <Radio value="hour">１時間間隔</Radio>
            <Radio value="day">１日間隔</Radio>
          </HStack>
        </RadioGroup>
      </FormControl>
      <DatapointsDrawer
        loadingDatapoints={loading}
        datapoints={datapoints}
        options={options}
        series={series}
        statistics={statistics}
      ></DatapointsDrawer>
    </Stack>
  );
});
