/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from "react";

import { TenantInfo } from "../types/api/tenantTableTypes";
import { useMessage } from "./useMessage";

type Props = {
  tenant_id: string;
  tenants: Array<TenantInfo>;
  onOpen: () => void;
};

// 選択したユーザー情報を特定しモーダルを表示するカスタムフック
export const useSelectTenant = () => {
  const { showMessage } = useMessage();

  const [selectedTenant, setSelectedTenant] = useState<TenantInfo>();

  const onSelectTenant = useCallback((props: Props) => {
    const { tenant_id, tenants, onOpen } = props;
    const targetTenant = tenants.find((obj) => obj.tenant_id === tenant_id);
    if (!targetTenant) {
      if (tenant_id === "") {
        setSelectedTenant(targetTenant);
        onOpen();
      } else {
        showMessage({ title: "テナントが見つかりません", status: "error" });
        return;
      }
    } else {
      setSelectedTenant(targetTenant);
      onOpen();
    }
  }, []);
  return { onSelectTenant, selectedTenant };
};
