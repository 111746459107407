import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  Box,
  Flex,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";

import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { useConfirm } from "../../../hooks/useConfirm";
import { HeaderLayout } from "../../templates/HeaderLayout";
import { useMessage } from "../../../hooks/useMessage";

export const Confirm: FC = memo(() => {
  const params = useParams();
  const userName: string = params.id ? params.id : "";

  const { signIn, confirmPassword, loading } = useConfirm();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const location = useLocation();
  const state = location.state as {forgotPW: boolean}; //型を無理やり与える

  const { showMessage } = useMessage();

  const onChangeCurrentPassword = (e: ChangeEvent<HTMLInputElement>) =>
    setCurrentPassword(e.target.value);

  const onChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) =>
    setNewPassword(e.target.value);

  const onChangeNewPassword2 = (e: ChangeEvent<HTMLInputElement>) =>
  setNewPassword2(e.target.value);

  const onClickConfirm = () => {
    if(newPassword !== newPassword2){
      showMessage({title:"新パスワードが一致しません。",status: "error" });
    }else{
      if(state.forgotPW){
        confirmPassword(userName, currentPassword, newPassword);
      }else{
        signIn(userName, currentPassword, newPassword);
      }
    }
  };

  return (
    <HeaderLayout>
    {
      <Flex alignItems="center" justifyContent="center" mt={4}>
        <Box bg="white" w="sm" p={4} borderRadius="md" shadow="md">
          <Heading as="h1" size={{ base: "md", md: "lg" }} textAlign="center" m={{ base: "2", md: "4" }}>
            パスワード変更
          </Heading>
          <Stack spacing={2} py={4} px={4}>
            <Box>
              <FormLabel>仮パスワード</FormLabel>
              <Input
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={onChangeCurrentPassword}
              />
            </Box>
            <Box>
              <FormLabel>新パスワード</FormLabel>
              <Input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={onChangeNewPassword}
              />
            </Box>
            <Box>
              <FormLabel>新パスワード（再入力）</FormLabel>
              <Input
                type="password"
                placeholder="Confirm New Password"
                value={newPassword2}
                onChange={onChangeNewPassword2}
              />
            </Box>
            <Stack pt={10}>
              <PrimaryButton
                disabled={newPassword === "" || currentPassword === ""}
                isLoading={loading}
                onClick={onClickConfirm}
                type="button"
              >
              登録
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Flex>
    }
    </HeaderLayout>
  );
});
