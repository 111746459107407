/* eslint-disable react-hooks/exhaustive-deps */
import { memo, FC } from "react";
import { Flex, HStack, Spacer, Text } from "@chakra-ui/react";

import appconfig from "../../../config_app.json";

export const Footer: FC = memo(() => {
  const SYSTEM_VERSION: string = appconfig["SYSTEM_VERSION"];
  const SYSTEM_COPYRIGHT: string = appconfig["SYSTEM_COPYRIGHT"];

  return (
    <Flex>
      <HStack w="100%">
        <Text fontSize="sm">{SYSTEM_COPYRIGHT}</Text>
        <Spacer />
        <Text fontSize="sm">{SYSTEM_VERSION}</Text>
      </HStack>
    </Flex>
  );
});
