import { memo, FC } from "react";
import { Box, Spacer, Stack, Text } from "@chakra-ui/react";

type Props = {
  id: string;
  name: string;
  description?: string;
  enabled?: string;
  onClick: (id: string) => void;
};

export const ApiKeyCard: FC<Props> = memo((props) => {
  const { id, name, description, enabled, onClick } = props;
  let apiKeyenabled:Boolean = true
  if(enabled === "False")
    apiKeyenabled = false;
  return (
    <Box
      w={{
        base: "calc(100vw - 60px)",
        md: "calc(50vw - 60px)",
        lg: "calc(33vw - 50px)",
        xl: "calc(25vw - 50px)",
      }}
      minHeight="85px"
      bg={enabled ? "white" : "blackAlpha.100"}
      borderRadius="10px"
      shadow="md"
      p={4}
      _hover={{ cursor: "pointer", opacity: 0.8 }}
      onClick={() => onClick(id)}
    >
      <Stack textAlign="left">
        <Text fontSize="md" fontWeight="bold">
          {name}
        </Text>
        <Text fontSize="sm" color="gray">
          {description}
        </Text>
        <Spacer />
        <Text fontSize="sm" color="gray">
          {enabled ? "" : "無効"}
        </Text>
      </Stack>
    </Box>
  );
});
