import { memo, FC } from "react";
import { Link, Td, Tr } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

type Props = {
  group_id: string;
  title: string;
  description: string;
  users_count: string;
  onClick: (group_id: string) => void;
};

export const GroupRow: FC<Props> = memo((props) => {
  const { group_id, title, description, users_count, onClick } = props;

  return (
    <Tr key={group_id} bg="gray.50">
      <Td>
        <Link onClick={() => onClick(group_id)} isExternal>
          {title}
          <EditIcon mx="2" color={"gray"} fontSize="sm" />
        </Link>
      </Td>
      <Td fontSize={"sm"}>{description}</Td>
      <Td>{users_count}</Td>
    </Tr>
  );
});
