import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  Badge,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spacer,
  Spinner,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import quotasconfig from "../../../quotas.json";
import { UsagePlan, KeyInfo } from "../../../types/api/usageplan";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { ApiKey } from "../../../types/api/apikey";
import { useUsagePlan } from "../../../hooks/useUsagePlan";

type Props = {
  usagePlan: UsagePlan;
  usagePlanKeys: KeyInfo[];
  isOpen: boolean;
  isAdmin?: boolean;
  onInsert: (usagePlan: UsagePlan) => void;
  onUpdate: (usagePlan: UsagePlan) => void;
  onDelete: (id: string) => void;
  onInsertKey: (usagePlanId: string, keyId: string) => void;
  onDeleteKey: (usagePlanId: string, keyId: string) => void;
  onClose: () => void;
  tenantApiKeys: ApiKey[];
  loadingUsagePlan: boolean;
  loadingApiKey: boolean;
};

export const UsagePlanDetailModal: FC<Props> = memo((props) => {
  const {
    isOpen,
    onClose,
    usagePlan,
    tenantApiKeys,
    onInsertKey,
    onDeleteKey,
    usagePlanKeys,
    loadingUsagePlan,
    loadingApiKey,
  } = props;

  const { getUsage, usage, loadingUsage } = useUsagePlan(onClose);
  const isAddMode: boolean = usagePlan.id ? false : true;

  const [id, setId] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string | undefined>("");
  const [throttle, setThrottle] = useState<boolean>(false);
  const [burstLimit, setBurstLimit] = useState<number | undefined>(undefined);
  const [rateLimit, setRateLimit] = useState<number | undefined>(undefined);
  const [quota, setQuota] = useState<boolean>(false);
  const [limit, setLimit] = useState<number | undefined>(undefined);
  const [offset, setOffset] = useState<number | undefined>(undefined);
  const [period, setPeriod] = useState<"DAY" | "WEEK" | "MONTH" | undefined>(
    undefined
  );

  useEffect(() => {
    onModalReset();
    if (usagePlan.id) getUsage(usagePlan.id);
  }, [usagePlan]);

  const onChangeId = (e: ChangeEvent<HTMLInputElement>) =>
    setId(e.target.value);

  const onChangePeriod = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "DAY") {
      setPeriod("DAY");
    } else if (e.target.value === "WEEK") {
      setPeriod("WEEK");
    } else if (e.target.value === "MONTH") {
      setPeriod("MONTH");
    } else {
      setPeriod(undefined);
    }
  };

  const onClickUpdate = () => {
    if (id) {
      usagePlan.name = name;
      if (description) {
        if (description !== "") {
          usagePlan.description = description;
        } else {
          delete usagePlan.description;
        }
      } else {
        delete usagePlan.description;
      }
      if (throttle) {
        usagePlan.throttle = {
          burstLimit: 0,
          rateLimit: 20,
        };
        if (burstLimit) usagePlan.throttle.burstLimit = burstLimit;
        if (rateLimit) usagePlan.throttle.rateLimit = rateLimit;
      } else {
        delete usagePlan.throttle;
      }
      if (quota) {
        usagePlan.quota = {
          limit: 10000,
          offset: 0,
          period: "DAY",
        };
        if (limit) usagePlan.quota.limit = limit;
        if (offset) usagePlan.quota.offset = offset;
        if (period) usagePlan.quota.period = period;
      } else {
        delete usagePlan.quota;
      }
      props.onUpdate(usagePlan);
      props.onClose();
    }
  };

  const onClickCreate = () => {
    usagePlan.name = name;
    if (description) {
      if (description !== "") {
        usagePlan.description = description;
      } else {
        delete usagePlan.description;
      }
    } else {
      delete usagePlan.description;
    }
    if (throttle) {
      usagePlan.throttle = {
        burstLimit: 0,
        rateLimit: 20,
      };
      if (burstLimit) usagePlan.throttle.burstLimit = burstLimit;
      if (rateLimit) usagePlan.throttle.rateLimit = rateLimit;
    } else {
      delete usagePlan.throttle;
    }
    if (quota) {
      usagePlan.quota = {
        limit: 10000,
        offset: 0,
        period: "DAY",
      };
      if (limit) usagePlan.quota.limit = limit;
      if (offset) usagePlan.quota.offset = offset;
      if (period) usagePlan.quota.period = period;
    } else {
      delete usagePlan.quota;
    }
    props.onInsert(usagePlan);
    props.onClose();
  };

  const onClickDelete = () => {
    if (id) {
      if (window.confirm(`「${usagePlan.name}（${usagePlan.id}）」を削除しますか？`)) {
        props.onDelete(id);
        props.onClose();
      }
    }
  };
  const onClickListAdd = (keyId: string) => {
    if (keyId === "") return;
    if (usagePlan.id) {
      onInsertKey(usagePlan.id, keyId);
    }
  };
  const onClickListDelete = (keyId: string) => {
    if (keyId === "") return;
    if (usagePlan.id) {
      onDeleteKey(usagePlan.id, keyId);
    }
  };

  const onModalReset = () => {
    if (usagePlan) {
      setId(usagePlan.id ?? undefined);
      setName(usagePlan.name ?? "");
      setDescription(usagePlan.description ?? "");
      if (usagePlan.throttle) {
        setThrottle(true);
        setBurstLimit(usagePlan.throttle.burstLimit);
        setRateLimit(usagePlan.throttle.rateLimit);
      } else {
        setThrottle(false);
        setBurstLimit(undefined);
        setRateLimit(undefined);
      }
      if (usagePlan.quota) {
        setQuota(true);
        setLimit(usagePlan.quota.limit);
        setOffset(usagePlan.quota.offset);
        setPeriod(usagePlan.quota.period);
      } else {
        setQuota(false);
        setLimit(undefined);
        setOffset(undefined);
        setPeriod(undefined);
      }
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>使用量プラン詳細</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Tabs>
            <TabList>
              <Tab>詳細</Tab>
              <Tab>制限設定</Tab>
              {!isAddMode && (<Tab>API</Tab>)}
              {!isAddMode && (<Tab>API Key</Tab>)}
              {!isAddMode && (<Tab>Usage</Tab>)}
            </TabList>
            {loadingUsagePlan ? (
              <Center h="50vh">
                <Spinner color="teal.500" />
              </Center>
            ) : (
              <TabPanels>
                <TabPanel>
                  <Stack spacing={4}>
                    <FormControl>
                      <FormLabel>
                        ID
                        <Badge colorScheme="gray" ml={2}>
                          変更不可
                        </Badge>
                      </FormLabel>
                      <Input
                        readOnly={true}
                        value={id}
                        placeholder="ID"
                        onChange={onChangeId}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>
                        Name (名前)
                        <Badge colorScheme="red" ml={2}>
                          必須
                        </Badge>
                      </FormLabel>
                      <Input
                        value={name}
                        placeholder="name"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setName(e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Description (説明)</FormLabel>
                      <Input
                        value={description}
                        placeholder="description"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setDescription(e.target.value)
                        }
                      />
                    </FormControl>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack spacing={4}>
                    <Checkbox
                      isChecked={throttle}
                      onChange={(e) => setThrottle(e.target.checked)}
                    >
                      スロットリングの有効化
                    </Checkbox>
                    <FormControl isDisabled={!throttle}>
                      <FormLabel>レート(1 秒あたりのリクエスト数)</FormLabel>
                      <NumberInput
                        value={rateLimit}
                        min={0}
                        max={1000}
                        onChange={(valueString) =>
                          setRateLimit(parseInt(valueString))
                        }
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                    <FormControl isDisabled={!throttle}>
                      <FormLabel>バースト</FormLabel>
                      <NumberInput
                        value={burstLimit}
                        min={0}
                        max={1000}
                        onChange={(valueString) =>
                          setBurstLimit(parseInt(valueString))
                        }
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                    <Checkbox
                      isChecked={quota}
                      onChange={(e) => setQuota(e.target.checked)}
                    >
                      クォータを有効にする
                    </Checkbox>
                    <FormControl isDisabled={!quota}>
                      <FormLabel>リクエスト数</FormLabel>
                      <NumberInput
                        value={limit}
                        min={0}
                        max={quotasconfig.usageplan.quota.limit}
                        onChange={(valueString) =>
                          setLimit(parseInt(valueString))
                        }
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                    <FormControl isDisabled={!quota}>
                      <FormLabel>offset</FormLabel>
                      <NumberInput
                        value={offset}
                        min={0}
                        max={20}
                        onChange={(valueString) =>
                          setOffset(parseInt(valueString))
                        }
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                    <FormControl isDisabled={!quota}>
                      <FormLabel>期間</FormLabel>
                      <Select value={period} onChange={onChangePeriod}>
                        <option value={undefined}>未設定</option>
                        <option value={"DAY"}>日</option>
                        <option value={"WEEK"}>週</option>
                        <option value={"MONTH"}>月</option>
                      </Select>
                    </FormControl>
                  </Stack>
                  <HStack mt={4}>
                    <Spacer />
                    {isAddMode ? (
                      <PrimaryButton
                        disabled={id === undefined}
                        onClick={onClickCreate}
                      >
                        追加
                      </PrimaryButton>
                    ) : (
                      <>
                        <PrimaryButton onClick={onClickUpdate}>
                          更新
                        </PrimaryButton>
                        <PrimaryButton onClick={onClickDelete}>
                          削除
                        </PrimaryButton>
                      </>
                    )}
                  </HStack>
                </TabPanel>
                <TabPanel>
                  <Stack>
                    <FormControl>
                      {/**
                      <HStack>
                        <Input type="email" value={userName} placeholder="Mailアドレスを入力" onChange={onChangeInput} />
                        <PrimaryButton onClick={()=>onClickListAdd(userName)}>追加</PrimaryButton>
                      </HStack>                     
                       */}
                      <Flex fontSize={"sm"} m={1}>
                        設定API
                      </Flex>
                      <Flex
                        border={"solid"}
                        borderWidth={1}
                        borderColor={"gray.300"}
                      >
                        <TableContainer>
                          <Table size={"sm"}>
                            <Thead>
                              <Tr>
                                <Th>api Id</Th>
                                <Th>stage</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {usagePlan.apiStages.map((cell, i) => (
                                <Tr key={i}>
                                  <Td>{cell.apiId}</Td>
                                  <Td>{cell.stage}</Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Flex>
                    </FormControl>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  {loadingApiKey ? (
                    <Center h="50vh">
                      <Spinner color="teal.500" />
                    </Center>
                  ) : (
                  <Stack>
                    <FormControl>
                      {/**
                      <HStack>
                        <Input type="email" value={userName} placeholder="Mailアドレスを入力" onChange={onChangeInput} />
                        <PrimaryButton onClick={()=>onClickListAdd(userName)}>追加</PrimaryButton>
                      </HStack>                     
                       */}
                      <Flex fontSize={"sm"} m={1}>
                        選択中のAPI Key
                      </Flex>
                      <Flex
                        border={"solid"}
                        borderWidth={1}
                        borderColor={"gray.300"}
                      >
                        <TableContainer>
                          <Table size={"sm"}>
                            <Thead>
                              <Tr>
                                <Th textAlign={"center"}>削除</Th>
                                <Th>Id</Th>
                                <Th>name</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {usagePlanKeys.map((cell, i) => (
                                <Tr key={i}>
                                  <Td textAlign={"center"}>
                                    <IconButton
                                      onClick={() => onClickListDelete(cell.id)}
                                      variant="outline"
                                      colorScheme="red"
                                      aria-label="削除"
                                      size={"xs"}
                                      icon={<CloseIcon />}
                                    />
                                  </Td>
                                  <Td>{cell.id}</Td>
                                  <Td>{cell.name}</Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Flex>
                      <Flex fontSize={"sm"} m={1} mt={3}>
                        他のAPI Key
                      </Flex>
                      <Flex
                        border={"solid"}
                        borderWidth={1}
                        borderColor={"gray.300"}
                      >
                        <TableContainer>
                          <Table size={"sm"}>
                            <Thead>
                              <Tr>
                                <Th textAlign={"center"}>追加</Th>
                                <Th>Id</Th>
                                <Th>name</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {tenantApiKeys.map((cell, i) => (
                                <Tr key={usagePlanKeys.length + i}>
                                  {usagePlanKeys.find((k) => {
                                    return k.id === cell.id;
                                  }) ? (
                                    <></>
                                  ) : (
                                    <>
                                      <Td textAlign={"center"}>
                                        <IconButton
                                          onClick={() => onClickListAdd(cell.id)}
                                          variant="outline"
                                          colorScheme="green"
                                          aria-label="追加"
                                          size={"xs"}
                                          icon={<AddIcon />}
                                        />
                                      </Td>
                                      <Td>{cell.id}</Td>
                                      <Td>{cell.name}</Td>
                                    </>
                                  )}
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Flex>
                    </FormControl>
                  </Stack>
                )}
                </TabPanel>
                <TabPanel>
                  <>
                  {loadingUsage ? (
                      <Center h="50vh">
                        <Spinner color="teal.500" />
                      </Center>
                    ) : (
                      <Textarea
                        value={JSON.stringify(usage??null, null, 2)}
                        readOnly={true}
                        rows={20}
                        border="1px"
                        borderColor={"gray.400"}
                      />
                  )}
                  </>
                </TabPanel>
              </TabPanels>
            )}
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
