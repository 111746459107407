import { useCallback, useState } from "react";
import axios from "axios";

import { useMessage } from "./useMessage";
import appconfig from "../config.json";
import { useLoginUser } from "./useLoginUser";
import { EntityTypePtn } from "../types/api/parameterTypes";

export const useParameters = () => {
  const { tokenRefresh } = useLoginUser();
  const { showMessage } = useMessage();
  const [deviceNames, setDeviceNames] = useState<Array<string>>([]);
  const [typeNames, setTypeNames] = useState<Array<string>>([]);
  const [typePtns, setTypePtns] = useState<Array<EntityTypePtn>>([]);
  const [parameter, setParameter] = useState<string>();
  const [loading, setLoading] = useState(false);

  const PARMS_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getDeviceNames = useCallback(() => {
    setLoading(true);
    type DeviceParam = {
      DeviceName: string;
    };
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<Array<DeviceParam>>(PARMS_MANAGER_URL + "/parameters/device", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          var names = res.data.map((val) => {
            return val["DeviceName"];
          });
          setDeviceNames(names);
          setLoading(false);
        })
        .catch(() => {
          showMessage({
            title: "パラメータの取得に失敗しました",
            status: "error",
          });
          setLoading(false);
        });
    });
  }, []);

  const getTypeNames = useCallback(() => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<Array<EntityTypePtn>>(
          PARMS_MANAGER_URL + "/parameters/entitytype",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          var names = res.data.map((val) => {
            return val["TypeName"];
          });
          setTypePtns(res.data);
          setTypeNames(names);
          setLoading(false);
        })
        .catch(() => {
          showMessage({
            title: "パラメータの取得に失敗しました",
            status: "error",
          });
          setLoading(false);
        });
    });
  }, []);

  const getParameter = useCallback((path: string) => {
    setLoading(true);
    if (path) {
      type Param = {
        Value: string;
      };

      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .get<Param>(PARMS_MANAGER_URL + `/parameter?path=${path}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setParameter(res.data.Value);
            setLoading(false);
          })
          .catch(() => {
            showMessage({
              title: "パラメータの取得に失敗しました",
              status: "error",
            });
            setParameter(undefined);
            setLoading(false);
          });
      });
    } else {
      setParameter(undefined);
    }
  }, []);

  return {
    getDeviceNames,
    getTypeNames,
    getParameter,
    deviceNames,
    typeNames,
    typePtns,
    parameter,
    loading,
  };
};
