import { useCallback, useState } from "react";
import axios from "axios";

import { Customer,ApiKey } from "../types/api/devPortal";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useDevPortal = (onClose: () => void) => {
  const { showMessage } = useMessage();
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<Array<Customer>>([]);
  const [apiKeys, setApiKeys] = useState<Array<ApiKey>>([]);
  const API_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  type IPostResponse = {
    access?: string;
    token?: string;
    newPasswordRequired?: string;
  };

  const getCustomers = useCallback(() => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      axios
        .get<Array<Customer>>(API_MANAGER_URL + "/devportal/customers" + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) =>
          setCustomers(
            res.data.sort((a, b) => {
              if (a.DateRegistered > b.DateRegistered) return 1;
              else return -1;
            })
          )
        )
        .catch(() =>
          showMessage({ title: "developer-portal情報の取得に失敗しました", status: "error" })
        )
        .finally(() => setLoading(false));
    });
  }, []);
  const getApiKeys = useCallback(() => {
    setLoading(true);
    new Promise((resolve)=>resolve(tokenRefresh()))
    .then((token)=>{
      let atr:string = ""
      axios
        .get<Array<ApiKey>>(API_MANAGER_URL + "/devportal/apikeys" + atr, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => setApiKeys(res.data.sort((a,b)=>{if(a.name.toLowerCase() > b.name.toLowerCase())return 1;else return -1;})))
        .catch(() =>
          showMessage({ title: "ApiKeyの取得に失敗しました", status: "error" })
        )
        .finally(() => setLoading(false));      
    })
  }, []);

  const apiKeyEdit = useCallback(
    (apiKey: ApiKey) => {
      setLoading(true);

      new Promise((resolve)=>resolve(tokenRefresh()))
      .then((token)=>{
        axios
          .put<any>(API_MANAGER_URL + "/devportal/apikey/" + apiKey.id, apiKey, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(async (res) => {
            if (res.status === 204) {
              showMessage({ title: "更新しました。", status: "success" });
              setLoading(false);
            } else {
              showMessage({ title: res.statusText, status: "error" });
              setLoading(false);
            }
          })
          .then(()=>{
            getApiKeys();
            onClose();
          })
          .catch(() => {
            showMessage({ title: "ユーザが更新できません", status: "error" });
            setLoading(false);
          });
        })
    },
    []
  );


  return {
    getCustomers,
    getApiKeys,
    apiKeyEdit,
    loading,
    customers,
    apiKeys
  };
};
