export enum ExecutionStatusType {
    RUNNING='RUNNING',
    SUCCEEDED='SUCCEEDED',
    FAILED='FAILED',
    TIMED_OUT='TIMED_OUT',
    ABORTED='ABORTED',  
};

export type Execution = 
{
    executionArn: string,
    itemCount: number,
    mapRunArn: string,
    name: string,
    startDate: string,
    stateMachineAliasArn: string,
    stateMachineArn: string,
    stateMachineVersionArn: string,
    status: ExecutionStatusType,
    stopDate: string
};