import { useCallback, useState } from "react";
import axios from "axios";
import { OrionSubscription } from "../types/api/orionSubscription";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useOrionSubscriptions = () => {
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [orionSubscriptions, setOrionSubscriptions] = useState<Array<OrionSubscription>>([]);

  const ORION_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getOrionSubscriptions = useCallback(() => {
    setLoading(true);

    let atr: string = "";
    if (selectTenant != "999999") {
      atr = "?limit=1000&tenant=" + selectTenant;
    }
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<Array<OrionSubscription>>(
          ORION_MANAGER_URL + "/subscriptions" + atr,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            setOrionSubscriptions(res.data);
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(() =>
          showMessage({
            title: "テンプレートの取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  return {
    getOrionSubscriptions,
    loading,
    orionSubscriptions,
  };
};
