import { createContext, memo, ReactNode, useEffect, useState, FC } from "react";

import {
  CognitoUserPool,
  CognitoUser,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";

import awsConfiguration from "../awsConfiguration";

import { User } from "../types/api/user";
import { TierType } from "../types/api/commonTypes";
import { useTenant } from "../hooks/useTenant";

type LoginUser = User & { isAdmin: boolean } & { isAccountAdmin: boolean } & {
  bearerToken: string;
} & { exp: number };

export type LoginUserContextType = {
  loginUser: LoginUser | null;
  token: string | null;
  isLoggedIn: boolean;
  login: (idToken: string, accessToken: string, refreshToken: string) => void;
  logout: () => void;
  tokenRefresh: () => void;
  selectTenant: string | null;
  selectTier: TierType | null;
  setSelectTenant: (selectTenant: string | null) => void;
};

export const LoginUserContext = createContext<LoginUserContextType>({
  isLoggedIn: false,
} as LoginUserContextType);

const decodeJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
};

const calculateRemainingTime = (expTime: number) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};
type Props = {
  children: ReactNode;
};
export const LoginUserProvider: FC<Props> = memo((props) => {
  const { children } = props;
  const initialToken: string | null = sessionStorage.getItem("token");
  const initialRefresh: string | null = sessionStorage.getItem("refresh");
  var initialName: string | null = sessionStorage.getItem("name");
  const {tenantGet,tenant} = useTenant();
  if (!initialName) initialName = "";
  const [loginUser, setLoginUser] = useState<LoginUser | null>(null);
  const [selectTenant, setSelectTenant] = useState<string | null>(null);
  const [selectTier, setSelectTier] = useState<TierType>("");
  const [token, setToken] = useState<string | null>(initialToken);
  const [refresh, setRefresh] = useState<string | null>(initialRefresh);
  const [userName, setUserName] = useState<string>(initialName);

  const userPool = new CognitoUserPool({
    UserPoolId: awsConfiguration.UserPoolId,
    ClientId: awsConfiguration.ClientId,
  });

  const logoutHandler = () => {
    setToken(null);
    setRefresh(null);
    setUserName("");
    setSelectTenant(null);
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh");
    sessionStorage.removeItem("name");
  };

  const refreshHandler = async () => {
    if (loginUser?.exp) {
      const remainingTime = calculateRemainingTime(loginUser.exp * 1000);
      // トークンの残り有効時間
      // console.log(remainingTime / 60000);
      if (remainingTime <= 0) {
        return await refreshProc();
      } else {
        return sessionStorage.getItem("token");
      }
    } else {
      return sessionStorage.getItem("token");
    }
  };

  async function refreshProc() {
    var token;
    await new Promise((resolve, rejects) => {
      if (refresh) {
        // トークン情報更新
        // ユーザープールの生成
        var userData = { Username: userName, Pool: userPool };
        var cognitoUser = new CognitoUser(userData);
        // 更新トークンの設定
        var refreshToken: any = new CognitoRefreshToken({
          RefreshToken: refresh,
        });
        // トークン情報更新
        cognitoUser.refreshSession(refreshToken, (err, session) => {
          if (err) {
            rejects();
          } else {
            if (session.isValid()) {
              var idToken = session.idToken.jwtToken;
              var AccessToken = session.accessToken.jwtToken;
              var refreshToken = session.refreshToken.token;
              loginHandler(idToken, AccessToken, refreshToken);
              resolve(idToken);
            } else {
              rejects();
            }
          }
        });
      } else {
        rejects();
      }
    })
      .then((newtoken) => {
        // 再取得したトークンを返す
        console.log("tokenRefresh");
        token = newtoken;
      })
      .catch(() => {
        // 再取得に失敗したのでsessionStorageに入っているtokenを返す
        token = sessionStorage.getItem("token");
      });
    return token;
  }

  useEffect(() => {
    if (token) {
      const decodedToken: any = decodeJwt(token);
      const username: string = decodedToken["cognito:username"];
      const firstName: string = decodedToken["given_name"];
      const lastName: string = decodedToken["family_name"];
      const userRole: string = decodedToken["custom:role"];
      const tenant_id: string = decodedToken["custom:tenant_id"];
      const exp: number = decodedToken["exp"];
      setUserName(username);
//      setSelectTenant(tenant_id);
      sessionStorage.setItem("name", username);
      // console.log("setName:" + username);
      const user = {
        userName: username,
        enabled: true,
        confirmedStatus: "",
        dateCreated: "",
        tier: "",
        firstName: firstName,
        role: userRole,
        lastName: lastName,
        isAdmin: userRole?.lastIndexOf("Admin") > 0 ? true : false,
        isAccountAdmin:
          userRole?.lastIndexOf("AccountAdmin") > 0 ? true : false,
        bearerToken: token,
        tenant: tenant_id,
        exp: exp,
      };
      setLoginUser(user);
    }
  }, [token]);

  useEffect(() => {
    if (loginUser) 
      setSelectTenant(loginUser.tenant);
    else setSelectTenant(null);
  }, [loginUser]);

  useEffect(() => {
    if (selectTenant) 
    tenantGet(selectTenant);
    else setSelectTier("");
  }, [selectTenant,tenantGet]);

  useEffect(() => {
    if (tenant) 
    setSelectTier(tenant.tier);
    else setSelectTier("");
  }, [tenant]);

  const loginHandler = (
    idToken: string,
    accessToken: string,
    refreshToken: string
  ) => {
    setToken(idToken);
    setRefresh(refreshToken);
    sessionStorage.setItem("token", idToken);
    sessionStorage.setItem("refresh", refreshToken);
    console.log("setToken:" + idToken);
    // console.log("setRefresh:" + refreshToken);
  };

  const contextValue: LoginUserContextType = {
    loginUser: loginUser,
    token: token,
    isLoggedIn: !!token,
    login: loginHandler,
    logout: logoutHandler,
    tokenRefresh: refreshHandler,
    selectTenant: selectTenant,
    selectTier: selectTier,
    setSelectTenant: setSelectTenant,
  };

  return (
    <LoginUserContext.Provider value={contextValue}>
      {children}
    </LoginUserContext.Provider>
  );
});
