import { ChangeEvent, memo, useEffect, useState, FC, useRef } from "react";
import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useApiMetric } from "../../../hooks/useApiMetric";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { useDomainApi } from "../../../hooks/useDomainApi";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { ApexOptions } from "apexcharts";
import { DatapointsDrawer } from "../../molecules/DatapointsDrawer";

type BucketInfo = {
  type: string;
  bucketName: string;
};
export const OpenApiMetric: FC = memo(() => {
  const { loginUser } = useLoginUser();
  const { getApiMetric, loading: loadingDatapoints, datapoints } = useApiMetric();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const didLogRef = useRef(false);
  const { getOpenApi, getDomainApis, loading, domainApis, openApi } = useDomainApi(onClose);

  const [enabled, setEnabled] = useState<boolean>(true);
  type StatisticType = "Average" | "Sum" | "Minimum" | "Maximum";
  type MetricPtn =
    | "4XXError"
    | "5XXError"
    | "CacheHitCount"
    | "CacheMissCount"
    | "Count"
    | "IntegrationLatency"
    | "Latency";
  const [statistics, setStatistics] = useState<StatisticType[]>(["Sum"]);
  const [metric, setMetric] = useState<MetricPtn>("Count");
  const [series, setSeries] = useState([{ name: "", data: [{ x: "yyyy-mm-dd", y: 0 }] }]);
  const [periodset, setPeriodset] = useState<"5minits" | "hour" | "day">("5minits");

  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      getDomainApis(enabled);
    }
  }, []);

  // openApi の選択　または、メトリクスの変更時に
  useEffect(() => {
    if (openApi) {
      let temps: StatisticType[] = ["Sum"];
      if (metric == "4XXError") temps = ["Sum", "Average"];
      else if (metric == "5XXError") temps = ["Sum", "Average"];
      else if (metric == "CacheHitCount") temps = ["Sum", "Average"];
      else if (metric == "CacheMissCount") temps = ["Sum", "Average"];
      else if (metric == "Count") temps = ["Sum"];
      else if (metric == "IntegrationLatency") temps = ["Average", "Maximum", "Minimum"];
      else if (metric == "Latency") temps = ["Average", "Maximum", "Minimum"];
      const endDate = new Date();
      const startDate = new Date();
      let period: number = 300;
      if (periodset == "5minits") period = 300;
      else if (periodset == "hour") period = 60 * 60;
      else if (periodset == "day") period = 60 * 60 * 24;
      startDate.setDate(endDate.getDate() - (period * 1440) / (60 * 60 * 24));
      setStatistics(temps);
      getApiMetric(
        openApi.restApiname,
        openApi.stage,
        metric,
        temps,
        startDate.toISOString(),
        endDate.toISOString(),
        period
      );
    }
  }, [openApi, metric, periodset]);

  // データ取得後に、グラフ用のデータを編集
  useEffect(() => {
    if (datapoints) {
      setSeries(
        statistics.map((statistic) => {
          const data: { x: string; y: number }[] = datapoints.map((datapoint) => {
            const point = {
              x: datapoint.Timestamp,
              y: datapoint[statistic] || 0,
            };
            if (statistic in datapoint) {
              if (datapoint.Unit == "Bytes") {
                point.y = Math.ceil((datapoint[statistic] || 0 * 10) / (1024 * 1024)) / 10;
              } else {
                point.y = datapoint[statistic] || 0;
              }
            } else {
              point.y = 0;
            }

            return point;
          });
          return { name: statistic, data: data };
        })
      );
    }
  }, [datapoints]);
  // Apiを選択した際の処理
  const onChangeDomainApi = (e: ChangeEvent<HTMLSelectElement>) => {
    const api = domainApis?.find((api) => api.domainName === e.target.value);

    if (api) {
      getOpenApi(api.domainName);
    }
  };

  const options: ApexOptions = {
    chart: {
      id: "chart",
      toolbar: {
        export: {
          csv: {
            columnDelimiter: ",",
            headerCategory: "datetime",
            dateFormatter(timestamp: number) {
              return new Date(timestamp).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
            },
          },
        },
      },
      animations:{
        enabled: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false, // 表示をJSTにする
        datetimeFormatter: {
          year: "yyyy年",
          month: "M月",
          day: "M/d",
          hour: "H:mm",
          minute: "H:mm",
        },
        rotate: -15,
        rotateAlways: true,
      },
    },
    yaxis: {
      min: 0,
    },
    tooltip: {
      x: { format: "yyyy/M/d H:mm" },
    },
  };
  return (
    <Stack maxW={"1200px"}>
      <PageDiscription>OpenAPIを選択してください。</PageDiscription>
      <FormControl>
        <Checkbox
          hidden={!loginUser?.isAccountAdmin}
          isDisabled={!loginUser?.isAccountAdmin}
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked);
            getDomainApis(e.target.checked);
          }}
        >
          テナント内のみ表示
        </Checkbox>
      </FormControl>
      <FormControl>
        <HStack>
          <FormLabel whiteSpace={"nowrap"}>OPENAPIの種類</FormLabel>
          {loading ? (
            <Spinner key="deviceSpinner" color="teal.500" />
          ) : (
            <Select
              onChange={onChangeDomainApi}
              bg="gray.50"
              disabled={loading}
              value={openApi ? openApi.domainName : ""}
            >
              <option value={""}></option>
              {domainApis?.map((domainApi) => (
                <option key={domainApi.domainName} value={domainApi.domainName}>
                  {domainApi.domainName}
                </option>
              ))}
            </Select>
          )}
        </HStack>
      </FormControl>
      <FormControl>
        <RadioGroup onChange={(nextValue: MetricPtn) => setMetric(nextValue)} value={metric}>
          <HStack flexWrap="wrap">
            <Radio value="4XXError">4XX（権限エラー）</Radio>
            <Radio value="5XXError">5XX（システムエラー）</Radio>
            <Radio value="CacheHitCount">CacheHitCount</Radio>
            <Radio value="CacheMissCount">CacheMissCount</Radio>
            <Radio value="Count">実行回数</Radio>
            <Radio value="IntegrationLatency">IntegrationLatency</Radio>
            <Radio value="Latency">Latency</Radio>
          </HStack>
        </RadioGroup>
      </FormControl>
      <FormControl>
        <RadioGroup onChange={(nextValue: "5minits" | "hour" | "day") => setPeriodset(nextValue)} value={periodset}>
          <HStack flexWrap="wrap" spacing="5">
            <Radio value="5minits">5分間隔</Radio>
            <Radio value="hour">１時間間隔</Radio>
            <Radio value="day">１日間隔</Radio>
          </HStack>
        </RadioGroup>
      </FormControl>
      <DatapointsDrawer
        loadingDatapoints={loadingDatapoints}
        datapoints={datapoints}
        options={options}
        series={series}
        statistics={statistics}
      ></DatapointsDrawer>
    </Stack>
  );
});
