import { useCallback, useState } from "react";
import axios from "axios";
import { useMessage } from "./useMessage";
import { useLoginUser } from "./useLoginUser";
import appconfig from "../config.json";
import { WorkflowStatus } from "../types/api/workflow";

export const useWorkflow = () => {
    const { showMessage } = useMessage();
    const { tokenRefresh, selectTenant } = useLoginUser();
    const [loading, setLoading] = useState(false);
    const [workflowStatus, setWorkflowStatus] = useState<Array<WorkflowStatus>>([]);
    const WORKFLOW_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

    const runWorkflow = useCallback((workflow: string) => {
        setLoading(true);
        new Promise((resolve) => resolve(tokenRefresh()))
        .then((token) => {
            axios
                .get<any>(
                    WORKFLOW_MANAGER_URL + `/workflow/${workflow}/start`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    setLoading(false);
                    getWorkflowStatus(workflow);
                    showMessage({ title: "取込処理を開始しました。", status: "success" });
                })
                .catch((err) => {
                    throw new Error(err);
                })
        })
        .catch((err) => {
            setLoading(false);
            console.log(err);
            showMessage({
                title: "取込処理の開始に失敗しました。",
                status: "error",
            });
        })
        .finally(()=>{
            // setUploading(false);
        })
    }, []);

    const getWorkflowStatus = useCallback((workflow: string | undefined) => {
        setLoading(true);
        if(workflow){
            new Promise((resolve) => resolve(tokenRefresh()))
            .then((token) => {
                axios
                    .get<Array<WorkflowStatus>>(
                        WORKFLOW_MANAGER_URL + `/workflow/${workflow}/status`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then((res) => {
                        setWorkflowStatus(res.data);
                        setLoading(false);
                    })
                    .catch((err) => {
                        throw new Error(err);
                    })
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                showMessage({
                    title: "取込処理状態の取得に失敗しました。",
                    status: "error",
                });
            })
            .finally(()=>{
                // setUploading(false);
            })    
        }else{
            setWorkflowStatus([]);
            setLoading(false);
        }
    }, []);

    return { runWorkflow, getWorkflowStatus, loading, workflowStatus };
};
