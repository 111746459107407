import { memo, ReactNode, FC } from "react";

import { MyMenu } from "../organisms/layout/MyMenu";

type Props = {
  children: ReactNode;
};

export const HeaderLayout: FC<Props> = memo((props) => {
  const { children } = props;

  return <MyMenu key="mymenu">{children}</MyMenu>;
});
