/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMessage } from "./useMessage";
import { LoginUserContext } from "../providers/LoginUserProvider";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js"
import awsConfiguration from '../awsConfiguration'
import awsCognitoMessage from "../awsCognitoMessage";

const userPool = new CognitoUserPool({
  UserPoolId: awsConfiguration.UserPoolId,
  ClientId: awsConfiguration.ClientId,
})

export const useAuth = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const authCtx = useContext(LoginUserContext)
  const [loading, setLoading] = useState(false);

  const signIn = useCallback(
    (id: string, password: string) => {
      setLoading(true);
      const authenticationDetails = new AuthenticationDetails({
        Username : id,
        Password : password
      })
      const cognitoUser = new CognitoUser({
        Username: id,
        Pool: userPool
      })
      cognitoUser
        .authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          console.log("onSuccess")
          // get the ID token
          var idToken = result.getIdToken().getJwtToken();
          var AccessToken = result.getAccessToken().getJwtToken();
          var RefreshToken:string = result.getRefreshToken().getToken();

          authCtx.login(idToken,AccessToken,RefreshToken);
          setLoading(false);
          showMessage({ title: "ログインしました", status: "success" });
          navigate("/home");
        },
        onFailure:(err) => {
          console.log("onFailure")
          setLoading(false);
          console.log(err);
          // パスワードリセットが必要な場合
          if(err.name === 'PasswordResetRequiredException'){
            navigate("/confirm/" + id,{state:{forgotPW : true}});
          }else{
            showMessage({ title: "ログインできません。", status: "error" });
            const msg:string = err['message'];
            showMessage({ title: awsCognitoMessage[msg] ?? err['message'] , status: "error" });
          }
        },
        newPasswordRequired:(userAttributes, requiredAttributes) => {
          setLoading(false);
          navigate("/confirm/" + id,{state:{forgotPW : false}});
        }
      });
    },
    [navigate, showMessage]
  );

  return { signIn, loading};
};
