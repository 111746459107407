import { memo, useCallback, useState, useEffect, useRef } from "react";
import { Center, HStack, Spinner, Stack, Th, Tr, useDisclosure } from "@chakra-ui/react";
import { MessageConfigDetailModal } from "../../organisms/modal/MessageConfigDetailModal";
import { useMessageConfig, ConfigArrayItem } from "../../../hooks/useMessageConfigs";
import { useCustomMetrics } from "../../../hooks/useCustomMetrics";
import { AddButton } from "../../atoms/button/AddButton";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { MessageConfigRow } from "../../organisms/datarow/MessageConfigRow";
import { TableContent } from "../../organisms/layout/TableContent";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { SearchInput } from "../../atoms/Input/SearchInput";

const alertSystemEntityId = "alertSystem";
const alertSystemEentityType = "alertSystem";
export const AlertConfigs = memo(() => {
  const dlTemplate = useDisclosure();
  const { getMessageConfigs, messageConfigEdit, loading, configs } = useMessageConfig();

  const { getMetrics, metrics } = useCustomMetrics();

  const newConfig: ConfigArrayItem = {
    messageId: "new",
    config: {
      name: "",
      state: "enable",
      updateTime: "",
      updateUser: "",
      conditions: {
        items: [{ condition1: "sum", condition2: "0", comparison: "==" }],
        join: "AND",
        trigger: "Always",
        interval: "24",
      },
      notifications: {
        method: "EMAIL",
        template: "AlertSystemTemplate",
        sourceAddress: "",
        templateData: {
          subject: "監視アラート発生",
        },
        toAddressList: [],
      },
    },
  };

  const { selectTenant } = useLoginUser();
  const [searchValue, setSearchValue] = useState("");

  const [selectedConfig, setSelectedConfig] = useState<ConfigArrayItem>({ ...newConfig });
  const didLogRef = useRef(false);
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      if (selectTenant) {
        getMessageConfigs(selectTenant, alertSystemEntityId);
        getMetrics();
      }
    }
  }, []);

  const onClickConfig = useCallback(
    (config_id: string) => {
      //      onSelectType({ typeName, types, dlType.onOpen });
      const targetConfig = configs.find((obj) => obj.messageId === config_id);
      if (targetConfig) {
        setSelectedConfig(targetConfig);
      } else {
        setSelectedConfig({ ...newConfig });
      }
      dlTemplate.onOpen();
    },
    [configs, newConfig, dlTemplate]
  );
  const onUpdateConfig = useCallback(
    (configItem: ConfigArrayItem) => {
      if (selectTenant) {
        if (configItem.messageId === "new") {
          // テナントに1つも設定がない場合は新規設定
          if (configs.length > 0) {
            const messageIDs = configs.map((config) => {
              return parseInt(config.messageId.substring(1));
            });
            let max_value = Math.max.apply(null, messageIDs);
            let messageId = "m" + (max_value + 1).toString().padStart(5, "0");
            messageConfigEdit(
              selectTenant,
              alertSystemEntityId,
              alertSystemEentityType,
              { ...configItem, messageId },
              "PUT"
            );
          } else {
            messageConfigEdit(selectTenant, alertSystemEntityId, alertSystemEentityType, configItem, "POST");
          }
        } else {
          messageConfigEdit(selectTenant, alertSystemEntityId, alertSystemEentityType, configItem, "PUT");
        }
      }
    },
    [configs, messageConfigEdit]
  );

  return (
    <Stack>
      <PageDiscription>通知設定を選択してください。</PageDiscription>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <HStack>
            <SearchInput
              placeholder={"通知設定名、件名、送信先アドレス、監視対象"}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <AddButton onClick={() => onClickConfig("")} />
          </HStack>
          <TableContent
            heightOffset="50px"
            headerRows={
              <Tr key="header">
                <Th>ID</Th>
                <Th width="40%">通知設定名</Th>
                <Th width="60%">件名</Th>
                <Th>監視間隔（時間）</Th>
                <Th>送信先アドレス数</Th>
                <Th>監視設定数</Th>
                <Th>状態（有効／無効）</Th>
              </Tr>
            }
            bodyRows={configs
              .filter((obj) => {
                return obj.config.state !== "deleted";
              })
              .filter((obj) => {
                return (
                  searchValue === "" ||
                  obj.config.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                  obj.config.notifications.templateData.subject?.toLowerCase().includes(searchValue.toLowerCase()) ||
                  obj.config.notifications.toAddressList.some((toAddress) => {
                    return toAddress.toLowerCase().includes(searchValue.toLowerCase());
                  }) ||
                  obj.config.Metrics?.some((metric) => {
                    return (
                      metric.Dimensions.some((dimension) => 
                        dimension.Name !== "Tenant" && 
                        dimension.Value.toLowerCase().includes(searchValue.toLowerCase())
                      ) || 
                      (metric.Comment ?? "").toLowerCase().includes(searchValue.toLowerCase())
                    );
                  })
                );
              })
              .map((obj) => (
                <MessageConfigRow
                  configId={obj.messageId}
                  configName={obj.config.name}
                  subject={obj.config.notifications.templateData.subject}
                  interval={obj.config.conditions.interval ?? ""}
                  toAddressNum={obj.config.notifications.toAddressList.length}
                  metricsNum={obj.config.Metrics?.length ?? 0}
                  state={obj.config.state}
                  onClick={onClickConfig}
                />
              ))}
          ></TableContent>
        </>
      )}
      <MessageConfigDetailModal
        isOpen={dlTemplate.isOpen}
        onClose={dlTemplate.onClose}
        configArrayItem={selectedConfig}
        customMetrics={metrics}
        onUpdate={onUpdateConfig}
      />
    </Stack>
  );
});
