import { useCallback, useState } from "react";
import axios from "axios";
import { TableMetadata } from "../types/api/tablemetadata";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

type responsedata = {
  NextToken? : string;
  TableMetadataList :[TableMetadata];
}
export const useDataCatalog = () => {
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [tables, setTables] = useState<Array<TableMetadata>>([]);

  const DATACATALOG_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getDataCatalogTables = useCallback(() => {
    setLoading(true);

    let atr: string = "";
    if (selectTenant != "999999") {
      atr = "?tenant=" + selectTenant;
    }
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<responsedata>(
          DATACATALOG_MANAGER_URL + "/datacatalog/tables" + atr,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            setTables(res.data.TableMetadataList);
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(() =>
          showMessage({
            title: "テーブルの取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  return {
    getDataCatalogTables,
    loading,
    tables,
  };
};
