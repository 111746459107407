import { ChangeEvent, memo, useCallback, useEffect, useState, FC } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { ApiKeyCard } from "../../organisms/tenant/ApiKeyCard";
import { useApiKey } from "../../../hooks/useApiKey";
import { ApiKeyDetailModal } from "../../organisms/modal/ApiKeyDetailModal";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import { ApiKey } from "../../../types/api/apikey";
import { AddButton } from "../../atoms/button/AddButton";
import { PageDiscription } from "../../atoms/button/PageDiscription";

export const ApiKeys: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    getApiKeys,
    getApiKey,
    apiKeyAdd,
    apiKeyEdit,
    apiKeyDelete,
    apiKeyEnable,
    apiKeyDisable,
    loading,
    loadingApiKey,
    apiKeys,
    selectedApiKey,
  } = useApiKey(onClose);
  const { loginUser } = useLoginUser();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => getApiKeys(), [getApiKeys]);

  const onClickApiKey = useCallback(
    (id: string) => {
      if (id) {
        getApiKey(id);
        onOpen();
      } else {
        getApiKey("");
        onOpen();
      }
    },
    [apiKeys, onOpen]
  );

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <Stack>
      <PageDiscription>APIキーを選択してください。</PageDiscription>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <AddButton onClick={() => onClickApiKey("")} />

          <Wrap px={{ base: 4, md: 10 }} py={{ base: 1, md: 2 }}>
            {apiKeys
              .filter((obj) => {
                return searchValue === "" || obj.id.indexOf(searchValue) >= 0;
              })
              .map((obj) => (
                <WrapItem key={obj.id} mx="auto">
                  <ApiKeyCard
                    id={obj.id}
                    name={obj.name}
                    description={obj.description}
                    enabled={obj.enabled}
                    onClick={onClickApiKey}
                  />
                </WrapItem>
              ))}
          </Wrap>
        </>
      )}
      <ApiKeyDetailModal
        isOpen={isOpen}
        isAdmin={loginUser?.isAdmin}
        onInsert={apiKeyAdd}
        onUpdate={apiKeyEdit}
        onDelete={apiKeyDelete}
        onEnable={apiKeyEnable}
        onDisable={apiKeyDisable}
        onClose={onClose}
        apiKey={selectedApiKey}
        loading={loadingApiKey}
      />
    </Stack>
  );
});
