import { memo, useCallback, useState, useEffect, useRef } from "react";
import { useOrionTypes } from "../../../hooks/useOrionTypes";
import { OrionTypeModal } from "../../organisms/modal/OrionTypeModal";
import {
  Center,
  Spinner,
  Stack,
  Th,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { OrionTypeRow } from "../../organisms/datarow/OrionTypeRow";
import { TableContent } from "../../organisms/layout/TableContent";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { OrionType } from "../../../types/api/orionType";
export const OrionTypes = memo(() => {
  const dlType = useDisclosure();
  const { loginUser, selectTenant } = useLoginUser();
  const { getOrionTypes, loading, orionTypes } = useOrionTypes();
  const [selectedType, setSelectedType] = useState<OrionType>();
  const didLogRef = useRef(false);

  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      let tenant_id: string = "";
      if (selectTenant) {
        tenant_id = selectTenant;
      } else {
        if (loginUser) tenant_id = loginUser.tenant;
      }
      getOrionTypes(tenant_id);
    }
  }, []);

  const onClickType = useCallback(
    (type: string) => {
      const targetType = orionTypes.find((obj) => obj.type === type);
      if (targetType) {
        setSelectedType(targetType);
        dlType.onOpen();
      }
    },
    [orionTypes]
  );
  return (
    <Stack>
      <PageDiscription>
        エンティティ・タイプを選択してください。
      </PageDiscription>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <TableContent
            heightOffset="50px"
            headerRows={
              <Tr key="header">
                <Th width="30%">エンティティ・タイプ</Th>
                <Th width="20%">件数</Th>
              </Tr>
            }
            bodyRows={orionTypes.map((obj) => (
              <OrionTypeRow
                key={obj.type}
                type={obj.type}
                count={obj.count}
                onClick={onClickType}
              />
            ))}
          ></TableContent>
        </>
      )}
      <OrionTypeModal
        isOpen={dlType.isOpen}
        onClose={dlType.onClose}
        Type={selectedType}
      />
    </Stack>
  );
});
