import { memo, FC } from "react";
import { Box, Stack, Text } from "@chakra-ui/react";

type Props = {
  typeName: string;
  SecurityLevel: string;
  onClick: (userName: string) => void;
};

export const TypeCard: FC<Props> = memo((props) => {
  const { typeName, SecurityLevel, onClick } = props;

  return (
    <Box
    w={{base:"calc(100vw - 60px)", md:"calc(50vw - 60px)", lg:"calc(33vw - 50px)", xl:"calc(25vw - 50px)"}}
    minHeight="85px"
      bg="white"
      borderRadius="10px"
      shadow="md"
      p={4}
      _hover={{ cursor: "pointer", opacity: 0.8 }}
      onClick={() => onClick(typeName)}
    >
      <Stack textAlign="left">
        <Text fontSize="md" fontWeight="bold">
          {typeName}
        </Text>
        <Text fontSize="sm" color="gray">
          {SecurityLevel}
        </Text>
      </Stack>
    </Box>
  );
});
