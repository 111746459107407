export enum WorkflowStatusType {
    RUNNING='RUNNING',
    COMPLETED='COMPLETED',
    STOPPING='STOPPING',
    STOPPED='STOPPED',
    ERROR='ERROR',  
};

export type WorkflowStatistics = {
    TotalActions: Number,
    TimeoutActions: Number,
    FailedActions: Number,
    StoppedActions: Number,
    SucceededActions: Number,
    RunningActions: Number,
    ErroredActions: Number,
    WaitingActions: Number 
}

export type WorkflowStatus = 
{
    Name: string,
    WorkflowRunId: string,
    PreviousRunId: string,
    WorkflowRunProperties: {
        string: string
    },
    StartedOn: string,
    CompletedOn: string,
    Status: WorkflowStatusType,
    ErrorMessage: string,
    Statistics: WorkflowStatistics
};