import { memo, useCallback, useEffect, useState, useRef } from "react";
import {
  Center,
  HStack,
  Spinner,
  Stack,
  Th,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { IdentitieDetailModal } from "../../organisms/modal/IdentitieDetailModal";
import { useEmailIdentitie } from "../../../hooks/useEmailIdentities";
import { EmailIdentitie } from "../../../types/api/emailIdentitie";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { TableContent } from "../../organisms/layout/TableContent";
import { AddButton } from "../../atoms/button/AddButton";
import { SearchInput } from "../../atoms/Input/SearchInput";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { EmailIdentitieRow } from "../../organisms/datarow/EmailIdentitieRow";

export const EmailIdentities = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    getEmailIdentities,
    emailIdentitieAdd,
    emailIdentitieDelete,
    loading,
    EmailIdentities,
  } = useEmailIdentitie();

  const { selectTenant } = useLoginUser();

  const newIdentitie: EmailIdentitie = {
    IdentityType: "EMAIL_ADDRESS",
    IdentityName: "",
    SendingEnabled: false,
    Tenant: selectTenant,
  };

  const [selectedIdentitie, setSelectedIdentitie] =
    useState<EmailIdentitie>(newIdentitie);

  const didLogRef = useRef(false);
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      getEmailIdentities();
    }
  }, []);
  const onClickIdentitie = useCallback(
    (identitie_name: string) => {
      //      onSelectType({ typeName, types, dlType.onOpen });
      const targetIdentitie = EmailIdentities.find(
        (obj) => obj.IdentityName === identitie_name
      );
      if (targetIdentitie) {
        setSelectedIdentitie(targetIdentitie);
        onOpen();
      } else {
        setSelectedIdentitie(newIdentitie);
        onOpen();
      }
    },
    [EmailIdentities, newIdentitie, onOpen]
  );

  const [searchValue, setSearchValue] = useState("");

  return (
    <Stack maxW={"1200px"}>
      <PageDiscription>送信元アドレスを選択してください。</PageDiscription>
      <HStack>
        <SearchInput
          placeholder={"メールアドレス"}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <AddButton onClick={() => onClickIdentitie("")} />
      </HStack>

      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <TableContent
            headerRows={
              <Tr key="header">
                <Th>アドレス</Th>
                <Th>テナント</Th>
                <Th>状態</Th>
              </Tr>
            }
            bodyRows={EmailIdentities.filter((obj) => {
              return (
                searchValue === "" || obj.IdentityName.indexOf(searchValue) >= 0
              );
            }).map((obj) => (
              <EmailIdentitieRow
                key={obj.IdentityName}
                identityName={obj.IdentityName}
                tenant={obj.Tenant ?? ""}
                sendingEnabled={obj.SendingEnabled}
                onClick={onClickIdentitie}
              />
            ))}
          ></TableContent>
        </>
      )}
      <IdentitieDetailModal
        isOpen={isOpen}
        onClose={onClose}
        EmailIdentitie={selectedIdentitie}
        onDelete={emailIdentitieDelete}
        onInsert={emailIdentitieAdd}
      />
    </Stack>
  );
});
