import { useCallback, useState } from "react";
import axios from "axios";
import { Filelist, FileInfo, OpenSearchErrorFiles } from "../types/api/file";
import { useMessage } from "./useMessage";
import { useLoginUser } from "./useLoginUser";
import appconfig from "../config.json";

export const useCsvDownload = () => {
  const { showMessage } = useMessage();
  const { tokenRefresh, selectTenant } = useLoginUser();
  const [fileListLoading, setFileListLoading] = useState(false);
  const [csvFiles, setCsvFiles] = useState<Array<FileInfo>>([]);
  const FILE_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const [fileLoading, setFileLoading] = useState(false);
  const [jsonContent, setJsonContent] = useState<any>();

  // data/csv/ドライバ名のcsvファイルの一覧を取得
  const getCsvFileList = useCallback((bucket: string, prefix: string) => {
    setFileListLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .get<Filelist>(FILE_MANAGER_URL + "/s3action/list" + atr, {
          headers: {
            Authorization: `Bearer ${token}`,
            bucket: `${bucket}`,
            key: `data/csv/${prefix}/`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setCsvFiles(res.data.Contents);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setFileListLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(() => {
          showMessage({
            title: "ファイルリストの取得に失敗しました",
            status: "error",
          });
        })
        .finally(() => setFileListLoading(false));
    });
  }, []);

  // csvファイルの一覧を初期化
  const clearFileList = useCallback(() => {
    setCsvFiles([]);
  }, []);

  // csvファイルのダウンロード
  const getFile = useCallback(
    (bucket: string, key: string, opensearchError: boolean = false) => {
      setFileLoading(true);
      setJsonContent(undefined);
      new Promise((resolve) => resolve(tokenRefresh()))
        .then((token) => {
          let atr: string = "";
          if (selectTenant) {
            atr = "?tenant=" + selectTenant;
          }
          axios
            .get<any>(FILE_MANAGER_URL + "/s3action" + atr, {
              headers: {
                Authorization: `Bearer ${token}`,
                bucket: `${bucket}`,
                key: `${key}`,
              },
            })
            .then((res) => {
              if (res.data) {
                return res.data.presigned_url;
              } else {
                return "";
              }
            })
            .then((url) => {
              if (url === "") {
                throw new Error("presigned_url undefined");
              } else {
                if (opensearchError) {
                  // ファイルの中身の取り出し
                  fetch(url)
                    .then((res) => {
                      if (!res.ok) {
                        throw new Error(res.statusText);
                      }
                      return res.json();
                    })
                    .then((json) => {
                      setJsonContent({
                        status: 200,
                        statusText: "",
                        errorFiles: json,
                      } as OpenSearchErrorFiles);
                    })
                    .catch((e) => {
                      console.log(e);
                      setJsonContent({
                        status: 500,
                        statusText: String(e),
                      } as OpenSearchErrorFiles);
                    });
                } else {
                  // ファイルのダウンロード
                  window.open(url, "_self");
                }
              }
            })
            .catch((err) => {
              console.log(err);
              showMessage({
                title: "ファイルのダウンロードに失敗しました。",
                status: "error",
              });
              throw new Error(err);
            });
        })
        .catch((err) => {
          console.log(err);
          showMessage({
            title: "ファイルのダウンロードに失敗しました。",
            status: "error",
          });
        })
        .finally(() => {
          setFileLoading(false);
        });
    },
    []
  );

  return {
    getCsvFileList,
    clearFileList,
    getFile,
    fileListLoading,
    csvFiles,
    fileLoading,
    jsonContent,
  };
};
