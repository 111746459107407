import { ChangeEvent, memo, useCallback, useEffect, useState, FC } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { UsagePlanCard } from "../../organisms/tenant/UsagePlanCard";
import { useUsagePlan } from "../../../hooks/useUsagePlan";
import { UsagePlanDetailModal } from "../../organisms/modal/UsagePlanDetailModal";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import { useApiKey } from "../../../hooks/useApiKey";
import { AddButton } from "../../atoms/button/AddButton";
import { PageDiscription } from "../../atoms/button/PageDiscription";

export const UsagePlans: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    getUsagePlans,
    getUsagePlan,
    usagePlanAdd,
    usagePlanEdit,
    usagePlanDelete,
    usagePlanKeyAdd,
    usagePlanKeyDelete,
    loading,
    loadingUsagePlan,
    loadingApiKey,
    usagePlans,
    selectedUsagePlan,
    usagePlanKeys,
  } = useUsagePlan(onClose);
  const { loginUser } = useLoginUser();
  const { getApiKeys, apiKeys } = useApiKey(onClose);
  const [enabled, setEnabled] = useState<boolean>(true);

  useEffect(() => {
    getUsagePlans(enabled)
  }, [getUsagePlans,enabled]);
  useEffect(() => getApiKeys(), [getApiKeys]);

  const onClickUsagePlan = useCallback(
    (id: string | undefined) => {
      getUsagePlan(id ?? "");
      onOpen();
    },
    [getUsagePlan, onOpen]
  );

  return (
    <Stack>
      <PageDiscription>使用量プランを選択してください。</PageDiscription>
      <Flex mx="5">
      <FormControl>
                    <FormLabel>テナント内のみ表示</FormLabel>
                    <Checkbox
                      isChecked={enabled}
                      onChange={(e) => setEnabled(e.target.checked)}
                    />
                  </FormControl>

      </Flex>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <AddButton onClick={() => onClickUsagePlan("")} />
          <Wrap px={{ base: 4, md: 10 }} py={{ base: 1, md: 2 }}>
            {usagePlans.map((obj) => (
              <WrapItem key={obj.id} mx="auto">
                <UsagePlanCard
                  id={obj.id}
                  name={obj.name}
                  description={obj.description}
                  onClick={onClickUsagePlan}
                />
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}
      <UsagePlanDetailModal
        isOpen={isOpen}
        isAdmin={loginUser?.isAdmin}
        onInsert={usagePlanAdd}
        onUpdate={usagePlanEdit}
        onDelete={usagePlanDelete}
        onInsertKey={usagePlanKeyAdd}
        onDeleteKey={usagePlanKeyDelete}
        onClose={onClose}
        usagePlan={selectedUsagePlan}
        usagePlanKeys={usagePlanKeys}
        tenantApiKeys={apiKeys}
        loadingUsagePlan={loadingUsagePlan}
        loadingApiKey={loadingApiKey}
      />
    </Stack>
  );
});
