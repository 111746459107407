import { useCallback, useState } from "react";
import axios from "axios";

import { User } from "../types/api/user";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useUser = (onClose: () => void) => {
  const { showMessage } = useMessage();
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Array<User>>([]);

  const USER_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  type IPostResponse = {
    access?: string;
    token?: string;
    newPasswordRequired?: string;
  };

  const userAdd = useCallback(
    (userName: string, firstName: string, lastName: string, role: string) => {
      setLoading(true);
      var user = {
        userName: userName,
        firstName: firstName,
        lastName: lastName,
        role: role,
      };
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        let atr: string = "";
        if (selectTenant) {
          atr = "?tenant=" + selectTenant;
        }
        axios
          .post<any>(USER_MANAGER_URL + "/user" + atr, user, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "追加しました。", status: "success" });
              setLoading(false);
            } else {
              showMessage({ title: "ユーザが作成できません", status: "error" });
              setLoading(false);
            }
          })
          .then(() => {
            getUsers();
            onClose();
          })
          .catch(() => {
            showMessage({ title: "ユーザが作成できません", status: "error" });
            setLoading(false);
          });
      });
    },
    []
  );

  const userEdit = useCallback(
    (userName: string, firstName: string, lastName: string, role: string) => {
      setLoading(true);
      var user = {
        userName: userName,
        firstName: firstName,
        lastName: lastName,
        role: role,
      };
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        let atr: string = "";
        if (selectTenant) {
          atr = "?tenant=" + selectTenant;
        }
        axios
          .put<any>(USER_MANAGER_URL + "/user" + atr, user, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "更新しました。", status: "success" });
              setLoading(false);
            } else {
              showMessage({ title: res.statusText, status: "error" });
              setLoading(false);
            }
          })
          .then(() => {
            getUsers();
            onClose();
          })
          .catch(() => {
            showMessage({ title: "ユーザが更新できません", status: "error" });
            setLoading(false);
          });
      });
    },
    []
  );

  const userDelete = useCallback((userName: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .delete<any>(USER_MANAGER_URL + "/user/" + userName + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status == 204) {
            showMessage({ title: "削除しました。", status: "success" });
            setLoading(false);
          } else if (res.status == 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            showMessage({ title: res.statusText, status: "error" });
            setLoading(false);
          }
        })
        .then(() => {
          getUsers();
          onClose();
        })
        .catch(() => {
          showMessage({ title: "ユーザが削除できません", status: "error" });
          setLoading(false);
        });
    });
  }, []);

  const userEnable = useCallback((userName: string) => {
    setLoading(true);
    var user = {
      userName: userName,
    };
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .put<any>(USER_MANAGER_URL + "/user/enable" + atr, user, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.data) {
            console.log(res.data);
            showMessage({ title: "有効化しました。", status: "success" });
            setLoading(false);
          } else {
            showMessage({ title: res.statusText, status: "error" });
            setLoading(false);
          }
        })
        .then(() => {
          getUsers();
          onClose();
        })
        .catch(() => {
          showMessage({ title: "ユーザが更新できません", status: "error" });
          setLoading(false);
        });
    });
  }, []);
  const userDisable = useCallback((userName: string) => {
    setLoading(true);
    var user = {
      userName: userName,
    };
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .put<any>(USER_MANAGER_URL + "/user/disable" + atr, user, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.data) {
            console.log(res.data);
            showMessage({ title: "無効化しました。", status: "success" });
            setLoading(false);
          } else {
            showMessage({ title: res.statusText, status: "error" });
            setLoading(false);
          }
        })
        .then(() => {
          getUsers();
          onClose();
        })
        .catch(() => {
          showMessage({ title: "ユーザが更新できません", status: "error" });
          setLoading(false);
        });
    });
  }, []);

  const userReset = useCallback((userName: string) => {
    setLoading(true);
    var user = {
      userName: userName,
    };
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .put<any>(USER_MANAGER_URL + "/user/reset" + atr, user, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.data) {
            console.log(res.data);
            showMessage({
              title: "パスワードをリセットしました。",
              status: "success",
            });
            setLoading(false);
          } else {
            showMessage({ title: res.statusText, status: "error" });
            setLoading(false);
          }
        })
        .then(() => {
          getUsers();
          onClose();
        })
        .catch(() => {
          showMessage({ title: "ユーザが更新できません", status: "error" });
          setLoading(false);
        });
    });
  }, []);

  const getUsers = useCallback(() => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .get<Array<User>>(USER_MANAGER_URL + "/users" + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) =>
          setUsers(
            res.data.sort((a, b) => {
              if (a.userName.toLowerCase() > b.userName.toLowerCase()) return 1;
              else return -1;
            })
          )
        )
        .catch(() =>
          showMessage({
            title: "ユーザーの取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  return {
    getUsers,
    userAdd,
    userEdit,
    userDelete,
    userEnable,
    userDisable,
    userReset,
    loading,
    users,
  };
};
