import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  Badge,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { DeviceInfo, TenantInfo } from "../../../types/api/tenantTableTypes";
import {
  SecurityLevelPtn,
  SecurityLevelType,
  TierPtn,
} from "../../../types/api/commonTypes";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { CloseIcon } from "@chakra-ui/icons";

type Props = {
  device: DeviceInfo;
  isOpen: boolean;
  onInsert: (
    deviceName: string,
    securityLevel: SecurityLevelType,
    lfourId: number[]
  ) => void;
  onUpdate: (
    deviceName: string,
    securityLevel: SecurityLevelType,
    lfourId: number[]
  ) => void;
  onDelete: (deviceName: string) => void;
  onClose: () => void;
  deviceNames: Array<string>;
  tenant?: TenantInfo;
};

export const DeviceDetailModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, device, deviceNames, tenant } = props;
  const isAddMode: boolean = device.DeviceName ? false : true;

  const [deviceName, setDeviceName] = useState("");
  const [securityLevel, setSecurityLevel] = useState<SecurityLevelType>("");
  const [lfourId, setLfourId] = useState(""); // 編集中のlfourID
  const [lfourIds, setLfourIds] = useState<number[]>([]);

  useEffect(() => {
    onModalReset();
  }, [device]);

  const onChangeDeviceName = (e: ChangeEvent<HTMLSelectElement>) => {
    setDeviceName(e.target.value);
  };
  const onChangeSecurityLevel = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === SecurityLevelPtn.Public) {
      setSecurityLevel(SecurityLevelPtn.Public);
    } else if (e.target.value === SecurityLevelPtn.Private) {
      setSecurityLevel(SecurityLevelPtn.Private);
    } else if (e.target.value === SecurityLevelPtn.Anonymized) {
      setSecurityLevel(SecurityLevelPtn.Anonymized);
    } else {
      setSecurityLevel("");
    }
  };
  const onChangeLfourId = (e: ChangeEvent<HTMLInputElement>) =>
    setLfourId(e.target.value);

  const onClickLfourIdAdd = () => {
    if (lfourId === "") return;
    setLfourIds([...lfourIds, Number(lfourId)]);
    setLfourId("");
  };

  const onClickListDelete = (index: number) => {
    const newLfourIds = [...lfourIds];
    newLfourIds.splice(index, 1);
    setLfourIds(newLfourIds);
  };

  // 配列中の重複削除＆昇順ソート
  const onClickSort = () => {
    const newLfourIds = [...lfourIds].filter(function (x, i, self) {
      return self.indexOf(Number(x)) === i;
    });
    newLfourIds.sort((a, b) => {
      return a - b;
    });
    setLfourIds(newLfourIds);
  };

  const onClickUpdate = () => {
    props.onUpdate(deviceName, securityLevel, lfourIds);
    props.onClose();
  };

  const onClickCreate = () => {
    props.onInsert(deviceName, securityLevel, lfourIds);
    props.onClose();
  };

  const onClickDelete = () => {
    props.onDelete(deviceName);
    props.onClose();
  };

  const onModalReset = () => {
    setLfourId("");
    setDeviceName(device.DeviceName ?? "");
    setSecurityLevel(device.SecurityLevel);
    if (device.lfourIds) {
      setLfourIds(device.lfourIds);
    } else {
      setLfourIds([]);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>ドライバ詳細</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>
                ドライバの種類
                {isAddMode ? (
                  <Badge colorScheme="red" ml={2}>
                    必須
                  </Badge>
                ) : (
                  <Badge colorScheme="gray" ml={2}>
                    変更不可
                  </Badge>
                )}
              </FormLabel>
              <Select value={deviceName} onChange={onChangeDeviceName}>
                {isAddMode ? (
                  <>
                    <option value={""}>未選択</option>
                    {deviceNames.map((name) => (
                      <option value={name}>{name}</option>
                    ))}
                  </>
                ) : (
                  <option value={deviceName}>{deviceName}</option>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>セキュリティレベル</FormLabel>
              <Select value={securityLevel} onChange={onChangeSecurityLevel}>
                <option value={""}>未設定</option>
                <option value={SecurityLevelPtn.Public}>IoT(public)</option>
                <option value={SecurityLevelPtn.Private}>IoT(private)</option>
                <option value={SecurityLevelPtn.Anonymized}>
                  匿名加工情報
                </option>
                {tenant?.tier === TierPtn.v2 ? (
                  <>
                    <option value={SecurityLevelPtn.Related}>
                      個人関連情報
                    </option>
                    <option value={SecurityLevelPtn.Pseudonymized}>
                      仮名加工情報
                    </option>
                  </>
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>LfourID（10進数）</FormLabel>
              <HStack>
                <Input
                  type={"number"}
                  value={lfourId}
                  placeholder="LfourID"
                  onChange={onChangeLfourId}
                />
                <PrimaryButton onClick={onClickLfourIdAdd}>追加</PrimaryButton>
                <Spacer />
                <PrimaryButton onClick={onClickSort}>ID整理</PrimaryButton>
              </HStack>
            </FormControl>
            <Flex border={"solid"} borderWidth={1} borderColor={"gray.300"}>
              <TableContainer>
                <Table size={"sm"}>
                  <Thead>
                    <Tr>
                      <Th>LfourID（10進数）</Th>
                      <Th>（16進数）</Th>
                      <Th textAlign={"center"}>削除</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {lfourIds.map((cell, i) => (
                      <Tr key={i}>
                        <Td>{cell}</Td>
                        <Td>
                          {Number(String(cell)).toString(16).toUpperCase()}
                        </Td>
                        <Td textAlign={"center"}>
                          <IconButton
                            onClick={() => onClickListDelete(i)}
                            variant="outline"
                            colorScheme="red"
                            aria-label="削除"
                            size={"xs"}
                            icon={<CloseIcon />}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
            <Spacer />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            {isAddMode ? (
              <PrimaryButton
                disabled={deviceName === ""}
                onClick={onClickCreate}
              >
                追加
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton onClick={onClickUpdate}>更新</PrimaryButton>
                <PrimaryButton onClick={onClickDelete}>削除</PrimaryButton>
              </>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
