import { memo, useCallback, useEffect, useState, FC, useRef } from "react";
import {
  Center,
  Spinner,
  Stack,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import appconfig from "../../../config.json";
import { useDevPortal } from "../../../hooks/useDevPortal";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { DevPotalCustomerCard } from "../../organisms/card/DevPotalCustomerCard";
import { Customer, ApiKey } from "../../../types/api/devPortal";
import { DevPortalApiKeyDetailModal } from "../../organisms/modal/DevPortalApiKeyDetailModal";
import { useParameters } from "../../../hooks/useParameters";
import {
  EnvSingleParm,
  TenantSingleParm,
} from "../../../types/api/parameterTypes";
import { useUsagePlan } from "../../../hooks/useUsagePlan";

type CustomerApiKey = Customer & { name: string };
export const DevPortalCustomers: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    getCustomers,
    getApiKeys,
    apiKeyEdit,
    loading: customersLoading,
    customers,
    apiKeys,
  } = useDevPortal(onClose);
  const {
    getUsagePlan: getReceiveDeveloperUsagePlan,
    usagePlanKeyAdd: receiveDeveloperUsagePlanKeyAdd,
    usagePlanKeyDelete: receiveDeveloperUsagePlanKeyDelete,
    selectedUsagePlan: receiveDeveloperUsagePlan,
    loading: receiveDeveloperUsagePlanLoading,
    usagePlanKeys: receiveDeveloperUsagePlanKeys,
  } = useUsagePlan(onClose);
  const {
    getUsagePlan: getDeveloperUsagePlan,
    usagePlanKeyAdd: developerUsagePlanKeyAdd,
    usagePlanKeyDelete: developerUsagePlanKeyDelete,
    selectedUsagePlan: developerUsagePlan,
    loading: developerUsagePlanLoading,
    usagePlanKeys: developerUsagePlanKeys,
  } = useUsagePlan(onClose);
  const { loginUser, selectTenant } = useLoginUser();
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | undefined>(
    undefined
  );
  const [customerApiKeys, setCustomerApiKeys] = useState<Array<CustomerApiKey>>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const didLogRef = useRef(false);
  const {
    getParameter: getReceiveDeveloperUsagePlanid,
    parameter: receiveDeveloperUsagePlanid,
  } = useParameters();
  const {
    getParameter: getDeveloperUsagePlanid,
    parameter: developerUsagePlanid,
  } = useParameters();
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      let tenant_id: string = "";
      if (selectTenant) {
        tenant_id = selectTenant;
      } else {
        if (loginUser) tenant_id = loginUser.tenant;
      }
      getCustomers();
      getApiKeys();
      const basePath = "/" + appconfig.IOT_MANAGEMENT_ENV + "/";

      getDeveloperUsagePlanid(
        basePath + tenant_id + "/" + TenantSingleParm.DeveloperUsagePlanid
      );
      getReceiveDeveloperUsagePlanid(
        basePath + EnvSingleParm.ReceiveDeveloperUsagePlanid
      );
    }
  }, [getCustomers, getApiKeys]);

  useEffect(() => {
    if (
      customersLoading ||
      receiveDeveloperUsagePlanLoading ||
      developerUsagePlanLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    customersLoading,
    receiveDeveloperUsagePlanLoading,
    developerUsagePlanLoading,
  ]);

  useEffect(() => {
    if (receiveDeveloperUsagePlanid) {
      getReceiveDeveloperUsagePlan(receiveDeveloperUsagePlanid);
    }
  }, [receiveDeveloperUsagePlanid, getReceiveDeveloperUsagePlan]);

  useEffect(() => {
    if (developerUsagePlanid) {
      getDeveloperUsagePlan(developerUsagePlanid);
    }
  }, [developerUsagePlanid, getDeveloperUsagePlan]);

  useEffect(() => {
    const mergedArray: Array<CustomerApiKey> = customers.map((customer) => {
      const matchedApiKey = apiKeys.find(
        (apiKey) => apiKey.id === customer.ApiKeyId
      );
      if (matchedApiKey) {
        return { ...customer, name: matchedApiKey.name };
      } else {
        return { ...customer, name: "" };
      }
    });
    setCustomerApiKeys(mergedArray);
  }, [customers, apiKeys]);
  const onClickApiKey = useCallback(
    (id: string) => {
      if (id) {
        const matchedApiKey = apiKeys.find((apiKey) => apiKey.id === id);
        setSelectedApiKey(matchedApiKey);
        onOpen();
      }
    },
    [apiKeys, onOpen]
  );

  return (
    <Stack>
      <PageDiscription>
        Developer Portal で登録された API Keyの設定を行います。
      </PageDiscription>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <Wrap px={{ base: 4, md: 10 }} py={{ base: 1, md: 2 }}>
            {customerApiKeys.map((obj) => (
              <WrapItem key={obj.ApiKeyId} mx="auto">
                <DevPotalCustomerCard
                  id={obj.ApiKeyId}
                  name={obj.EmailAddress}
                  description={obj.DateRegistered}
                  enabled={"true"}
                  onClick={onClickApiKey}
                />
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}
      <DevPortalApiKeyDetailModal
        isOpen={isOpen}
        isAdmin={loginUser?.isAdmin}
        onUpdate={apiKeyEdit}
        receiveDeveloperUsagePlan={receiveDeveloperUsagePlan}
        receiveDeveloperUsagePlanKeys={receiveDeveloperUsagePlanKeys}
        onReceiveDeveloperUsagePlanInsertKey={receiveDeveloperUsagePlanKeyAdd}
        onReceiveDeveloperUsagePlanDeleteKey={
          receiveDeveloperUsagePlanKeyDelete
        }
        developerUsagePlan={developerUsagePlan}
        developerUsagePlanKeys={developerUsagePlanKeys}
        onDeveloperUsagePlanInsertKey={developerUsagePlanKeyAdd}
        onDeveloperUsagePlanDeleteKey={developerUsagePlanKeyDelete}
        onClose={onClose}
        apiKey={selectedApiKey}
      />
    </Stack>
  );
});
