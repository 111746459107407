import { memo, FC } from "react";
import { Link, Td, Textarea, Tr } from "@chakra-ui/react";
import { EditIcon, EmailIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";

type Props = {
  templateName: string;
  subjectPart: string;
  htmlPart: string;
  textPart: string;
  onClick: (templateName: string) => void;
  onClickSend: (templateName: string) => void;
};

export const MailTemplateRow: FC<Props> = memo((props) => {
  const {
    templateName,
    subjectPart,
    htmlPart,
    textPart,
    onClick,
    onClickSend,
  } = props;

  return (
    <Tr key={templateName} bg="gray.50">
      <Td>
        <Link onClick={() => onClick(templateName)} isExternal>
          {templateName}
          <EditIcon mx="2" color={"gray"} fontSize="sm" />
        </Link>
      </Td>
      <Td>{subjectPart}</Td>
      <Td>
        <Textarea
          value={textPart}
          readOnly={true}
          rows={5}
          border="1px"
          borderColor={"gray.400"}
        />
      </Td>
      <Td alignItems="center">
        <Link onClick={() => onClickSend(templateName)} isExternal>
          <ExternalLinkIcon color={"gray"} w="100%" fontSize={"lg"} />
        </Link>
      </Td>
      {/* <Td>
        <Box
          border="1px"
          p="2"
          borderColor={"gray.300"}
          dangerouslySetInnerHTML={{ __html: htmlPart }}
        ></Box>
      </Td> */}
    </Tr>
  );
});
