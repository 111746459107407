/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useMessage } from "./useMessage";
import { LoginUserContext } from "../providers/LoginUserProvider";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js"
import awsConfiguration from '../awsConfiguration'
import awsCognitoMessage from "../awsCognitoMessage";

const userPool = new CognitoUserPool({
  UserPoolId: awsConfiguration.UserPoolId,
  ClientId: awsConfiguration.ClientId,
})
/*
curl -X POST  \
  -H "Content-Type: application/json"  \
  -d '{"userName":"soulful.man.jp@gmail.com", "password":"S3m6i1y4"}'  \
  https://evb7iabjra.execute-api.ap-northeast-1.amazonaws.com/v1/auth
*/
export const useConfirm = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const authCtx = useContext(LoginUserContext)

  const [loading, setLoading] = useState(false);
  const AUTH_MANAGER_URL: string =
    "https://194q1sinm0.execute-api.us-east-1.amazonaws.com/v1";
  type IPostResponse = {
    access?: string;
    token?: string;
    newPasswordRequired?: string;
  };
  const login = useCallback(
    (id: string, currentPassword: string, newPassword: string) => {
      setLoading(true);
      var user = {
        userName: id,
        password: currentPassword,
        newPassword: newPassword
      };

      axios
        .post<IPostResponse>(AUTH_MANAGER_URL + "/auth", user)
        .then((res) => {
          if (res.data) {
            if (res.data.newPasswordRequired) {
              setLoading(false);
              navigate("/confirm");
            } else {
              if (res.data.token) {
                authCtx.login(res.data.token,"","");
              }
              setLoading(false);
              showMessage({ title: "ログインしました", status: "success" });
              navigate("/home");
            }
          } else {
            showMessage({ title: "ユーザーが見つかりません", status: "error" });
            setLoading(false);
          }
        })
        .catch((err) => {
          showMessage({ title: "ログインできません。", status: "error" });
          const msg:string = err['message'];
          showMessage({ title: awsCognitoMessage[msg] ?? err['message'] , status: "error" });
          setLoading(false);
        });
    },
    [navigate, showMessage]
  );
  const signIn = useCallback(
    (id: string, currentPassword: string, newPassword: string) => {
      setLoading(true);
      const authenticationDetails = new AuthenticationDetails({
        Username : id,
        Password : currentPassword
      })
      const cognitoUser = new CognitoUser({
        Username: id,
        Pool: userPool
      })
      cognitoUser
        .authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            // get the ID token
            var idToken = result.getIdToken().getJwtToken();
            var AccessToken = result.getAccessToken().getJwtToken();
            authCtx.login(idToken,"","");
            setLoading(false);
            showMessage({ title: "ログインしました。", status: "success" });
            navigate("/home");
          },
          onFailure:(err) => {
            console.log(err);
            showMessage({ title: awsCognitoMessage[err['message']] ?? err['message'] , status: "error" });
            setLoading(false);  
        },
        newPasswordRequired:(userAttributes, requiredAttributes) => {
          // These attributes are not mutable and should be removed from map.
          delete userAttributes.email_verified;
          delete userAttributes['custom:tenant_id'];
          console.log(requiredAttributes);
          delete userAttributes.email;
          cognitoUser.completeNewPasswordChallenge(newPassword, {},{
            onSuccess: (result) => {
              setLoading(false);
              showMessage({ title: "登録しました。", status: "success" });
              navigate("/login");
            },
            onFailure:(err) => {
              console.log(err);
              showMessage({ title: "登録できません。", status: "error" });
              const msg:string = err['message'];
              showMessage({ title: awsCognitoMessage[msg] ?? err['message'] , status: "error" });
              setLoading(false);
            }
          });
        }
      });  
    },
    [navigate, showMessage]
  );
  const confirmPassword = useCallback(
    (id: string, currentPassword: string, newPassword: string) => {
      setLoading(true);
      const cognitoUser = new CognitoUser({
        Username: id,
        Pool: userPool
      })
      cognitoUser
        .confirmPassword(currentPassword,newPassword,{
        onSuccess: (result) => {
          setLoading(false);
          showMessage({ title: "登録しました。", status: "success" });
          navigate("/login");
        },
        onFailure:(err) => {
          console.log(err);
          showMessage({ title: "登録できません。", status: "error" });
          const msg:string = err['message'];
          showMessage({ title: awsCognitoMessage[msg] ?? err['message'] , status: "error" });
          setLoading(false);
        }});  
    },
    [navigate, showMessage]
  );

  const forgotPassword = useCallback(
    (id: string) => {
      setLoading(true);
      const cognitoUser = new CognitoUser({
        Username: id,
        Pool: userPool
      })
      cognitoUser
        .forgotPassword({
        onSuccess: (result) => {
          setLoading(false);
          showMessage({ title: "仮パスワードを送信しました。", status: "success" });
          navigate("/confirm/" + id,{state:{forgotPW : true}});
        },
        onFailure:(err) => {
          console.log(err);
          showMessage({ title: "仮パスワードを送信できませんでした。", status: "error" });
          const msg:string = err['message'];
          showMessage({ title: awsCognitoMessage[msg] ?? err['message'] , status: "error" });
          setLoading(false);
        }});  
    },
    [navigate, showMessage]
  );

  return { login, loading ,signIn, confirmPassword, forgotPassword};
};
