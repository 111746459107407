import { memo, FC } from "react";
import { Button, Flex, HStack, IconButton, Link, Spacer, Td, Tr } from "@chakra-ui/react";
import { CloseIcon, DownloadIcon, EditIcon } from "@chakra-ui/icons";

type Props = {
    bucket: string;
    prefix: string;
    filekey: string;
    lastModified: string;
    size: number;
    onClickGet?: (bucket: string, key: string) => void;
    onClickDelete?: (bucket: string, prefix: string, key: string) => void;
    disabled?: boolean;
};

export const FileRow: FC<Props> = memo((props) => {
    const { bucket, prefix, filekey, lastModified, size, onClickGet, onClickDelete, disabled = false } = props;
    const filename = filekey.split(/[/]+/).pop();
    // const filename = filekey.substring(prefix.length);
    return (
        <Tr key={filekey} bg="gray.50">
            <Td fontSize={"sm"}>
                <HStack>
                    <Flex>{filename}</Flex>
                    <Spacer/>
                    {onClickGet && (
                        <Button
                            leftIcon={<DownloadIcon />}
                            onClick={() => onClickGet(bucket, filekey)}
                            variant="outline"
                            colorScheme="green"
                            size={"sm"}
                            aria-label="ダウンロード"
                            ml="3"                    
                        >
                            {"Download"}
                        </Button>
                    )}
                </HStack>
            </Td>
            <Td fontSize={"sm"}>{new Date(lastModified).toLocaleString()}</Td>
            <Td fontSize={"sm"} textAlign={"right"}>
                {size.toLocaleString()} byte
            </Td>
            {onClickDelete && (
                <Td textAlign={"center"}>
                    <IconButton
                        disabled={disabled}
                        onClick={() => onClickDelete(bucket, prefix, filekey)}
                        variant="outline"
                        colorScheme="red"
                        size={"sm"}
                        aria-label="削除"
                        icon={<CloseIcon />}
                    />
                </Td>
            )}
        </Tr>
    );
});
