import { useCallback, useState } from "react";
import axios from "axios";
import { customMetricConst, Metric } from "../types/api/customMetric";
import { useMessage } from "./useMessage";
import { useLoginUser } from "./useLoginUser";
import appconfig from "../config.json";
import {
  Datapoint,
  ResponseGetMetricStatistics,
} from "../types/api/metricDataResult";
type MetricsItem = { Metrics: Metric[] };

export const useCustomMetrics = () => {
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();

  const [metrics, setMetrics] = useState<Array<Metric>>([]);
  const [datapoints, setDatapoints] = useState<Array<Datapoint>>([]);
  const MANAGER_API_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getMetrics = useCallback(() => {
    setLoading(true);
    let atr: string = "";
    if (selectTenant != "999999") {
      atr = "?tenant=" + selectTenant;
    }
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<MetricsItem>(`${MANAGER_API_URL}/custommetric/listmetrics` + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status === 200) {
            // Convert to an array of { messageId, config }
            setMetrics(res.data.Metrics);
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(() =>
          showMessage({
            title: "メトリック一覧の取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  const getConvertCountMetric = useCallback(
    (
      tenant: string,
      entitytype: string | undefined,
      entityid: string | undefined,
      drivername: string | undefined,
      starttime: string,
      endtime: string,
      period: number
    ) => {
      setLoading(true);

      const atr: string =
        "?tenant=" +
        tenant +
        (entitytype ? "&entitytype=" + entitytype : "") +
        (entityid ? "&entityid=" + entityid : "") +
        (drivername ? "&drivername=" + drivername : "") +
        "&starttime=" +
        starttime +
        "&starttime=" +
        starttime +
        "&endtime=" +
        endtime +
        "&period=" +
        String(period);
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .get<ResponseGetMetricStatistics>(
            MANAGER_API_URL + "/custommetric/convertcount" + atr,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(async (res) => {
            if (res.status === 200) {
              setDatapoints(res.data.Datapoints);
              setDatapoints(
                res.data.Datapoints.sort((a, b) => {
                  if (a.Timestamp > b.Timestamp) return 1;
                  else return -1;
                })
              );
              setLoading(false);
            } else if (res.status === 403) {
              showMessage({ title: "権限エラー:" + res.data, status: "error" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .catch(() =>
            showMessage({
              title: "メトリクスの取得に失敗しました",
              status: "error",
            })
          )
          .finally(() => setLoading(false));
      });
    },
    []
  );

  return {
    getMetrics,
    getConvertCountMetric,
    loading,
    metrics,
    datapoints,
  };
};
