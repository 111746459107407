import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";

import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { useConfirm } from "../../../hooks/useConfirm";
import { HeaderLayout } from "../../templates/HeaderLayout";

export const ForgotPassword: FC = memo(() => {

  const { forgotPassword, loading } = useConfirm();
  const [userId, setUserId] = useState("");

  const onChangeUserId = (e: ChangeEvent<HTMLInputElement>) =>
    setUserId(e.target.value);

  const onClickForgotPassword = () => {
    forgotPassword(userId);
  };

  return (
    <HeaderLayout>
    {
      <Flex alignItems="center" justifyContent="center" mt={4}>
        <Box bg="white" w="sm" p={4} borderRadius="md" shadow="md">
          <Heading as="h1" size={{ base: "md", md: "lg" }} textAlign="center" m={{ base: "2", md: "4" }}>
            仮パスワードの送信
          </Heading>
          <Stack spacing={2} py={4} px={4}>
            <FormLabel>ユーザー名</FormLabel>
            <Input
              type="email"
              placeholder="Username"
              value={userId}
              onChange={onChangeUserId}
            />
            <Text fontSize={"sm"} pt={5}>
            パスワードを変更するための仮パスワードがメールで送信されます。
            </Text>
            <Stack pt={5}>
              <PrimaryButton
                disabled={userId === ""}
                isLoading={loading}
                onClick={onClickForgotPassword}
                type="button"
              >
              送信
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Flex>
    }
    </HeaderLayout>
  );
});
