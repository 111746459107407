import { useCallback, useState } from "react";
import axios from "axios";

import { ApiKey } from "../types/api/apikey";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from '../config.json'
import { useSelectTenant } from "./useSelectTenant";

export const useApiKey = (onClose:()=>void) => {
  const { showMessage } = useMessage();
  const { loginUser,tokenRefresh,selectTenant } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [loadingApiKey, setLoadingApiKey] = useState(false);
  const [apiKeys, setApiKeys] = useState<Array<ApiKey>>([]);
  const newApiKey: ApiKey = {
    id: "",
    name: "",
    stageKeys:[""],
  };
    const [selectedApiKey, setSelectedApiKey] = useState<ApiKey>({...newApiKey});

  const API_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  type IPostResponse = {
    access?: string;
    token?: string;
    newPasswordRequired?: string;
  };
  
  const apiKeyAdd = useCallback(
    (name: string, description: string) => {
      setLoading(true);
      var apiKey = {
        name: name,
        description: description
      };
      new Promise((resolve)=>resolve(tokenRefresh()))
      .then((token)=>{
        let atr:string = ""
        if (selectTenant){
          atr = "?tenant=" + selectTenant
        }
        axios
          .post<any>(API_MANAGER_URL + "/apikey" + atr, apiKey, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "追加しました。", status: "success" });
              setLoading(false);
            } else {
              showMessage({ title: "ApiKeyが作成できません", status: "error" });
              setLoading(false);
            }
          })
          .then(()=>{
            getApiKeys();
            onClose();
          })
          .catch(() => {
            showMessage({ title: "ユーザが作成できません", status: "error" });
            setLoading(false);
          });
        })
    },
    []
  );

  const apiKeyEdit = useCallback(
    (apiKey: ApiKey) => {
      setLoading(true);

      new Promise((resolve)=>resolve(tokenRefresh()))
      .then((token)=>{
        axios
          .put<any>(API_MANAGER_URL + "/apikey/" + apiKey.id, apiKey, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "更新しました。", status: "success" });
              setLoading(false);
            } else {
              showMessage({ title: res.statusText, status: "error" });
              setLoading(false);
            }
          })
          .then(()=>{
            getApiKeys();
            onClose();
          })
          .catch(() => {
            showMessage({ title: "ユーザが更新できません", status: "error" });
            setLoading(false);
          });
        })
    },
    []
  );

  const apiKeyDelete = useCallback((id: string) => {
    setLoading(true);
    new Promise((resolve)=>resolve(tokenRefresh()))
    .then((token)=>{
      axios
        .delete<any>(API_MANAGER_URL + "/apikey/" + id, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(async (res) => {
          console.log(res.data);
          showMessage({ title: "削除しました。", status: "success" });
          setLoading(false);
        })
        .then(()=>{
          getApiKeys();
          onClose();
      })
        .catch(() => {
          showMessage({ title: "ApiKeyが削除できません", status: "error" });
          setLoading(false);
        });
      });
    }, []);

  const apiKeyEnable = useCallback(
    (userName: string) => {
      setLoading(true);
      var user = {
        userName: userName
      };
      new Promise((resolve)=>resolve(tokenRefresh()))
      .then((token)=>{
        axios
          .put<any>(API_MANAGER_URL + "/user/enable", user, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "有効化しました。", status: "success" });
              setLoading(false);
            } else {
              showMessage({ title: res.statusText, status: "error" });
              setLoading(false);
            }
          })
          .then(()=>{
            getApiKeys();
            onClose();
          })
          .catch(() => {
            showMessage({ title: "ユーザが更新できません", status: "error" });
            setLoading(false);
          });
        });
      },
    []
  );
  const apiKeyDisable = useCallback(
    (userName: string) => {
      setLoading(true);
      var user = {
        userName: userName
      };
      new Promise((resolve)=>resolve(tokenRefresh()))
      .then((token)=>{
        axios
          .put<any>(API_MANAGER_URL + "/user/disable", user, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "無効化しました。", status: "success" });
              setLoading(false);
            } else {
              showMessage({ title: res.statusText, status: "error" });
              setLoading(false);
            }
          })
          .then(()=>{
            getApiKeys();
            onClose();
          })
          .catch(() => {
            showMessage({ title: "ユーザが更新できません", status: "error" });
            setLoading(false);
          });
      });
    },
    []
  );


  const getApiKeys = useCallback(() => {
    setLoading(true);
    new Promise((resolve)=>resolve(tokenRefresh()))
    .then((token)=>{
      let atr:string = ""
      if (selectTenant){
        atr = "?tenant=" + selectTenant
      }
      axios
        .get<Array<ApiKey>>(API_MANAGER_URL + "/apikeys" + atr, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => setApiKeys(res.data.sort((a,b)=>{if(a.name.toLowerCase() > b.name.toLowerCase())return 1;else return -1;})))
        .catch(() =>
          showMessage({ title: "ApiKeyの取得に失敗しました", status: "error" })
        )
        .finally(() => setLoading(false));      
    })
  }, []);

  const getApiKey = useCallback((id: string) => {
    setLoadingApiKey(true);
    if (id ==="") {
      setSelectedApiKey({...newApiKey})
      setLoadingApiKey(false);
    }else{
      new Promise((resolve)=>resolve(tokenRefresh()))
      .then((token)=>{
        axios
          .get<ApiKey>(API_MANAGER_URL + "/apikey/" + id, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then((res) => setSelectedApiKey(res.data))
          .catch(() =>
            showMessage({ title: "ApiKeyの取得に失敗しました", status: "error" })
          )
          .finally(() => setLoadingApiKey(false));
      })
    }
  }, []);

  return { getApiKeys,getApiKey, apiKeyAdd, apiKeyEdit, apiKeyDelete,apiKeyEnable,apiKeyDisable, loading,loadingApiKey,apiKeys,selectedApiKey };
};
