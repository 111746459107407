import { ChangeEvent, memo, useCallback, useEffect, useState, FC } from "react";
import {
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  Stack,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { useDomainApi } from "../../../hooks/useDomainApi";
import { ObjectTypeModal } from "../../organisms/modal/ObjectTypeModal";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { BasicCard } from "../../organisms/tenant/BasicCard";

export const DomainApis: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    getOpenApi,
    getDomainApis,
    loading,
    domainApis,
    openApi
  } = useDomainApi(onClose);
  const [enabled, setEnabled] = useState<boolean>(true);
  useEffect(() => getDomainApis(enabled), [getDomainApis,enabled]);

  const onClickCard = useCallback(
    (id: string) => {
      const targetObject = domainApis.find((obj) => obj.domainName === id);
      if (id) {
        getOpenApi(id);
        onOpen();
      }
    },
    [getDomainApis, onOpen]
  );
  return (
    <Stack>
      <PageDiscription>APIを選択してください。</PageDiscription>
      <Flex mx="5">
      <FormControl>
                    <FormLabel>テナント内のみ表示</FormLabel>
                    <Checkbox
                      isChecked={enabled}
                      onChange={(e) => setEnabled(e.target.checked)}
                    />
                  </FormControl>

      </Flex>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <Wrap px={{ base: 4, md: 10 }} py={{ base: 1, md: 2 }}>
            {domainApis
              .map((obj) => (
                <WrapItem key={obj.domainName} mx="auto">
                  <BasicCard
                    id={obj.domainName}
                    name={obj.domainName}
                    description={obj.distributionDomainName}
                    onClick={onClickCard}
                  />
                </WrapItem>
              ))}
          </Wrap>
        </>
      )}
      <ObjectTypeModal
        isOpen={isOpen}
        onClose={onClose}
        object={openApi}
      />
    </Stack>
  );
});
