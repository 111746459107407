import { ChangeEvent, memo, useCallback, useEffect, useState, FC } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Select,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spacer,
  Badge,
} from "@chakra-ui/react";

import { User } from "../../../types/api/user";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { useUser } from "../../../hooks/useUser";
import { debuglog } from "util";

import appconfig from "../../../config.json";
import { useLoginUser } from "../../../hooks/useLoginUser";

type Props = {
  user: User | undefined;
  isOpen: boolean;
  isAdmin?: boolean;
  onInsert: (
    userName: string,
    firstName: string,
    lastName: string,
    role: string
  ) => void;
  onUpdate: (
    userName: string,
    firstName: string,
    lastName: string,
    role: string
  ) => void;
  onDelete: (userName: string) => void;
  onEnable: (userName: string) => void;
  onDisable: (userName: string) => void;
  onReset: (userName: string) => void;
  onClose: () => void;
};

export const UserDetailModal: FC<Props> = memo((props) => {
  const loginUser = useLoginUser();
  const role_env =
    appconfig.IOT_MANAGEMENT_ENV +
    "-" +
    (loginUser ? loginUser.selectTenant : "") +
    "-";

  const { isOpen, onClose, isAdmin = false, user } = props;
  const isAddMode: boolean = user ? false : true;
  const isEnabled: boolean = user?.enabled || false;

  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    onModalReset();
  }, [user]);

  const onChangeUserName = (e: ChangeEvent<HTMLInputElement>) =>
    setUserName(e.target.value);
  const onChangeFirstName = (e: ChangeEvent<HTMLInputElement>) =>
    setFirstName(e.target.value);
  const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) =>
    setLastName(e.target.value);
  const onChangeRole = (e: ChangeEvent<HTMLSelectElement>) =>
    setRole(e.target.value);

  const onClickUpdate = () => {
    props.onUpdate(userName, firstName, lastName, role);
    props.onClose();
  };

  const onClickCreate = () => {
    props.onInsert(userName, firstName, lastName, role);
    props.onClose();
  };

  const onClickDelete = () => {
    if (window.confirm(`ユーザー「${userName}」を削除しますか？`)) {
      props.onDelete(userName);
      props.onClose();
    }
  };

  const onClickEnable = () => {
    props.onEnable(userName);
    props.onClose();
  };

  const onClickDisable = () => {
    props.onDisable(userName);
    props.onClose();
  };

  const onClickReset = () => {
    if (
      window.confirm(
        `「${userName}」のパスワードをリセットしますか？\n新しいパスワードがメールで通知されます。`
      )
    ) {
      props.onReset(userName);
      props.onClose();
    }
  };

  const onModalReset = () => {
    setUserName(user?.userName ?? "");
    setFirstName(user?.firstName ?? "");
    setLastName(user?.lastName ?? "");
    setRole(user?.role ?? role_env + "TenantUser");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>ユーザー設定</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Tabs>
            <TabList>
              <Tab>詳細</Tab>
              {isEnabled ? <Tab>その他</Tab> : <></>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>
                      ユーザーID（メールアドレス）
                      {isAddMode ? (
                        <Badge colorScheme="red" ml={2}>
                          必須
                        </Badge>
                      ) : (
                        <Badge colorScheme="gray" ml={2}>
                          変更不可
                        </Badge>
                      )}
                    </FormLabel>
                    <Input
                      type="email"
                      readOnly={!isAddMode}
                      value={userName}
                      placeholder="email"
                      onChange={onChangeUserName}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      氏名（姓）
                      <Badge colorScheme="red" ml={2}>
                        必須
                      </Badge>
                    </FormLabel>
                    <Input
                      value={lastName}
                      placeholder="lastName"
                      onChange={onChangeLastName}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      氏名（名）
                      <Badge colorScheme="red" ml={2}>
                        必須
                      </Badge>
                    </FormLabel>
                    <Input
                      value={firstName}
                      placeholder="firstName"
                      onChange={onChangeFirstName}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>ロール</FormLabel>
                    <Select value={role} onChange={onChangeRole}>
                      <option value={role_env + "TenantAdmin"}>
                        Tenant Admin
                      </option>
                      <option value={role_env + "TenantUser"}>
                        Tenant User
                      </option>
                    </Select>
                  </FormControl>
                </Stack>
                <HStack mt={4}>
                  <Spacer />
                  {isAddMode ? (
                    <PrimaryButton
                      onClick={onClickCreate}
                      disabled={
                        userName === "" || lastName === "" || firstName === ""
                      }
                    >
                      登録
                    </PrimaryButton>
                  ) : isEnabled ? (
                    <>
                      <PrimaryButton
                        onClick={onClickUpdate}
                        disabled={
                          userName === "" || lastName === "" || firstName === ""
                        }
                      >
                        更新
                      </PrimaryButton>
                    </>
                  ) : (
                    <>
                      <PrimaryButton onClick={onClickEnable}>
                        有効化
                      </PrimaryButton>
                      <PrimaryButton onClick={onClickDelete}>
                        削除
                      </PrimaryButton>
                    </>
                  )}
                </HStack>
              </TabPanel>
              <TabPanel>
                <Stack mt={4} mx={4} spacing={4}>
                  <PrimaryButton onClick={onClickReset}>
                    パスワードリセット
                  </PrimaryButton>
                  <PrimaryButton onClick={onClickDisable}>
                    ユーザーの無効化
                  </PrimaryButton>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
});
