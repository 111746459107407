import { ChangeEvent, memo, useEffect, useState, FC, useCallback } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  HStack,
  Textarea,
  Tabs,
  TabPanels,
  TabPanel,
  Spacer,
  Badge,
  Alert,
  AlertIcon,
  Tab,
  TabList,
  ListItem,
  List,
  InputGroup,
  InputLeftAddon,
  Checkbox,
} from "@chakra-ui/react";

import { MailTemplate } from "../../../types/api/mailTemplate";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { CKEditor, CKEditorEventPayload } from "ckeditor4-react";

type Props = {
  mailTemplate: MailTemplate;
  tenant: string | null;
  isOpen: boolean;
  onInsert: (TemplateName: string, SubjectPart: string, TextPart: string, HtmlPart: string) => void;
  onUpdate: (TemplateName: string, SubjectPart: string, TextPart: string, HtmlPart: string) => void;
  onDelete: (tenplate_name: string) => void;
  onClose: () => void;
  isAccountAdmin?: boolean;
};

const DataAttribute = [
  "{{data1}}",
  "{{data2}}",
  "{{data3}}",
  "{{data4}}",
  "{{data5}}",
  "{{data6}}",
  "{{data7}}",
  "{{data8}}",
  "{{data9}}",
  "{{#if result}}",
  "{{else}}",
  "{{/if}}",
];
// アラートメール関係
const ALERT_SYSTEM = "alertSystem";
const DataAttributeAlert = [
  "{{data1}}",
  "{{data2}}",
  "{{data3}}",
  "{{data4}}",
  "{{data5}}",
  "{{data6}}",
  "{{data7}}",
  "{{data8}}",
  "{{data9}}",
  "{{data10}}",
  "{{dataOver}}",
  "{{#if data1}}",
  "{{#if data2}}",
  "{{#if data3}}",
  "{{#if data4}}",
  "{{#if data5}}",
  "{{#if data6}}",
  "{{#if data7}}",
  "{{#if data8}}",
  "{{#if data9}}",
  "{{#if data10}}",
  "{{#if dataOver}}",
  "{{else}}",
  "{{/if}}",
  "{{subject}}",
  "{{interval}}",
  "{{condition1}}",
  "{{condition2}}",
  "{{comparison}}",
];

export const TemplateDetailModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, mailTemplate, tenant, isAccountAdmin } = props;
  const isAddMode: boolean = mailTemplate.TemplateName ? false : true;

  const [templateName, setTemplateName] = useState("");
  const [templateNameError, setTemplateNameError] = useState("");
  const [subjectPart, setSubjectPart] = useState("");
  const [subjectPartError, setSubjectPartError] = useState("");
  const [textPart, setTextPart] = useState("");
  const [textPartError, setTextPartError] = useState("");
  const [htmlPart, setHtmlPart] = useState("");
  const [htmlPartError, setHtmlPartError] = useState("");

  const [isAlert, setIsAlert] = useState(false);

  useEffect(() => {
    onModalReset();
  }, [mailTemplate]);

  const onChangeTemplateName = (e: ChangeEvent<HTMLInputElement>) => setTemplateName(e.target.value);

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    if (name.match(/[^A-Za-z0-9]/)) {
      setTemplateNameError("英数字のみで入力してください。");
    } else {
      setTemplateNameError("");
    }
  };

  const templateBlur = useCallback(
    (value: string, key: string) => {
      let msg = "";
      // 引数のチェック
      const dataAttr = value.match(/\{\{(.(?!\{))*\}\}/g);
      dataAttr?.forEach((attr) => {
        if (isAccountAdmin) {
          if (!DataAttributeAlert.includes(attr)) {
            msg = `${attr} は指定できません。`;
          }
        } else {
          if (!DataAttribute.includes(attr)) {
            msg = `${attr} は指定できません。`;
          }
        }
      });
      switch (key) {
        case "SubjectPart":
          setSubjectPartError(msg);
          break;
        case "TextPart":
          setTextPartError(msg);
          break;
        case "HtmlPart":
          setHtmlPartError(msg);
          break;
      }
    },
    [isAccountAdmin]
  );

  const onChangeSubjectPart = (e: ChangeEvent<HTMLInputElement>) => setSubjectPart(e.target.value);

  const onChangeTextPart = (e: ChangeEvent<HTMLTextAreaElement>) => setTextPart(e.target.value);

  const onClickUpdate = () => {
    props.onUpdate(templateName, subjectPart, textPart, htmlPart);
    props.onClose();
  };

  const onClickCreate = () => {
    if (isAlert) {
      props.onInsert(`${ALERT_SYSTEM}-${templateName}`, subjectPart, textPart, htmlPart);
    } else {
      props.onInsert(`${tenant}-${templateName}`, subjectPart, textPart, htmlPart);
    }
    props.onClose();
  };

  const onClickDelete = () => {
    if (window.confirm(`メールテンプレート「${templateName}（${subjectPart}）」を削除しますか？`)) {
      props.onDelete(templateName);
      props.onClose();
    }
  };

  const onModalReset = () => {
    if (mailTemplate) {
      setTemplateName(mailTemplate.TemplateName ?? "");
      setSubjectPart(mailTemplate.SubjectPart ?? "");
      setTextPart(mailTemplate.TextPart);
      setHtmlPart(mailTemplate.HtmlPart);
      setIsAlert(mailTemplate.TemplateName?.startsWith(ALERT_SYSTEM) ?? false);
    }
    setSubjectPartError("");
    setHtmlPartError("");
    setTextPartError("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      trapFocus={false}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>メールテンプレート設定</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Tabs>
            <TabPanels>
              <TabPanel>
                <Stack spacing={4}>
                  {isAddMode ? (
                    <FormControl>
                      <FormLabel>
                        テンプレート名
                        {isAddMode ? (
                          <Badge colorScheme="red" ml={2}>
                            必須
                          </Badge>
                        ) : (
                          <Badge colorScheme="gray" ml={2}>
                            変更不可
                          </Badge>
                        )}
                      </FormLabel>
                      <HStack>
                        <InputGroup>
                          {isAlert ? (
                            <InputLeftAddon children={`${ALERT_SYSTEM}-`} />
                          ) : (
                            <InputLeftAddon children={`${tenant}-`} />
                          )}
                          <Input
                            value={templateName}
                            placeholder={isAlert ? "tenant" : "templateName"}
                            onChange={onChangeTemplateName}
                            onBlur={handleBlur}
                          />
                        </InputGroup>
                        {isAccountAdmin && (
                          <FormControl w="fit-content">
                            <Checkbox isChecked={isAlert} onChange={(e) => setIsAlert(e.target.checked)}>
                              ALERT
                            </Checkbox>
                          </FormControl>
                        )}
                      </HStack>
                      {templateNameError && (
                        <Alert status="error" variant="top-accent">
                          <AlertIcon />
                          {templateNameError}
                        </Alert>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl>
                      <FormLabel>
                        テンプレート名
                        <Badge colorScheme="gray" ml={2}>
                          変更不可
                        </Badge>
                      </FormLabel>
                      <Input
                        readOnly={true}
                        value={templateName}
                        placeholder="TemplateName"
                        onChange={onChangeTemplateName}
                      />
                    </FormControl>
                  )}
                  <FormControl>
                    <FormLabel>
                      件名
                      <Badge colorScheme="red" ml={2}>
                        必須
                      </Badge>
                    </FormLabel>
                    <Input
                      value={subjectPart}
                      placeholder="subject"
                      onChange={onChangeSubjectPart}
                      onBlur={(e) => {
                        templateBlur(e.target.value, "SubjectPart");
                      }}
                    />
                    {subjectPartError && (
                      <Alert status="error" variant="top-accent">
                        <AlertIcon />
                        {subjectPartError}
                      </Alert>
                    )}
                  </FormControl>
                  <Tabs>
                    <TabList>
                      <Tab>
                        テキスト形式
                        <Badge colorScheme="red" ml={2}>
                          必須
                        </Badge>
                      </Tab>
                      <Tab>
                        HTML形式
                        <Badge colorScheme="red" ml={2}>
                          必須
                        </Badge>
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <FormControl>
                          <Textarea
                            value={textPart}
                            rows={12}
                            onChange={onChangeTextPart}
                            onBlur={(e) => {
                              templateBlur(e.target.value, "TextPart");
                            }}
                          />
                          {textPartError && (
                            <Alert status="error" variant="top-accent">
                              <AlertIcon />
                              {textPartError}
                            </Alert>
                          )}
                        </FormControl>
                      </TabPanel>
                      <TabPanel>
                        <FormControl>
                          <CKEditor
                            config={{ width: "100%", versionCheck: false }}
                            initData={mailTemplate.HtmlPart}
                            onChange={(e) => {
                              setHtmlPart(e.editor.getData());
                            }}
                            onBlur={(e) => {
                              templateBlur(e.editor.getData(), "HtmlPart");
                            }}
                          />
                          {htmlPartError && (
                            <Alert status="error" variant="top-accent">
                              <AlertIcon />
                              {htmlPartError}
                            </Alert>
                          )}
                        </FormControl>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Stack>
                <HStack mt={4}>
                  <List fontSize={"sm"} ml="2" color={"gray.700"}>
                    <ListItem>{`・「{{data1}}」～「{{data9}}」で引数を指定できます。`}</ListItem>
                    <ListItem>{`・「{{#if result}}該当{{else}}非該当{{/if}}」で通知条件の判定に応じた分岐を指定できます。`}</ListItem>
                  </List>
                  <Spacer />
                  {isAddMode ? (
                    <PrimaryButton
                      onClick={onClickCreate}
                      disabled={
                        templateName === "" ||
                        subjectPart === "" ||
                        textPart === "" ||
                        htmlPart === "" ||
                        templateNameError !== "" ||
                        subjectPartError !== "" ||
                        textPartError !== "" ||
                        htmlPartError !== ""
                      }
                    >
                      登録
                    </PrimaryButton>
                  ) : (
                    <>
                      <PrimaryButton
                        onClick={onClickUpdate}
                        disabled={
                          templateName === "" ||
                          subjectPart === "" ||
                          textPart === "" ||
                          htmlPart === "" ||
                          templateNameError !== "" ||
                          subjectPartError !== "" ||
                          textPartError !== "" ||
                          htmlPartError !== ""
                        }
                      >
                        更新
                      </PrimaryButton>
                      <PrimaryButton onClick={onClickDelete}>削除</PrimaryButton>
                    </>
                  )}
                </HStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
});
