import { memo, FC } from "react";
import { Box, Stack, Text } from "@chakra-ui/react";

type Props = {
  tenant_id: string;
  accountName: string;
  onClick: (userName: string) => void;
};

export const TenantCard: FC<Props> = memo((props) => {
  const { tenant_id, accountName, onClick } = props;

  return (
    <Box
      w={{base:"calc(100vw - 60px)", md:"calc(50vw - 60px)", lg:"calc(33vw - 50px)"}}
      minHeight="100px"
      bg="white"
      borderRadius="10px"
      shadow="md"
      p={4}
      _hover={{ cursor: "pointer", opacity: 0.8 }}
      onClick={() => onClick(tenant_id)}
    >
      <Stack textAlign="left">
        <Text fontSize="md" fontWeight="bold">
          {tenant_id}
        </Text>
        <Text fontSize="sm" color="gray">
          {accountName}
        </Text>
      </Stack>
    </Box>
  );
});
