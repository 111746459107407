import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
  useMemo,
  FC,
  useRef,
} from "react";
import {
  Center,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Spinner,
  Stack,
  Th,
  Tr,
} from "@chakra-ui/react";

import appconfig from "../../../config.json";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { useCsvDownload } from "../../../hooks/useDatalakeCsvDownloads";
import { useTenant } from "../../../hooks/useTenant";
import { useParameters } from "../../../hooks/useParameters";
import { TenantSingleParm } from "../../../types/api/parameterTypes";
import { DeviceInfo } from "../../../types/api/tenantTableTypes";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { SearchInput } from "../../atoms/Input/SearchInput";
import { TableContent } from "../../organisms/layout/TableContent";
import { FileRow } from "../../organisms/datarow/FileRow";

export const DatalakeCsvDownloads: FC = memo(() => {
  const { loginUser, selectTenant } = useLoginUser();
  const {
    getCsvFileList,
    clearFileList,
    getFile,
    fileListLoading,
    fileLoading,
    jsonContent,
    csvFiles,
  } = useCsvDownload();
  const { tenantGet, tenant, loading: deviceLoading } = useTenant();
  const [deviceName, setDeviceId] = useState<string>();
  const [Devices, setDevices] = useState<DeviceInfo[]>();
  const IOT_MANAGEMENT_ENV: string = appconfig["IOT_MANAGEMENT_ENV"];
  const [bucket, setBucket] = useState<string | undefined>(undefined);
  const [prefix, setPrefix] = useState<string | undefined>(undefined);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState("");
  const [sortSymbol, setSortSymbol] = useState("");
  const didLogRef = useRef(false);
  const [sortConfig, setSortConfig] = useState({
    sortKey: "Key",
    direction: "descending",
  });
  const {
    getParameter: getPublicBucket,
    parameter: publicBucket,
    loading: publicLoading,
  } = useParameters();
  const {
    getParameter: getPrivateBucket,
    parameter: privateBucket,
    loading: privateLoading,
  } = useParameters();
  const {
    getParameter: getPersonalBucket,
    parameter: personalBucket,
    loading: personaLoading,
  } = useParameters();

  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      let tenant_id: string = "";
      if (selectTenant) {
        tenant_id = selectTenant;
      } else {
        if (loginUser) tenant_id = loginUser.tenant;
      }
      tenantGet(tenant_id);
    }
  }, []);

  // デバイスの一覧を設定
  useEffect(() => {
    if (tenant) {
      setDevices(tenant?.devices);
    }
  }, [tenant]);

  // セキュリティレベル毎のバケット情報を取得
  useEffect(() => {
    if (selectTenant) {
      const basePath = "/" + IOT_MANAGEMENT_ENV + "/" + selectTenant + "/";
      getPrivateBucket(basePath + TenantSingleParm.PrivateDataLake);
      getPublicBucket(basePath + TenantSingleParm.PublicDataLake);
      getPersonalBucket(basePath + TenantSingleParm.AnonymizedPersonalDataLake);
    }
  }, []);

  // デバイスを選択した際の処理
  const onChangeDeviceId = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectDevice = Devices?.find(
      (device) => device.DeviceName === e.target.value
    );
    if (selectDevice) {
      // bucketにデバイスのセキュリティレベルに応じたバケット情報を設定
      switch (selectDevice.SecurityLevel) {
        case "public":
          setBucket(publicBucket);
          break;
        case "private":
          setBucket(privateBucket);
          break;
        case "a-personal":
          setBucket(personalBucket);
          break;
        default:
          setBucket(undefined);
          break;
      }

      // prefixにデバイス名を設定
      setPrefix(selectDevice.DeviceName);

      // ソート内容を初期化（ファイル名の降順、記号無し）
      setSortConfig({ sortKey: "Key", direction: "descending" });
      setSortSymbol("");
    } else {
      setBucket(undefined);
      setPrefix(undefined);
      clearFileList();
    }
  };

  // csvファイルの一覧取得処理を呼び出し
  useEffect(() => {
    if (bucket && prefix) getCsvFileList(bucket, prefix);
  }, [bucket, prefix]);

  // csvファイル一覧のソート
  const sortedData = useMemo(() => {
    let sortableData = csvFiles.filter((obj) => obj.Key.split(/[/]+/).pop());
    if (sortConfig.sortKey !== null) {
      switch (sortConfig.sortKey) {
        case "Key":
          sortableData.sort((a, b) => {
            if (a.Key < b.Key) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a.Key > b.Key) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
            return 0;
          });
          break;
        case "LastModified":
          sortableData.sort((a, b) => {
            if (a.LastModified < b.LastModified) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a.LastModified > b.LastModified) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
            return 0;
          });
          break;
        case "Size":
          sortableData.sort((a, b) => {
            if (a.Size < b.Size) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a.Size > b.Size) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
            return 0;
          });
          break;
        default:
          break;
      }
    }
    return sortableData;
  }, [csvFiles, sortConfig]);

  // ソートキー等の設定（ヘッダークリック時）
  const requestSort = (sortKey: string) => {
    let direction = "ascending";
    setSortSymbol("▲");
    if (
      sortConfig.sortKey === sortKey &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
      setSortSymbol("▼");
    }
    setSortConfig({ sortKey, direction });
  };

  // csvファイルダウンロード処理の呼び出し（ダウンロードボタンクリック時）
  const onClickFileDownload = useCallback(
    (bucket: string | undefined, key: string) => {
      if (bucket && key) {
        getFile(bucket, key);
      }
    },
    []
  );

  useEffect(() => {
    setDisabled(
      deviceLoading ||
        fileLoading ||
        publicLoading ||
        privateLoading ||
        personaLoading ||
        fileListLoading
    );
  }, [
    deviceLoading,
    fileLoading,
    publicLoading,
    privateLoading,
    personaLoading,
    fileListLoading,
  ]);

  return (
    <Stack maxW={"1200px"}>
      <PageDiscription>
        CSVダウンロードを行うドライバの種類を選択してください。
      </PageDiscription>
      <FormControl>
        <HStack>
          <FormLabel whiteSpace={"nowrap"}>ドライバの種類</FormLabel>
          {deviceLoading ? (
            <Spinner key="deviceSpinner" color="teal.500" />
          ) : (
            <Select
              value={deviceName}
              onChange={onChangeDeviceId}
              bg="gray.50"
              disabled={fileLoading}
            >
              <option value={""}></option>
              {Devices?.map((device) => (
                <option key={device.DeviceName} value={device.DeviceName}>
                  {device.DeviceName}
                </option>
              ))}
            </Select>
          )}
        </HStack>
      </FormControl>
      <HStack>
        <SearchInput
          placeholder={"ファイル名"}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </HStack>
      {disabled ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          {sortedData ? (
            <TableContent
              heightOffset="0px"
              headerRows={
                <Tr key="header">
                  <Th
                    w="100%"
                    onClick={() => requestSort("Key")}
                    className="table-header"
                  >
                    {sortConfig.sortKey === "Key"
                      ? "ファイル名" + sortSymbol
                      : "ファイル名"}
                  </Th>
                  <Th
                    onClick={() => requestSort("LastModified")}
                    className="table-header"
                  >
                    {sortConfig.sortKey === "LastModified"
                      ? "最終更新日時" + sortSymbol
                      : "最終更新日時"}
                  </Th>
                  <Th
                    onClick={() => requestSort("Size")}
                    className="table-header"
                  >
                    {sortConfig.sortKey === "Size"
                      ? "ファイルサイズ" + sortSymbol
                      : "ファイルサイズ"}
                  </Th>
                </Tr>
              }
              bodyRows={sortedData
                .filter((obj) => {
                  return (
                    searchValue === "" ||
                    obj.Key.indexOf(searchValue) >= 0 ||
                    `${obj.Key}`.indexOf(searchValue) >= 0
                  );
                })
                .map((obj) => (
                  <FileRow
                    key={obj.Key}
                    bucket={bucket ?? ""}
                    prefix={prefix ?? ""}
                    filekey={obj.Key}
                    lastModified={obj.LastModified}
                    size={obj.Size}
                    onClickGet={onClickFileDownload}
                    disabled={disabled}
                  />
                ))}
            ></TableContent>
          ) : (
            <></>
          )}
        </>
      )}
    </Stack>
  );
});
