import { ChangeEvent, memo, useState, FC, FormEvent, useRef } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Stack,
  Link,
} from "@chakra-ui/react";

import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { useAuth } from "../../../hooks/useAuth";

import { HeaderLayout } from "../../templates/HeaderLayout";
import { useNavigate } from "react-router-dom";



export const Login: FC = memo(() => {
  const { signIn, loading } = useAuth();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const onChangeUserId = (e: ChangeEvent<HTMLInputElement>) =>
    setUserId(e.target.value);

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const onClickLogin = () => {
    signIn(userId, password);
  };

  const onClickForgotPassword = () => {
    navigate(`/forgotPassword`);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    console.log("handleSubmit");
    onClickLogin();
    event.preventDefault();
  };

  return (
    <HeaderLayout>
      {
        <form noValidate onSubmit={e => handleSubmit(e)}>
          <Flex alignItems="center" justifyContent="center" mt={4}>
            <Box bg="white" w="sm" p={4} borderRadius="md" shadow="md">
              <Heading
                as="h1"
                size={{ base: "md", md: "lg" }}
                textAlign="center"
                m={{ base: "2", md: "4" }}
              >
                ログイン
              </Heading>
              <Stack spacing={2} py={4} px={4}>
                <FormLabel>ユーザー名</FormLabel>
                <Input
                  type="email"
                  placeholder="Username"
                  value={userId}
                  onChange={onChangeUserId}
                  autoComplete="on"
                />
                <FormLabel>パスワード</FormLabel>
                <Input
                  type="password"
                  placeholder="password"
                  value={password}
                  onChange={onChangePassword}
                  autoComplete="on"
                />
                <Stack pt={5}>
                  <PrimaryButton
                    disabled={userId === ""}
                    isLoading={loading}
                    onClick={()=>{}}
                    type="submit"
                  >
                    ログイン
                  </PrimaryButton>
                </Stack>
                <Flex>
                  <Link
                    mt={"5"}
                    fontSize={"sm"}
                    color="teal.500"
                    onClick={onClickForgotPassword}
                  >
                    パスワードを忘れたとき
                  </Link>
                </Flex>
              </Stack>
            </Box>
          </Flex>
        </form>
      }
    </HeaderLayout>
  );
});
