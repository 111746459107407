import { useCallback, useState } from "react";
import axios, { AxiosRequestHeaders } from "axios";

import { UsagePlan, KeyInfo, Usage } from "../types/api/usageplan";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useUsagePlan = (onClose: () => void) => {
  const { showMessage } = useMessage();
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [loadingUsagePlan, setLoadingUsagePlan] = useState(false);
  const [loadingApiKey, setLoadingApiKey] = useState(false);
  const [loadingUsage, setLoadingUsage] = useState(false);
  const [usagePlans, setUsagePlans] = useState<Array<UsagePlan>>([]);
  const [usage, setUsage] = useState<Record<string, number[][]> | undefined>(
    undefined
  );
  const newUsagePlan: UsagePlan = {
    id: "",
    name: "",
    description: "",
    apiStages: [],
    throttle: {
      burstLimit: 0,
      rateLimit: 20,
    },
    quota: {
      limit: 10000,
      offset: 0,
      period: "DAY",
    },
  };
  const [selectedUsagePlan, setSelectedUsagePlan] =
    useState<UsagePlan>({...newUsagePlan});
  const [usagePlanKeys, setUsagePlanKeys] = useState<Array<KeyInfo>>([]);

  const API_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  type IPostResponse = {
    access?: string;
    token?: string;
    newPasswordRequired?: string;
  };

  const usagePlanAdd = useCallback((usagePlan: UsagePlan) => {
    setLoading(true);
    var UsagePlan = usagePlan;
    delete UsagePlan.id;

    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .post<any>(API_MANAGER_URL + "/usageplan" + atr, UsagePlan, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.data) {
            console.log(res.data);
            showMessage({ title: "追加しました。", status: "success" });
            setLoading(false);
          } else {
            console.log(res);
            showMessage({
              title: "使用量プランが作成できません",
              status: "error",
            });
            setLoading(false);
          }
        })
        .then(() => {
          getUsagePlans(true);
          onClose();
        })
        .catch((e: any) => {
          if (e.response && e.response.status === 403) {
            showMessage({
              title: "権限エラー:" + e.response.data,
              status: "error",
            });
          } else {
            showMessage({
              title: "使用量プランが作成できません",
              status: "error",
            });
          }
          setLoading(false);
        });
    });
  }, []);

  const usagePlanEdit = useCallback((usagePlan: UsagePlan) => {
    setLoading(true);
    var UsagePlan = usagePlan;
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }      axios
        .put<any>(API_MANAGER_URL + "/usageplan/" + UsagePlan.id + atr, UsagePlan, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.data) {
            console.log(res.data);
            showMessage({ title: "更新しました。", status: "success" });
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            showMessage({ title: res.statusText, status: "error" });
            setLoading(false);
          }
        })
        .then(() => {
          getUsagePlans(true);
          onClose();
        })
        .catch(() => {
          showMessage({
            title: "使用量プランが更新できません",
            status: "error",
          });
          setLoading(false);
        });
    });
  }, []);

  const usagePlanDelete = useCallback((id: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .delete<any>(API_MANAGER_URL + "/usageplan/" + id, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status === 204) {
            showMessage({ title: "削除しました。", status: "success" });
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            showMessage({ title: res.statusText, status: "error" });
            setLoading(false);
          }
        })
        .then(() => {
          getUsagePlans(true);
          onClose();
        })
        .catch(() => {
          showMessage({ title: "UsagePlanが削除できません", status: "error" });
          setLoading(false);
        });
    });
  }, []);

  const getUsagePlans = useCallback((tenantSelect: boolean) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (!tenantSelect) {
        atr = "?tenant=none";
      } else if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .get<Array<UsagePlan>>(API_MANAGER_URL + "/usageplans" + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) =>
          setUsagePlans(
            res.data.sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              else return -1;
            })
          )
        )
        .catch(() =>
          showMessage({
            title: "UsagePlanの取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  const getUsagePlan = useCallback((id: string) => {
    setLoadingUsagePlan(true);
    if (id === "") {
      setSelectedUsagePlan({...newUsagePlan});
      setUsagePlanKeys([]);
      setLoadingUsagePlan(false);
    } else {
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .get<UsagePlan>(API_MANAGER_URL + "/usageplan/" + id, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data.keys) {
              setUsagePlanKeys(res.data.keys);
            } else {
              setUsagePlanKeys([]);
            }
            setSelectedUsagePlan(res.data);
          })
          .catch(() =>
            showMessage({
              title: "UsagePlanの取得に失敗しました",
              status: "error",
            })
          )
          .finally(() => setLoadingUsagePlan(false));
      });
    }
  }, []);

  const usagePlanKeyAdd = useCallback((usagePlanId: string, keyId: string) => {
    setLoadingApiKey(true);
    var UsagePlanKey = {
      keyId: keyId,
      keyType: "API_KEY",
    };
    console.log(usagePlanKeys);

    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .post<Array<KeyInfo>>(
          API_MANAGER_URL + "/usageplan/" + usagePlanId + "/keys",
          UsagePlanKey,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(async (res) => {
          console.log(res.data);
          if (res.data) {
            let keys: Array<KeyInfo>;
            keys = usagePlanKeys.concat(res.data);
            setUsagePlanKeys(keys);
            showMessage({ title: "追加しました。", status: "success" });
            setLoadingApiKey(false);
          } else {
            console.log(res);
            showMessage({
              title: "API Keyを追加できません。",
              status: "error",
            });
            setLoadingApiKey(false);
          }
        }).then(()=>{
          getUsagePlan(usagePlanId);
        })
        .catch((e: any) => {
          if (e.response && e.response.status === 403) {
            showMessage({
              title: "権限エラー:" + e.response.data,
              status: "error",
            });
          } else {
            showMessage({
              title: "API Keyを追加できません。",
              status: "error",
            });
          }
          setLoadingApiKey(false);
        });
    });
  }, []);

  const usagePlanKeyDelete = useCallback(
    (usagePlanId: string, keyId: string) => {
      setLoadingApiKey(true);
      console.log("usagePlanKeyDelete:" + keyId);
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .delete<any>(
            API_MANAGER_URL + "/usageplan/" + usagePlanId + "/keys/" + keyId,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(async (res) => {
            console.log(res);
            if (res.status === 204) {
              console.log(keyId);
              const keys: Array<KeyInfo> = usagePlanKeys.filter((object) => {
                return object.id !== keyId;
              });
              setUsagePlanKeys(keys);
              showMessage({ title: "削除しました。", status: "success" });
              setLoadingApiKey(false);
            } else if (res.status === 403) {
              showMessage({ title: "権限エラー:" + res.data, status: "error" });
              setLoadingApiKey(false);
            } else {
              showMessage({ title: res.statusText, status: "error" });
              setLoadingApiKey(false);
            }
          }).then(()=>{
            getUsagePlan(usagePlanId);
          })
          .catch(() => {
            showMessage({ title: "削除できません", status: "error" });
            setLoadingApiKey(false);
          });
      });
    },
    []
  );
  const getUsage = useCallback((id: string) => {
    setLoadingUsage(true);
    if (id === "") {
      setUsage(undefined);
      setLoadingUsage(false)
    } else {
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .get<Usage>(API_MANAGER_URL + "/usageplan/" + id + "/usage", {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data.items) {
              setUsage(res.data.items);
            } else {
              setUsage(undefined);
            }
            setUsage(res.data.items);
          })
          .catch(() =>
            showMessage({
              title: "UsagePlanの取得に失敗しました",
              status: "error",
            })
          )
          .finally(() => setLoadingUsage(false));
      });
    }
  }, []);

  return {
    getUsagePlans,
    getUsagePlan,
    usagePlanAdd,
    usagePlanEdit,
    usagePlanDelete,
    usagePlanKeyAdd,
    usagePlanKeyDelete,
    getUsage,
    loading,
    loadingUsagePlan,
    loadingApiKey,
    loadingUsage,
    usagePlans,
    selectedUsagePlan,
    usagePlanKeys,
    usage,
  };
};
