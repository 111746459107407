/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, FC, ReactNode } from "react";
import {
  Box,
  Flex,
  HStack,
  VStack,
  Link,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Spacer,
  Grid,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Drawer,
  DrawerContent,
  CloseButton,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";

import { MenuIconButton } from "../../atoms/button/MenuIconButton";
import { useLoginUser } from "../../../hooks/useLoginUser";
import appconfig from "../../../config_app.json";
import { InfoIcon } from "@chakra-ui/icons";
import { Footer } from "./Footer";
import { categorys, menuSet } from "../../../MyRouter";

interface LinkItemProps {
  name: string; // メニュー表示名
  onClick: () => void; // 起動する関数
  user: boolean; // 権限
  tenantAdmin: boolean; // 権限
  accountAdmin: boolean; // 権限
}
interface LinkCategoryProps {
  categoryName: string; // メニュー分類名
  LinkItems: Array<LinkItemProps>;
}

type SidebarWithHeaderProps = {
  children: ReactNode;
  isLoggedIn: boolean;
  isAdmin: boolean | undefined;
  isAccountAdmin: boolean | undefined;
  longinUserName: string;
  selectTenant: string | null;
  LinkCategorys: Array<LinkCategoryProps>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onClickHome: () => void;
  onClickLoginout: () => void;
};

export const SidebarWithHeader: FC<SidebarWithHeaderProps> = memo((props) => {
  const {
    children,
    isLoggedIn,
    isAdmin,
    isAccountAdmin,
    longinUserName,
    selectTenant,
    LinkCategorys,
    isOpen,
    onOpen,
    onClose,
    onClickHome,
    onClickLoginout,
  } = props;

  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                  "footer footer"`}
      gridTemplateRows={{ base: "40px 1fr auto", md: "60px 1fr auto" }}
      gridTemplateColumns={{
        base: "0px 1fr",
        md: isLoggedIn ? "250px 1fr" : "0px 1fr",
      }}
      color="blackAlpha.700"
      h="100vh"
      w="100vw"
      maxH="100%"
    >
      <GridItem
        bg={"teal.500"}
        area={"header"}
        pos="fixed"
        zIndex={10}
        w="100%"
        h={{ base: "40px", md: "60px" }}
      >
        <MobileNav
          onOpen={onOpen}
          onClickHome={onClickHome}
          onClickLoginout={onClickLoginout}
          longinUserName={longinUserName}
          selectTenant={selectTenant}
          isLoggedIn={isLoggedIn}
          isAdmin={isAdmin}
          isAccountAdmin={isAccountAdmin}
        />
      </GridItem>
      <GridItem bg="teal.400" area={"nav"}>
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
        >
          <DrawerContent>
            <SidebarContent
              onClose={onClose}
              LinkCategorys={LinkCategorys}
              isLoggedIn={isLoggedIn}
              isAdmin={isAdmin}
              isAccountAdmin={isAccountAdmin}
            />
          </DrawerContent>
        </Drawer>
        <SidebarContent
          onClose={() => onClose}
          LinkCategorys={LinkCategorys}
          isLoggedIn={isLoggedIn}
          isAdmin={isAdmin}
          isAccountAdmin={isAccountAdmin}
          display={{ base: "none", md: "block" }}
        />
      </GridItem>
      <GridItem
        area={"main"}
        color="black"
        px={{ base: "2", md: "5" }}
        h="full"
        overflow="auto"
      >
        {children}
      </GridItem>
      <GridItem
        area={"footer"}
        bg="gray.400"
        color="gray.50"
        padding={{ base: 1, md: 2 }}
      >
        <Footer />
      </GridItem>
    </Grid>
  );
});

interface SidebarProps extends BoxProps {
  onClose: () => void;
  LinkCategorys: Array<LinkCategoryProps>;
  isLoggedIn: boolean;
  isAdmin: boolean | undefined;
  isAccountAdmin: boolean | undefined;
}

const SidebarContent = ({
  onClose,
  LinkCategorys,
  isLoggedIn,
  isAdmin,
  isAccountAdmin,
  ...rest
}: SidebarProps) => {
  return (
    <>
      {isLoggedIn ? (
        <Box
          bg="teal.400"
          color="gray.50"
          border="1px"
          borderColor={"gray.200"}
          h="full"
          {...rest}
        >
          <Flex bg="gray.400">
            <CloseButton
              display={{ base: "flex", md: "none" }}
              onClick={onClose}
              w="100%"
            />
          </Flex>

          <Accordion allowMultiple bg="teal.500" w="100%">
            {LinkCategorys.map((category) => (
              <Box key={category.categoryName}>
                {category.LinkItems.filter((link) => {
                  return (
                    link.user ||
                    (isAdmin && link.tenantAdmin) ||
                    (isAccountAdmin && link.accountAdmin)
                  );
                }).length > 0 ? (
                  <AccordionItem>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {category.categoryName}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} bg="teal.400">
                      {category.LinkItems.filter((link) => {
                        return (
                          link.user ||
                          (isAdmin && link.tenantAdmin) ||
                          (isAccountAdmin && link.accountAdmin)
                        );
                      }).map((link) => (
                        <NavItem
                          key={link.name}
                          onClick={() => {
                            link.onClick();
                            onClose();
                          }}
                        >
                          {link.name}
                        </NavItem>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                ) : (
                  <></>
                )}
              </Box>
            ))}
          </Accordion>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

interface NavItemProps extends FlexProps {
  children: ReactNode;
}
const NavItem = ({ children, ...rest }: NavItemProps) => {
  return (
    <Link
      href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="3"
        fontSize={"sm"}
        role="group"
        cursor="pointer"
        _hover={{
          bg: "blue.300",
          color: "white",
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  onClickLoginout: () => void;
  onClickHome: () => void;
  longinUserName: string;
  selectTenant: string | null;
  isLoggedIn: boolean;
  isAdmin: boolean | undefined;
  isAccountAdmin: boolean | undefined;
}

const MobileNav = ({
  onOpen,
  onClickLoginout,
  onClickHome,
  longinUserName,
  selectTenant,
  isLoggedIn,
  isAdmin,
  isAccountAdmin,
}: MobileProps) => {
  const SYSTEM_TITLE: string = appconfig["SYSTEM_TITLE"];

  return (
    <Flex color="gray.50" padding={{ base: 1, md: 2 }}>
      {isLoggedIn ? <MenuIconButton onOpen={onOpen} /> : <></>}
      <Flex
        align="center"
        as="a"
        mr={8}
        _hover={{ cursor: "pointer", color: "yellow" }}
        onClick={onClickHome}
      >
        <Heading
          as="h1"
          fontSize={{ base: "md", md: "lg" }}
          m={{ base: "1", md: "2" }}
        >
          {SYSTEM_TITLE}
        </Heading>
      </Flex>
      <Spacer />
      {isLoggedIn ? (
        <HStack spacing={{ base: "0", md: "6" }}>
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton _hover={{ cursor: "pointer", color: "yellow" }}>
                <InfoIcon
                  display={{ base: "flex", md: "none" }}
                  m="2"
                ></InfoIcon>
                <HStack>
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                    textAlign="right"
                  >
                    <Text fontSize="sm">{longinUserName}</Text>
                    <Text fontSize="xs" color="gray.300">
                      {selectTenant}
                    </Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}></Box>
                </HStack>
              </MenuButton>
              <MenuList borderColor={"gray.200"} color="gray.500">
                <Grid
                  templateRows="repeat(3, 1fr)"
                  templateColumns="4rem 1fr"
                  fontSize={"sm"}
                  m="2"
                >
                  <GridItem fontSize={"xs"}>ユーザー</GridItem>
                  <GridItem>{longinUserName}</GridItem>
                  <GridItem fontSize={"xs"}>ロール</GridItem>
                  <GridItem>
                    {`${isAccountAdmin ? "Account" : "Tenant"} ${
                      isAdmin ? "Admin" : "User"
                    }`}
                  </GridItem>
                  <GridItem fontSize={"xs"}>テナント</GridItem>
                  <GridItem>{selectTenant}</GridItem>
                </Grid>
                <MenuDivider />
                <MenuItem onClick={onClickLoginout}>ログアウト</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      ) : (
        <></>
      )}
    </Flex>
  );
};

type Props = {
  children: ReactNode;
};

export const MyMenu: FC<Props> = memo((props) => {
  const { children } = props;

  const { loginUser, tokenRefresh, selectTenant, logout, isLoggedIn } =
    useLoginUser();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const longinUserName = loginUser ? loginUser.userName : "";
  const navigate = useNavigate();

  const onClickHome = useCallback(() => navigate("/"), []);
  const onClickLoginout = useCallback(() => {
    if (isLoggedIn) {
      logout();
    }
    navigate("/");
  }, []);

  useEffect(() => {
    let tenant_id: string = "";
    if (selectTenant) {
      tenant_id = selectTenant;
    } else {
      if (loginUser) tenant_id = loginUser.tenant;
    }
  }, [loginUser, selectTenant]);

  // メニュー項目
  const LinkCategorys: Array<LinkCategoryProps> = [];
  const sortCategorys = categorys.sort((a, b) => a.seq - b.seq);
  sortCategorys.map((category) => {
    const linkCategory: LinkCategoryProps = {
      categoryName: category.caption,
      LinkItems: [],
    };
    const menus = menuSet.filter((menu) =>
      menu.category ? menu.category === category : false
    );
    const sortMenus = menus.sort((a, b) => a.seq - b.seq);
    sortMenus.map((menu) => {
      linkCategory.LinkItems.push({
        name: menu.caption,
        onClick: () => navigate(`/home/${menu.path}`),
        user: menu.allow.user,
        tenantAdmin: menu.allow.tenantAdmin,
        accountAdmin: menu.allow.accountAdmin,
      });
    });
    LinkCategorys.push(linkCategory);
  });
  return (
    <SidebarWithHeader
      children={children}
      isLoggedIn={isLoggedIn}
      isAdmin={loginUser?.isAdmin}
      isAccountAdmin={loginUser?.isAccountAdmin}
      longinUserName={longinUserName}
      selectTenant={selectTenant}
      LinkCategorys={LinkCategorys}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onClickHome={onClickHome}
      onClickLoginout={onClickLoginout}
    />
  );
});
