import { memo, useCallback, useEffect, useState, FC, useRef } from "react";
import {
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Stack,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { TypeCard } from "../../organisms/tenant/TypeCard";
import { DeviceCard } from "../../organisms/tenant/DeviceCard";
import { useTenant } from "../../../hooks/useTenant";
import { EntityTypeDetailModal } from "../../organisms/modal/EntityTypeDetailModal";
import { DeviceDetailModal } from "../../organisms/modal/DeviceDetailModal";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { useParams } from "react-router-dom";
import {
  EntityTypeInfo,
  DeviceInfo,
  PermissionInfo,
} from "../../../types/api/tenantTableTypes";
import {
  SecurityLevelType,
  SecurityLevel_V1Type,
} from "../../../types/api/commonTypes";
import { useParameters } from "../../../hooks/useParameters";
import { useGroup } from "../../../hooks/useGroup";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import appconfig from "../../../config.json";
import { useStepFunctions } from "../../../hooks/useStepFunctins";
import { TenantSingleParm } from "../../../types/api/parameterTypes";

export const Tenant: FC = memo(() => {
  const { loginUser, selectTenant } = useLoginUser();
  const dlType = useDisclosure();
  const dlDevice = useDisclosure();
  const { tenantGet, tenantEdit, loading, tenant } = useTenant();
  const { getParameter, parameter } = useParameters();
  const { startExecution } = useStepFunctions();
  const { getDeviceNames, getTypeNames, deviceNames, typeNames, typePtns } =
    useParameters();
  const { getGroups, groups } = useGroup();

  const [isEdit, setIsEdit] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newType: EntityTypeInfo = {
    TypeName: "",
    SecurityLevel: "public",
    Permissions: [],
  };
  const [selectedType, setSelectedType] = useState<EntityTypeInfo>(newType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newDevice: DeviceInfo = {
    DeviceName: "",
    SecurityLevel: "public",
    lfourIds: [],
  };

  const [selectedDevice, setSelectedDevice] = useState<DeviceInfo>(newDevice);
  const [types, setTypes] = useState<EntityTypeInfo[]>([]);
  const [devices, setDevices] = useState<DeviceInfo[]>([]);
  const didLogRef = useRef(false);

  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      let tenant_id: string = "";
      if (selectTenant) {
        tenant_id = selectTenant;
      } else {
        if (loginUser) tenant_id = loginUser.tenant;
      }
      tenantGet(tenant_id);
      getDeviceNames();
      getTypeNames();
      getGroups();
      const basePath =
        "/" + appconfig.IOT_MANAGEMENT_ENV + "/" + tenant_id + "/";

      getParameter(basePath + TenantSingleParm.DevicesBuildArn);
    }
  }, [tenantGet, getDeviceNames, getTypeNames, getGroups, getParameter]);

  useEffect(() => {
    if (tenant) {
      if (tenant.types) {
        setTypes(
          tenant.types.sort((a, b) => {
            if (a.TypeName.toLowerCase() > b.TypeName.toLowerCase()) return 1;
            else return -1;
          })
        );
      }
      if (tenant.devices) {
        setDevices(
          tenant.devices.sort((a, b) => {
            if (a.DeviceName.toLowerCase() > b.DeviceName.toLowerCase())
              return 1;
            else return -1;
          })
        );
      }
    }
  }, [tenant]);
  // TypeCardのクリック
  const onClickType = useCallback(
    (typeName: string) => {
      //      onSelectType({ typeName, types, dlType.onOpen });
      const targetType = types.find((obj) => obj.TypeName === typeName);
      if (targetType) {
        setSelectedType(targetType);
        dlType.onOpen();
      } else {
        setSelectedType(newType);
        dlType.onOpen();
      }
    },
    [types, dlType, newType]
  );
  // TypeCardのクリック
  const onClickDevice = useCallback(
    (deviceName: string) => {
      //      onSelectType({ typeName, types, dlType.onOpen });
      const targetDevice = devices.find((obj) => obj.DeviceName === deviceName);
      if (targetDevice) {
        setSelectedDevice(targetDevice);
        dlDevice.onOpen();
      } else {
        setSelectedDevice(newDevice);
        dlDevice.onOpen();
      }
    },
    [devices, dlDevice, newDevice]
  );
  const onEntityTypeInsert = (
    typeName: string,
    securityLevel: SecurityLevel_V1Type,
    permissions: PermissionInfo[],
    entityIds: string[] | undefined
  ) => {
    const typeinfo: EntityTypeInfo = {
      TypeName: typeName,
      SecurityLevel: securityLevel,
      Permissions: permissions,
    };
    if (entityIds) typeinfo.EntityIds = entityIds;

    // 既に存在するタイプの場合は追加しない
    var val = types.find(function (type) {
      return type.TypeName === typeName;
    });
    if (!val) {
      types.push(typeinfo);
      setIsEdit(true);
    }
  };
  const onEntityTypeUpdate = (
    typeName: string,
    securityLevel: SecurityLevel_V1Type,
    permissions: PermissionInfo[],
    entityIds: string[] | undefined
  ) => {
    var val = types.find(function (type) {
      return type.TypeName === typeName;
    });
    if (val) {
      val.SecurityLevel = securityLevel;
      val.Permissions = permissions;
      if (entityIds) val.EntityIds = entityIds;
      setIsEdit(true);
    }
  };
  const onEntityTypeDelete = (typeName: string) => {
    var val = types.find(function (type) {
      return type.TypeName === typeName;
    });
    if (val) {
      var index = types.indexOf(val);
      types.splice(index, 1);
      setIsEdit(true);
    }
  };
  const onDeviceInsert = (
    deviceName: string,
    securityLevel: SecurityLevelType,
    lfourIds: number[]
  ) => {
    const deviceinfo: DeviceInfo = {
      DeviceName: deviceName,
      SecurityLevel: securityLevel,
      lfourIds: lfourIds,
    };
    // 既に存在するドライバの場合は追加しない
    var val = devices.find(function (device) {
      return device.DeviceName === deviceName;
    });
    if (!val) {
      setDevices([...devices, deviceinfo]);
      setIsEdit(true);
    }
  };

  const onDeviceUpdate = (
    deviceName: string,
    securityLevel: SecurityLevelType,
    lfourIds: number[]
  ) => {
    var val = devices.find(function (device) {
      return device.DeviceName === deviceName;
    });
    if (val) {
      val.SecurityLevel = securityLevel;
      val.lfourIds = lfourIds;
      //      setDevices([...devices]);
      setIsEdit(true);
    }
  };
  const onDeviceDelete = (deviceName: string) => {
    var val = devices.find(function (device) {
      return device.DeviceName === deviceName;
    });
    if (val) {
      var index = devices.indexOf(val);
      devices.splice(index, 1);
      // setDevices([...devices.splice(index, 1)]);
      setIsEdit(true);
    }
  };

  // 編集された状態でテナント・テーブルを更新する。
  const updateTenantTable = () => {
    if (tenant) {
      tenant.types = types;
      tenant.devices = devices;
      tenantEdit(tenant);

      setIsEdit(false);
      if (parameter) {
        if (
          window.confirm(`設定反映の処理を実行しますか？（元には戻せません。）`)
        ) {
          startExecution(parameter);
        }
      }
    }
  };

  return (
    <Stack>
      <PageDiscription>
        エンティティタイプ設定やドライバ設定を更新し、「登録」ボタンを押してください。
      </PageDiscription>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <Grid>
          <HStack mx={"10"}>
            <PrimaryButton onClick={updateTenantTable} disabled={!isEdit}>
              登録
            </PrimaryButton>
            <Flex color={"red.500"}>
              {isEdit ? "登録待ちの内容があります。" : ""}
            </Flex>
          </HStack>

          <GridItem backgroundColor={"blackAlpha.200"} h="1px" m={3} />
          <GridItem>
            <PageDiscription my="0">エンティティタイプ設定</PageDiscription>
            <Wrap px={{ base: 4, md: 10 }} py={4}>
              {types.map((obj) => (
                <WrapItem key={obj.TypeName} mx="auto">
                  <TypeCard
                    key={obj.TypeName}
                    typeName={obj.TypeName}
                    SecurityLevel={obj.SecurityLevel}
                    onClick={onClickType}
                  />
                </WrapItem>
              ))}
              <WrapItem mx="auto">
                <TypeCard
                  typeName={newType.TypeName}
                  SecurityLevel={newType.SecurityLevel}
                  onClick={onClickType}
                />
              </WrapItem>
            </Wrap>
          </GridItem>
          <GridItem backgroundColor={"blackAlpha.200"} h="1px" m={3} />
          <GridItem>
            <PageDiscription my="0">ドライバ設定</PageDiscription>
            <Wrap px={{ base: 4, md: 10 }} py={4}>
              {devices.map((obj) => (
                <WrapItem key={obj.DeviceName} mx="auto">
                  <DeviceCard
                    key={obj.DeviceName}
                    deviceName={obj.DeviceName}
                    SecurityLevel={obj.SecurityLevel}
                    onClick={onClickDevice}
                  />
                </WrapItem>
              ))}
              <WrapItem mx="auto">
                <DeviceCard
                  deviceName={newDevice.DeviceName}
                  SecurityLevel={newDevice.SecurityLevel}
                  onClick={onClickDevice}
                />
              </WrapItem>
            </Wrap>
          </GridItem>
        </Grid>
      )}
      <EntityTypeDetailModal
        entityType={selectedType}
        isOpen={dlType.isOpen}
        onInsert={onEntityTypeInsert}
        onUpdate={onEntityTypeUpdate}
        onDelete={onEntityTypeDelete}
        onClose={dlType.onClose}
        tenantGroups={groups}
        typePtns={typePtns}
      />
      <DeviceDetailModal
        device={selectedDevice}
        isOpen={dlDevice.isOpen}
        onInsert={onDeviceInsert}
        onUpdate={onDeviceUpdate}
        onDelete={onDeviceDelete}
        onClose={dlDevice.onClose}
        deviceNames={deviceNames}
        tenant={tenant}
      />
    </Stack>
  );
});
