import { memo, FC } from "react";
import { Box, calc, HStack, Spacer, Stack, Text } from "@chakra-ui/react";

type Props = {
  menuTitle: string;
  description: string;
  enabled: boolean;
  onClick: () => void;
};

export const MenuCard: FC<Props> = memo((props) => {
  const { menuTitle,description, enabled, onClick } =
    props;

  return (
    <Box
      w={{base:"calc(100vw - 60px)", md:"calc(50vw - 60px)", lg:"calc(33vw - 50px)"}}
      minHeight="100px"
      bg={ enabled ? "white" : "blackAlpha.100"}
      borderRadius="10px"
      shadow="md"
      p={4}
      _hover={{ cursor: "pointer", opacity: 0.8 }}
      onClick={onClick}
    >
      <Stack textAlign="left">
        <Text fontSize="md" fontWeight="bold">
          {menuTitle}
        </Text>
        <Text fontSize="sm" color="gray">
          {description}
          </Text>
      </Stack>
    </Box>
  );
});
