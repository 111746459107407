import { useCallback, useState } from "react";
import axios from "axios";
import { Filelist, FileInfo, OpenSearchErrorFiles } from "../types/api/file";
import { useMessage } from "./useMessage";
import { useLoginUser } from "./useLoginUser";
import appconfig from "../config.json";

export const useFileUpload = () => {
    const { showMessage } = useMessage();
    const { tokenRefresh, selectTenant } = useLoginUser();
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [sourceLoading, setSourceLoading] = useState(false);
    const [doneLoading, setDoneLoading] = useState(false);

    const [sourceFiles, setSourceFiles] = useState<Array<FileInfo>>([]);
    const [doneFiles, setDoneFiles] = useState<Array<FileInfo>>([]);
    const [jsonContent, setJsonContent] = useState<any>();

    const FILE_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

    const getSourceFileList = useCallback((bucket: string, prefix: string) => {
        setSourceLoading(true);
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
            let atr: string = "";
            if (selectTenant) {
                atr = "?tenant=" + selectTenant;
            }
            axios
                .get<Filelist>(FILE_MANAGER_URL + "/s3action/list" + atr, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        bucket: `${bucket}`,
                        key: `${prefix}/source/`,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        setSourceFiles(res.data.Contents);
                    } else if (res.status === 403) {
                        showMessage({ title: "権限エラー:" + res.data, status: "error" });
                        setSourceLoading(false);
                    } else {
                        throw new Error(res.statusText);
                    }
                })
                .catch(() =>{
                    showMessage({
                        title: "ファイルリストの取得に失敗しました",
                        status: "error",
                    })
                })
                .finally(() => setSourceLoading(false));
        });
    }, []);
    const getDoneFileList = useCallback((bucket: string, prefix: string) => {
        setDoneLoading(true);
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
            let atr: string = "";
            if (selectTenant) {
                atr = "?tenant=" + selectTenant;
            }
            axios
                .get<Filelist>(FILE_MANAGER_URL + "/s3action/list" + atr, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        bucket: `${bucket}`,
                        key: `${prefix}/done/`,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        setDoneFiles(res.data.Contents);
                    } else if (res.status === 403) {
                        showMessage({ title: "権限エラー:" + res.data, status: "error" });
                        setDoneLoading(false);
                    } else {
                        throw new Error(res.statusText);
                    }
                })
                .catch(() =>{
                    showMessage({
                        title: "ファイルリストの取得に失敗しました",
                        status: "error",
                    })
                })
                .finally(() => setDoneLoading(false));
        });
    }, []);

    const clearFileList = useCallback(() => {
        setSourceFiles([]);
        setDoneFiles([]);
    }, []);    

    const putFile = useCallback((bucket: string, prefix: string, file: File) => {
        setUploading(true);

        // ファイル名をチェック
        if(!file.name.match(/^[a-zA-Z0-9-_.]+$/)){
            showMessage({
                title: "ファイル名は半角英数または記号（ハイフン、アンダーバー）にしてください。",
                status: "error",
            });
            setUploading(false);

        }else{
            new Promise((resolve) => resolve(tokenRefresh()))
            .then((token) => {
                let atr: string = "";
                if (selectTenant) {
                    atr = "?tenant=" + selectTenant;
                }
                axios
                    .put<any>(
                        FILE_MANAGER_URL + "/s3action" + atr,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                bucket: `${bucket}`,
                                key: `${prefix}/source/${file.name}`,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data) {
                            return res.data.presigned_url;
                        }else{
                            return "";
                        }
                    })
                    .then((url) => {
                        if(url === ""){
                            throw new Error("presigned_url undefined");
                        }else{
                            axios
                            .put<any>(`${url}`, file, {timeout: 0})
                            .then((res) => {
                                setUploading(false);
                                getSourceFileList(bucket,prefix);
                                getDoneFileList(bucket,prefix);
                                showMessage({ title: "ファイルをアップロードしました。", status: "success" });
                            })
                            .catch((err) => {
                                setUploading(false);
                                console.log(err);
                                showMessage({
                                    title: "ファイルアップロードに失敗しました。",
                                    status: "error",
                                });
                            })
                        }        
                    })
                    .catch((err) => {
                        setUploading(false);
                        console.log(err);
                        showMessage({
                            title: "ファイルアップロードに失敗しました。",
                            status: "error",
                        });
                        throw new Error(err);
                    })
            })
            .catch((err) => {
                setUploading(false);
                console.log(err);
                showMessage({
                    title: "ファイルアップロードに失敗しました。",
                    status: "error",
                });
            })
            .finally(()=>{
                // setUploading(false);
            })
        }

    }, []);

    const getFile = useCallback((bucket: string, key: string, opensearchError: boolean = false) => {
        setJsonContent(undefined);
        new Promise((resolve) => resolve(tokenRefresh()))
        .then((token) => {
            let atr: string = "";
            if (selectTenant) {
                atr = "?tenant=" + selectTenant;
            }
            axios
                .get<any>(
                    FILE_MANAGER_URL + "/s3action" + atr,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            bucket: `${bucket}`,
                            key: `${key}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.data) {
                        return res.data.presigned_url;
                    }else{
                        return "";
                    }
                })
                .then((url) => {
                    if(url === ""){
                        throw new Error("presigned_url undefined");
                    }else{
                        if(opensearchError){
                            // ファイルの中身の取り出し
                            fetch(url)
                            .then(res => {
                                if (!res.ok) {
                                    throw new Error(res.statusText)
                                }
                                return res.json();
                            }) 
                            .then(json => {
                                setJsonContent({status:200, statusText:"", errorFiles:json} as OpenSearchErrorFiles);
                            })
                            .catch(e => {
                                console.log(e);
                                setJsonContent({status:500, statusText:String(e)} as OpenSearchErrorFiles);
                            });
                        }else{
                            // ファイルのダウンロード
                            window.open(url, "_self");
                        }
                    }        
                })
                .catch((err) => {
                    console.log(err);
                    showMessage({
                        title: "ファイルのダウンロードに失敗しました。",
                        status: "error",
                    });
                    throw new Error(err);
                })
        })
        .catch((err) => {
            console.log(err);
            showMessage({
                title: "ファイルのダウンロードに失敗しました。",
                status: "error",
            });
        })
        .finally(()=>{
            // setUploading(false);
        })
    }, []);

    const deleteFile = useCallback((bucket: string, prefix: string, key: string) => {
        setLoading(true);
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
            let atr: string = "";
            if (selectTenant) {
                atr = "?tenant=" + selectTenant;
            }
            axios
                .delete<Filelist>(FILE_MANAGER_URL + "/s3action" + atr, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        bucket: `${bucket}`,
                        key: `${key}`,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false);
                        showMessage({ title: "ファイルを削除しました。", status: "success" });
                        getSourceFileList(bucket,prefix);
                        getDoneFileList(bucket,prefix);
                } else if (res.status === 403) {
                        setLoading(false);
                        showMessage({ title: "権限エラー:" + res.data, status: "error" });
                    } else {
                        throw new Error(res.statusText);
                    }
                })
                .catch(() =>
                    showMessage({
                        title: "ファイルの削除に失敗しました。",
                        status: "error",
                    })
                )
                .finally(() => setLoading(false));
        });
    }, []);

    return { getSourceFileList,getDoneFileList, clearFileList, getFile, putFile, deleteFile, sourceLoading,doneLoading, loading, uploading, sourceFiles,doneFiles, jsonContent };
};
