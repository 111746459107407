import { memo, useCallback, useState, useEffect, useRef } from "react";
import { useDataCatalog } from "../../../hooks/useDataCatalog";
import { Center, Spinner, Stack, Th, Tr, useDisclosure } from "@chakra-ui/react";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { TableContent } from "../../organisms/layout/TableContent";
import { TableMetadata } from "../../../types/api/tablemetadata";
import { DataCatalogTableRow } from "../../organisms/datarow/DataCatalogTableRow";
import { DatacatalogTableModal } from "../../organisms/modal/DatacatalogTableModal";
export const DatalakeTables = memo(() => {
  const dlTable = useDisclosure();
  const {
    getDataCatalogTables,
    loading,
    tables,
  } = useDataCatalog();
  const [selectedTable, setSelectedTable] =
    useState<TableMetadata>();
  const didLogRef = useRef(false);
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      getDataCatalogTables();
    }
  }, []);

  const onClickTable = useCallback(
    (name: string) => {
      const targetTable= tables.find(
        (obj) => obj.Name === name
      );
      if (targetTable) {
        setSelectedTable(targetTable);
        dlTable.onOpen();
      }
    },
    [tables]
  );
  return (
    <Stack>
      <PageDiscription>Tableを選択してください。</PageDiscription>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <TableContent
            heightOffset="50px"
            headerRows={
              <Tr key="header">
                <Th width="30%">Name</Th>
                <Th width="20%">Type</Th>
                <Th width="25%">作成日時</Th>
                <Th width="25%">最終更新日時</Th>
              </Tr>
            }
            bodyRows={tables.map((obj) => (
              <DataCatalogTableRow
                key={obj.Name}
                name={obj.Name}
                type={obj.TableType}
                createTime={obj.CreateTime}
                lastAccessTime={obj.LastAccessTime}
                onClick={onClickTable}
              />
            ))}
          ></TableContent>
        </>
      )}
          <DatacatalogTableModal
        isOpen={dlTable.isOpen}
        onClose={dlTable.onClose}
        table={selectedTable}
      />
</Stack>

  );
});
