import { memo, useEffect, useState, FC } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Wrap, WrapItem } from "@chakra-ui/react";

import { MenuCard } from "../../organisms/user/MenuCard";

import { useLoginUser } from "../../../hooks/useLoginUser";
import { HeaderLayout } from "../../templates/HeaderLayout";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { menuInfo, menuSet } from "../../../MyRouter";

export const Home: FC = memo(() => {
  const { loginUser, selectTenant } = useLoginUser();
  const [homeMenu, setHomeMenu] = useState<menuInfo[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (loginUser) {
      if (loginUser.isAccountAdmin) {
        const menus = menuSet.filter((menu) => menu.allow.accountAdmin);
        const sortMenus = menus.sort((a, b) => a.seq - b.seq);
        setHomeMenu(sortMenus);
      } else if (loginUser.isAdmin) {
        const menus = menuSet.filter((menu) => menu.allow.tenantAdmin);
        const sortMenus = menus.sort((a, b) => a.seq - b.seq);
        setHomeMenu(sortMenus);
      } else {
        const menus = menuSet.filter((menu) => menu.allow.user);
        const sortMenus = menus.sort((a, b) => a.seq - b.seq);
        setHomeMenu(sortMenus);
      }
    } else {
      setHomeMenu([]);
    }
  }, [loginUser, selectTenant]);

  return (
    <>
      <HeaderLayout>
        {loginUser && <Outlet />}
        {useLocation().pathname === "/home" ? (
          <>
            {loginUser ? (
              <>
                <PageDiscription>メニューを選択してください。</PageDiscription>
                <Wrap p={{ base: 4, md: 10 }}>
                  {homeMenu.map((menu) => (
                    <WrapItem mx="auto">
                      <MenuCard
                        menuTitle={menu.caption}
                        description={menu.description}
                        enabled={true}
                        onClick={() => navigate(`/home/${menu.path}`)}
                      />
                    </WrapItem>
                  ))}
                </Wrap>
              </>
            ) : (
              <>
                <PageDiscription>ログインしてください</PageDiscription>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </HeaderLayout>
    </>
  );
});
