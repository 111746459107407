// 名称変換用の定数
export const mailConst = {
  state: {
    enable: "有効",
    disable: "無効",
    deleted: "削除",
  },
  comparison: {
    "==": "＝",
    "<>": "≠",
    ">": "＞",
    ">=": "≧",
    "<": "＜",
    "<=": "≦",
  },
  join: {
    AND: "AND（すべての条件に一致）",
    OR: "OR（いずれかの条件に一致）",
  },
  trigger: {
    Always: "条件に該当するとき送信",
    TrueAndFalse: "該当／非該当に変更があったとき送信",
    FalseToTrue: "非該当→該当になったとき送信",
  },
};
export type MessageMetric = {
  Comment?: string;
  Namespace: string;
  MetricName: string;
  Dimensions: { Name: string; Value: string }[];
};
type ConfigItem = {
  condition1: string;
  condition2: string;
  comparison: (typeof mailConst.comparison)[keyof typeof mailConst.comparison];
};
type NotificationTemplateData = {
  subject: string;
};

type Notification = {
  method: string;
  template: string;
  templateData: NotificationTemplateData;
  sourceAddress: string;
  toAddressList: string[];
};

type Conditions = {
  items: ConfigItem[];
  join: (typeof mailConst.join)[keyof typeof mailConst.join];
  trigger: (typeof mailConst.trigger)[keyof typeof mailConst.trigger];
  interval?: string;
};

export type Config = {
  name: string;
  state: (typeof mailConst.state)[keyof typeof mailConst.state];
  updateTime: string;
  updateUser: string;
  conditions: Conditions;
  notifications: Notification;
  Metrics?: MessageMetric[];
};

export type MessageConfig = {
  id: string;
  tenant_id: string;
  type: string;
  configs: {
    [key: string]: Config; // This allows for arbitrary property names, each of which must be a Config type.
  };
};
