import { memo, FC } from "react";
import { Box, Stack, Text } from "@chakra-ui/react";

type Props = {
  id?: string|undefined;
  name: string;
  description?: string;
  onClick: (id: string|undefined) => void;
};

export const UsagePlanCard: FC<Props> = memo((props) => {
  const { id, name, description, onClick } = props;

  return (
    <Box
    w={{base:"calc(100vw - 60px)", md:"calc(50vw - 60px)", lg:"calc(33vw - 50px)", xl:"calc(25vw - 50px)"}}
    minHeight="85px"
      bg="white"
      borderRadius="10px"
      shadow="md"
      p={4}
      _hover={{ cursor: "pointer", opacity: 0.8 }}
      onClick={() => onClick(id)}
    >
      <Stack textAlign="left">
        <Text fontSize="md" fontWeight="bold">
          {name}
        </Text>
        <Text fontSize="sm" color="gray">
          {description}
        </Text>
      </Stack>
    </Box>
  );
});
