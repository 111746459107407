import { memo, FC } from "react";
import { Link, Td, Textarea, Tr } from "@chakra-ui/react";
import { EditIcon, EmailIcon, ExternalLinkIcon } from "@chakra-ui/icons";

type Props = {
  name: string;
  type: string;
  createTime: number;
  lastAccessTime: number;
  onClick: (id: string) => void;
};

export const DataCatalogTableRow: FC<Props> = memo((props) => {
  const {
    name,
    type,
    createTime,
    lastAccessTime,
    onClick,
  } = props;

  return (
    <Tr key={name} bg="gray.50">
      <Td>
        <Link onClick={() => onClick(name)} isExternal>
          {name}
          <EditIcon mx="2" color={"gray"} fontSize="sm" />
        </Link>
      </Td>
      <Td>{type}</Td>
      <Td>{new Date(createTime * 1000).toLocaleString()}</Td>
      <Td>{new Date(lastAccessTime * 1000).toLocaleString()}</Td>
      {/* <Td>
        <Box
          border="1px"
          p="2"
          borderColor={"gray.300"}
          dangerouslySetInnerHTML={{ __html: htmlPart }}
        ></Box>
      </Td> */}
    </Tr>
  );
});
