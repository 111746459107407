import { useCallback, useState } from "react";
import axios from "axios";

import {
  BasePathMapping,
  RestApi,
  DomainName,
  openApi,
} from "../types/api/apiGateway";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useDomainApi = (onClose: () => void) => {
  const { showMessage } = useMessage();
  const { tokenRefresh, selectTenant } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [domainApis, setDomainApis] = useState<Array<DomainName>>([]);
  const [openApi, setOpenApi] = useState<openApi | undefined>(undefined);

  const API_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getDomainApis = useCallback((tenantSelect: boolean) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      if (!tenantSelect) {
        atr = "?tenant=none";
      } else if (selectTenant) {
        atr = "?tenant=" + selectTenant;
      }
      axios
        .get<Array<DomainName>>(API_MANAGER_URL + "/domainapis" + atr, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) =>
          setDomainApis(
            res.data.sort((a, b) => {
              if (a.domainName.toLowerCase() > b.domainName.toLowerCase())
                return 1;
              else return -1;
            })
          )
        )
        .catch(() =>
          showMessage({ title: "Apiの取得に失敗しました", status: "error" })
        )
        .finally(() => setLoading(false));
    });
  }, []);
  const getOpenApi = useCallback((domain_name: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      let atr: string = "";
      axios
        .get<BasePathMapping & RestApi>(
          API_MANAGER_URL + "/domainapi/" + domain_name,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) =>
          setOpenApi({
            domainName: domain_name,
            basePath: res.data.basePath,
            restApiId: res.data.restApiId,
            stage: res.data.stage,
            restApiname: res.data.name,
            description: res.data.description,
            createdDate: res.data.createdDate,
            version: res.data.version,
          })
        )
        .catch(() =>
          showMessage({ title: "Apiの取得に失敗しました", status: "error" })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  return { getDomainApis, getOpenApi, loading, domainApis, openApi };
};
