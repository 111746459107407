import { ReactNode, FC } from "react";
import { Button, Flex } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

type Props = {
  onClick: () => void;
  loading?: boolean;
  text?: string;
  disabled?: boolean;
};

export const AddButton: FC<Props> = (props) => {
  const { onClick, loading = false, text = "新規登録", disabled=false } = props;

  return (
    <Flex
      px={{ base: 4, md: 10 }}
      py={{ base: 1, md: 2 }}
      mt={{ base: 1, md: 2 }}
    >
      <Button
        isLoading={loading}
        leftIcon={<AddIcon />}
        colorScheme="teal"
        variant="solid"
        onClick={onClick}
        size={{ base: "sm", md: "md" }}
        disabled={disabled}
      >
        {text}
      </Button>
    </Flex>
  );
};
