/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from "react";
import axios, { Axios, AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { useLoginUser } from "../hooks/useLoginUser";
import { useMessage } from "./useMessage";
import appconfig from '../config.json'
import { TenantInfo } from "../types/api/tenantTableTypes";
import { PermissionPtn, TierType } from "../types/api/commonTypes";

export const useRegister = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { loginUser,tokenRefresh } = useLoginUser();

  const TENANT_REGISTRATION_URL: string =appconfig["IOT_MANAGEMENT_API_URL"];

  type IPostResponse = {
    access?: string;
    token?: string;
    newPasswordRequired?: string;
  };

  const reg = useCallback(
    (
      tenant_id: string,
      accountName: string,
      companyName: string,
      email: string,
      tenantNamespace: string,
      tier:TierType,
      binaryStorageCapacityLimit: number,
    ) => {
      setLoading(true);

      const tenant:TenantInfo = {
        tenant_id,
        accountName,
        companyName,
        status:"",
        email,
        tenantNamespace,
        tier,
        binaryStorageCapacityLimit,
      };
      console.log(tenant)

      /// /reg(POST)[tenant-registration]
      ///    ->/user/pool/:id(GET)[user-manager] >DynamoDB(user_id = :id)
      ///    ->/user/reg(POST)[user-manager]
      ///    ->/tenant(POST)
      new Promise((resolve)=>resolve(tokenRefresh()))
      .then((token)=>{
        axios
          .post<IPostResponse>(TENANT_REGISTRATION_URL + "/reg", tenant, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(async (res) => {
            if (res.status == 200) {
              showMessage({
                title:
                  "アカウントが登録されました。 ログイン手順が記載されたメールが送信されます。",
                status: "success"
              });
              navigate("/login");
            } else if (res.status == 400) {
              showMessage({ title: "設定値エラー:", status: "error" });
              setLoading(false);
            } else if (res.status == 403) {
              showMessage({ title: "権限エラー:" + res.data, status: "error" });
              setLoading(false);
            } else {
              showMessage({ title: res.statusText, status: "error" });
              setLoading(false);
            }
          })
          .catch((err:AxiosError) => {
            if (err.request) {
              if (err.request.status == 400) {
                showMessage({ title: "設定値エラー:" + err.request.responseText , status: "error" });
                setLoading(false);
              } else if (err.request.status == 403) {
                showMessage({ title: "権限エラー:" + err.request.responseText, status: "error" });
                setLoading(false);
              } else {
                showMessage({ title: err.request.statusText, status: "error" });
                setLoading(false);
              }
            }else{
              showMessage({
                title: "新しいテナントを作成できません",
                status: "error"
              });
              setLoading(false);
            }
          });
      });
      },
    []
  );

  return { reg, loading };
};
