import { memo, useCallback, useEffect, FC } from "react";
import {
  Center,
  Spinner,
  useDisclosure,
  Wrap,
  WrapItem
} from "@chakra-ui/react";

import { TenantCard } from "../../organisms/tenant/TenantCard";
import { useAllTenants } from "../../../hooks/useAllTenants";
import { TenantDetailModal } from "../../organisms/modal/TenantDetailModal";
import { useSelectTenant } from "../../../hooks/useSelectTenant";
import { useLoginUser } from "../../../hooks/useLoginUser";

export const Tenants: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getTenants, loading, tenants } = useAllTenants();
  const { onSelectTenant, selectedTenant } = useSelectTenant();
  const { loginUser } = useLoginUser();

  useEffect(() => getTenants(), [getTenants]);

  const onClickUser = useCallback(
    (tenantId: string) => {
      onSelectTenant({ tenant_id:tenantId, tenants, onOpen });
    },
    [tenants, onSelectTenant, onOpen]
  );

  return (
    <>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <Wrap p={{ base: 4, md: 10 }}>
          {tenants.map((obj) => (
            <WrapItem key={obj.tenant_id} mx="auto">
              <TenantCard
                tenant_id={obj.tenant_id}
                accountName={obj.accountName}
                onClick={onClickUser}
              />
            </WrapItem>
          ))}
        </Wrap>
      )}
      <TenantDetailModal
        isOpen={isOpen}
        isAdmin={loginUser?.isAccountAdmin}
        onClose={onClose}
        tenant={selectedTenant}
      />
    </>
  );
});
