import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";

import theme from "./theme/theme";
import { LoginUserProvider } from "./providers/LoginUserProvider";
import { MyRouter } from "./MyRouter";

function App() {
  
  return (
    <ChakraProvider theme={theme}>
      <LoginUserProvider>
        <MyRouter/>
      </LoginUserProvider>
    </ChakraProvider>
  );
};

export default App;
