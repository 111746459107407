import { memo, FC } from "react";
import {
  Box,
  HStack,
  Td,
  Tr,
} from "@chakra-ui/react";
import { ExecutionStatusType } from "../../../types/api/stepFunctions";

type Props = {
  ExecutionArn: string;
  Name: string;
  StartDate: string;
  Status: ExecutionStatusType;
  StopDate: string;
};

export const SfnExecutionRow: FC<Props> = memo((props) => {
  const { ExecutionArn, Name, StartDate, Status, StopDate } = props;
  return (
    <Tr key={ExecutionArn} bg="gray.50" height={"50px"}>
      <Td fontSize={"sm"}>{StartDate.substring(0,19)}</Td>
      <Td fontSize={"sm"}>
        {StopDate ? StopDate.substring(0,19): ""}
      </Td>
      <Td fontSize={"sm"}>
        <HStack>
        {Status === ExecutionStatusType.FAILED ? (
          <Box color={"red"}>{Status}</Box>
          ) : (
            <Box>{Status}</Box>
            )}
        </HStack>
      </Td>
      <Td fontSize={"sm"}>
        <HStack>
          <Box>{Name}</Box>
        </HStack>
      </Td>
    </Tr>
  );
});
