import { memo, FC } from "react";
import { Box, Flex, Link, Stack, Td, Text, Tr } from "@chakra-ui/react";
import { CheckCircleIcon, EditIcon, NotAllowedIcon } from "@chakra-ui/icons";

type Props = {
  identityName: string;
  tenant: string;
  sendingEnabled: boolean;
  onClick: (identityName: string) => void;
};

export const EmailIdentitieRow: FC<Props> = memo((props) => {
  const { identityName, tenant, sendingEnabled, onClick } = props;

  return (
    <Tr key={identityName} bg={sendingEnabled ? "gray.50" : "gray.200"}>
      <Td>
        <Link onClick={() => onClick(identityName)} isExternal>
          {identityName}
          <EditIcon mx="2" color={"gray"} fontSize="sm" />
        </Link>
      </Td>
      <Td>{tenant}</Td>
      <Td>
        {sendingEnabled ? (
          <Flex align={"center"}>
            <CheckCircleIcon color={"green.400"} mr="1" />
            検証済
          </Flex>
        ) : (
          <Flex align={"center"}>
            <NotAllowedIcon color={"red.500"} mr="1" />
            未検証
          </Flex>
        )}
      </Td>
    </Tr>
  );
});
