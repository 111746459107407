import { useCallback, useState } from "react";
import axios from "axios";
import { OrionRegistration } from "../types/api/orionRegistration";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useOrionRegistrations = () => {
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [orionRegistrations, setOrionRegistrations] = useState<Array<OrionRegistration>>([]);

  const ORION_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getOrionRegistrations = useCallback(() => {
    setLoading(true);

    let atr: string = "";
    if (selectTenant != "999999") {
      atr = "?limit=1000&tenant=" + selectTenant;
    }
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<Array<OrionRegistration>>(
          ORION_MANAGER_URL + "/registrations" + atr,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            setOrionRegistrations(res.data);
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(() =>
          showMessage({
            title: "テンプレートの取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  return {
    getOrionRegistrations,
    loading,
    orionRegistrations,
  };
};
