import { memo, useCallback, useState, useEffect, useRef } from "react";
import { useOrionRegistrations } from "../../../hooks/useOrionRegistrations";
import { OrionRegistrationModal } from "../../organisms/modal/OrionRegistrationModal";
import { Center, Spinner, Stack, Th, Tr, useDisclosure } from "@chakra-ui/react";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { OrionRegistrationRow } from "../../organisms/datarow/OrionRegistrationRow";
import { TableContent } from "../../organisms/layout/TableContent";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { OrionRegistration } from "../../../types/api/orionRegistration";
export const OrionRegistrations = memo(() => {
  const dlRegistration = useDisclosure();
  const {
    getOrionRegistrations,
    loading,
    orionRegistrations,
  } = useOrionRegistrations();
  const [selectedRegistration, setSelectedRegistration] =
    useState<OrionRegistration>();
  const didLogRef = useRef(false);
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      getOrionRegistrations();
    }
  }, []);

  const onClickRegistration = useCallback(
    (id: string) => {
      const targetRegistration= orionRegistrations.find(
        (obj) => obj.id === id
      );
      if (targetRegistration) {
        setSelectedRegistration(targetRegistration);
        dlRegistration.onOpen();
      }
    },
    [orionRegistrations]
  );
  return (
    <Stack>
      <PageDiscription>Registrationを選択してください。</PageDiscription>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <TableContent
            heightOffset="50px"
            headerRows={
              <Tr key="header">
                <Th width="30%">id</Th>
                <Th width="50%">description</Th>
                <Th width="20%">status</Th>
              </Tr>
            }
            bodyRows={orionRegistrations.map((obj) => (
              <OrionRegistrationRow
                key={obj.id}
                id={obj.id}
                description={obj.description}
                expires={obj.expires}
                status={obj.status}
                onClick={onClickRegistration}
              />
            ))}
          ></TableContent>
        </>
      )}
          <OrionRegistrationModal
        isOpen={dlRegistration.isOpen}
        onClose={dlRegistration.onClose}
        Registration={selectedRegistration}
      />
</Stack>

  );
});
