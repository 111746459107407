import { ReactNode, FC } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";

interface Props extends FlexProps {
  children: ReactNode;
}

export const PageDiscription = ({ children, ...rest }: Props) => {
  return (
    <Flex mx="5" my="3" {...rest}>
      {children}
    </Flex>
  );
};
