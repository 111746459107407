import { ChangeEvent, memo, useEffect, useState, FC, useRef } from "react";
import {
  FormControl,
  FormLabel,
  HStack,
  Select,
  Spinner,
  Stack,
} from "@chakra-ui/react";

import { useDataLakeBucket } from "../../../hooks/useDataLakeBucket";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { useParameters } from "../../../hooks/useParameters";
import appconfig from "../../../config.json";
import { ApexOptions } from "apexcharts";
import { DatapointsDrawer } from "../../molecules/DatapointsDrawer";
import { TenantSingleParm } from "../../../types/api/parameterTypes";

type BucketInfo = {
  type: string;
  bucketName: string;
};
export const DatalakeBuckets: FC = memo(() => {
  const {
    getParameter: getPublicBucket,
    parameter: publicBucket,
    loading: publicLoading,
  } = useParameters();
  const {
    getParameter: getPrivateBucket,
    parameter: privateBucket,
    loading: privateLoading,
  } = useParameters();
  const {
    getParameter: getPersonalBucket,
    parameter: personalBucket,
    loading: personaLoading,
  } = useParameters();
  const {
    getParameter: getBinaryBucket,
    parameter: binaryBucket,
    loading: binaryLoading,
  } = useParameters();
  const { loginUser, selectTenant } = useLoginUser();

  const {
    getBucketsizeBytes,
    loading: loadingDatapoints,
    datapoints,
  } = useDataLakeBucket();

  type StatisticType = "Average" | "Sum" | "Minimum" | "Maximum";
  type MetricPtn =
    | "BucketSizeBytes"
    | "NumberOfObjects"
    | "AllRequests"
    | "GetRequests"
    | "PutRequests"
    | "DeleteRequests"
    | "HeadRequests"
    | "PostRequests";
  const [statistics, setStatistics] = useState<StatisticType[]>(["Average"]);
  const [metric, setMetric] = useState<MetricPtn>("BucketSizeBytes");
  const [series, setSeries] = useState<ApexAxisChartSeries>([
    { name: "", data: [{ x: "yyyy-mm-dd", y: 0 }] },
  ]);
  const [selectBucket, setSelectBucket] = useState<BucketInfo | undefined>(
    undefined
  );
  const [buckets, setBuckets] = useState<Array<BucketInfo>>([]);
  const [loading, setLoading] = useState(false);

  const didLogRef = useRef(false);
  const [periodset, setPeriodset] = useState<"5minits" | "hour" | "day">("day");
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      const tenant_id = selectTenant || loginUser?.tenant || "";

      setBuckets([]);
      const basePath =
        "/" + appconfig.IOT_MANAGEMENT_ENV + "/" + tenant_id + "/";

      getPrivateBucket(basePath + TenantSingleParm.PrivateDataLake);
      getPublicBucket(basePath + TenantSingleParm.PublicDataLake);
      getPersonalBucket(basePath + TenantSingleParm.AnonymizedPersonalDataLake);
      getBinaryBucket(basePath + TenantSingleParm.BinaryDataLake);
    }
  }, [selectTenant, loginUser]);

  // Bucket の選択　または、メトリクスの変更時に
  useEffect(() => {
    if (selectBucket) {
      let temps: StatisticType[] = ["Sum"];
      if (metric == "BucketSizeBytes") temps = ["Average"];
      else if (metric == "NumberOfObjects") temps = ["Average"];
      else if (metric == "AllRequests") temps = ["Sum"];

      const endDate = new Date();
      const startDate = new Date();
      let period: number = 300;
      if (periodset == "5minits") period = 300;
      else if (periodset == "hour") period = 60 * 60;
      else if (periodset == "day") period = 60 * 60 * 24;
      startDate.setDate(endDate.getDate() - (period * 1440) / (60 * 60 * 24));
      setStatistics(temps);
      getBucketsizeBytes(
        selectBucket.bucketName,
        metric,
        temps,
        startDate.toISOString(),
        endDate.toISOString(),
        period
      );
    }
  }, [selectBucket, metric, periodset]);
  const onChangeBucket = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectBucket = buckets?.find(
      (bucket) => bucket.type === e.target.value
    );
    setSelectBucket(selectBucket);
  };

  useEffect(() => {
    if (publicBucket) {
      setBuckets((prevBuckets) =>
        prevBuckets.concat([{ type: "public", bucketName: publicBucket }]).sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        })
      );
    }
  }, [publicBucket]);
  useEffect(() => {
    if (privateBucket) {
      setBuckets((prevBuckets) =>
        prevBuckets.concat([{ type: "private", bucketName: privateBucket }]).sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        })
      );
    }
  }, [privateBucket]);
  useEffect(() => {
    if (personalBucket && !(personalBucket === "non")) {
      setBuckets((prevBuckets) =>
        prevBuckets.concat([{ type: "匿名加工情報", bucketName: personalBucket }]).sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        })
      );
    }
  }, [personalBucket]);
  useEffect(() => {
    if (binaryBucket && !(binaryBucket === "non")) {
      setBuckets((prevBuckets) =>
        prevBuckets.concat([{ type: "binary", bucketName: binaryBucket }]).sort((a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        })
      );
    }
  }, [binaryBucket]);
  // データ取得後に、グラフ用のデータを編集
  useEffect(() => {
    if (datapoints) {
      setSeries(
        statistics.map((statistic) => {
          const data: { x: string; y: number }[] = datapoints.map(
            (datapoint) => {
              const point = {
                x: datapoint.Timestamp,
                y: datapoint[statistic] || 0,
              };
              if (statistic in datapoint) {
                if (datapoint.Unit == "Bytes") {
                  point.y =
                    Math.ceil(
                      ((datapoint[statistic] || 0) * 10) / (1024 * 1024)
                    ) / 10;
                } else {
                  point.y = datapoint[statistic] || 0;
                }
              } else {
                point.y = 0;
              }

              return point;
            }
          );
          return { name: statistic, data: data };
        })
      );
    }
  }, [datapoints]);
  useEffect(() => {
    if (publicLoading || privateLoading || personaLoading || binaryLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [publicLoading, privateLoading, personaLoading, binaryLoading]);

  const options: ApexOptions = {
    chart: {
      id: "chart",
      toolbar: {
        export: {
          csv: {
            columnDelimiter: ',',
            headerCategory: 'datetime',
            dateFormatter(timestamp: number) {
              return new Date(timestamp).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
            }
          },
        },
      },
      animations:{
        enabled: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false, // 表示をJSTにする
        datetimeFormatter: {
          year: 'yyyy年',
          month: 'M月',
          day: 'M/d',
          hour: 'H:mm',
          minute: 'H:mm',
        },
        rotate: -15,
        rotateAlways: true,
      },
    },
    yaxis:{
      min: 0,
    },
    tooltip: {
      x: { format: 'yyyy/M/d H:mm' },
    },
  };

  return (
    <Stack maxW={"1200px"}>
      <PageDiscription>ストレージを選択してください。</PageDiscription>
      <FormControl>
        <HStack>
          <FormLabel whiteSpace={"nowrap"}>ストレージ</FormLabel>
          {loading ? (
            <Spinner key="deviceSpinner" color="teal.500" />
          ) : (
            <Select onChange={onChangeBucket} bg="gray.50" disabled={loading}>
              <option value={""}></option>
              {buckets?.map((bucket) => (
                <option key={bucket.type} value={bucket.type}>
                  {bucket.type}
                </option>
              ))}
            </Select>
          )}
        </HStack>
      </FormControl>
      <DatapointsDrawer
        loadingDatapoints={loadingDatapoints}
        datapoints={datapoints}
        options={options}
        series={series}
        statistics={statistics}
      ></DatapointsDrawer>
    </Stack>
  );
});
