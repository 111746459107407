import { memo, FC } from "react";
import { Box, Flex, Link, Stack, Td, Text, Tr } from "@chakra-ui/react";
import { CheckCircleIcon, EditIcon, NotAllowedIcon } from "@chakra-ui/icons";
import { mailConst } from "../../../types/api/messageConfig";

type Props = {
  configId: string;
  configName: string;
  subject: string;
  interval: string;
  toAddressNum: number;
  metricsNum: number;
  state: string;
  onClick: (identityName: string) => void;
};

export const MessageConfigRow: FC<Props> = memo((props) => {
  const { configId, configName,subject, interval, toAddressNum,metricsNum, state, onClick } = props;

  return (
    <Tr key={configId}  bg={state === "enable" ? "gray.50" : "gray.200"}>
      <Td>
        <Link onClick={() => onClick(configId)} isExternal>
          {configId}
          <EditIcon mx="2" color={"gray"} fontSize="sm" />
        </Link>
      </Td>
      <Td>{configName}</Td>
      <Td>{subject}</Td>
      <Td textAlign="right">{interval}</Td>
      <Td textAlign="right">{toAddressNum}</Td>
      <Td textAlign="right">{metricsNum}</Td>
      <Td>
        {state === "enable" && (
          <Flex align={"center"}>
            <CheckCircleIcon color={"green.400"} mr="1" />
            {mailConst.state.enable}{" "}
          </Flex>
        )}
        {state === "disable" && (
          <Flex align={"center"}>
            <NotAllowedIcon color={"red.500"} mr="1" />
            {mailConst.state.disable}
          </Flex>
        )}
      </Td>
    </Tr>
  );
});
