import { memo, useEffect, useState, FC } from "react";
import {
    Box,
    FormLabel,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    VStack,
} from "@chakra-ui/react";
import { OpenSearchErrorFiles } from "../../../types/api/file";

type Props = {
    ErrorFiles: OpenSearchErrorFiles | undefined;
    isOpen: boolean;
    onClose: () => void;
};

export const OpensearchErrorModal: FC<Props> = memo((props) => {
    const { isOpen, onClose, ErrorFiles } = props;

    useEffect(() => {
        onModalReset();
    }, [ErrorFiles]);

    const onModalReset = () => {
        if (ErrorFiles) {
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onModalReset();
                onClose();
            }}
            motionPreset="slideInBottom"
            autoFocus={false}
            size={"4xl"}
        >
            <ModalOverlay />
            <ModalContent pb={2}>
                <ModalHeader>CSV取込チェック結果</ModalHeader>
                <ModalCloseButton />
                <ModalBody mx={{ base: 0, md: 6 }}>
                    {ErrorFiles &&
                        (ErrorFiles["status"] !== 200 ? (
                            <Box key="error">{JSON.stringify(ErrorFiles)}</Box>
                        ) : (
                            <Stack>
                                {ErrorFiles.errorFiles
                                    ?.filter((file) => {
                                        return file.errorCount > 0;
                                    })
                                    .map((file) => (
                                        <Stack key={file.filename}>
                                            <HStack>
                                                <Box key={"head-" + file.filename}>{file.filename}</Box>
                                                <Spacer/>
                                                <Box color={"red.500"} key={"head-count-" + file.filename}>{"エラー：" + file.errorCount + "件"}</Box>
                                            </HStack>
                                            <TableContainer key={"table-" + file.filename}>
                                                <Table size={"sm"}>
                                                    <Thead>
                                                        <Tr>
                                                            <Th>エラー行</Th>
                                                            {/* <Th>エラーコード</Th> */}
                                                            <Th>エラー内容</Th>
                                                            <Th w="100%"></Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {file.errors?.map((err, i) => (
                                                            <Tr key={i}>
                                                                <Td textAlign={"right"}>{String(err.row)+" 行目"}</Td>
                                                                {/* <Td>{err.error.errorCode}</Td> */}
                                                                <Td>{err.value}</Td>
                                                                <Td>{err.error.errorMessage}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                                {(file.errorCount != file.errors?.length) && (
                                                    <FormLabel>{`※${file.errors?.length}件まで表示`}</FormLabel>
                                                )}
                                            </TableContainer>
                                        </Stack>
                                    ))}
                            </Stack>
                        ))}
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
});
