import { memo, useEffect, useState, FC } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { OrionSubscription } from "../../../types/api/orionSubscription";

type Props = {
  Subscription: OrionSubscription | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const OrionSubscriptionModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, Subscription } = props;

  useEffect(() => {
    onModalReset();
  }, [Subscription]);

  const onModalReset = () => {
    if (Subscription) {
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>Subscription状況</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Textarea
            value={JSON.stringify(Subscription, null, 2)}
            readOnly={true}
            rows={20}
            border="1px"
            borderColor={"gray.400"}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
});
