import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  HStack,
  Tabs,
  TabPanels,
  TabPanel,
  Spacer,
  Badge,
  Flex,
} from "@chakra-ui/react";

import { EmailIdentitie } from "../../../types/api/emailIdentitie";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { CheckCircleIcon, NotAllowedIcon } from "@chakra-ui/icons";

type Props = {
  EmailIdentitie: EmailIdentitie;
  isOpen: boolean;
  onInsert: (IdentitieName: string, Tenant: string) => void;
  onDelete: (IdentitieName: string) => void;
  onClose: () => void;
};

export const IdentitieDetailModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, EmailIdentitie } = props;
  const isAddMode: boolean = EmailIdentitie.IdentityName ? false : true;

  const [IdentitieName, setIdentitieName] = useState("");
  const [Tenant, setTenant] = useState("");

  useEffect(() => {
    onModalReset();
  }, [EmailIdentitie]);

  const onChangeIdentitieName = (e: ChangeEvent<HTMLInputElement>) =>
    setIdentitieName(e.target.value);

  const onClickCreate = () => {
    props.onInsert(IdentitieName, Tenant);
    props.onClose();
  };

  const onClickDelete = () => {
    if (window.confirm(`「${IdentitieName}」を削除しますか？`)) {
      props.onDelete(IdentitieName);
      props.onClose();
    }
  };

  const onModalReset = () => {
    if (EmailIdentitie) {
      setIdentitieName(EmailIdentitie.IdentityName ?? "");
      setTenant(EmailIdentitie.Tenant ?? "");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>送信元アドレス設定</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Tabs>
            <TabPanels>
              <TabPanel>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>
                      メールアドレス
                      {isAddMode ? (
                        <Badge colorScheme="red" ml={2}>
                          必須
                        </Badge>
                      ) : (
                        <Badge colorScheme="gray" ml={2}>
                          変更不可
                        </Badge>
                      )}
                    </FormLabel>
                    <Input
                      type="email"
                      readOnly={!isAddMode}
                      value={IdentitieName}
                      placeholder="xxxxx@aaaa.co.jp"
                      onChange={onChangeIdentitieName}
                    />
                  </FormControl>
                  <FormControl display={isAddMode ? "none" : ""}>
                    <FormLabel>検証状態</FormLabel>
                    <Flex border="1px" p="2" borderColor={"gray.200"}>
                      {EmailIdentitie.SendingEnabled ? (
                        <Flex align={"center"}>
                          <CheckCircleIcon color={"green.400"} mr="1" />
                          検証済
                        </Flex>
                      ) : (
                        <Flex align={"center"}>
                          <NotAllowedIcon color={"red.500"} mr="1" />
                          未検証
                        </Flex>
                      )}
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <FormLabel>検証タイプ</FormLabel>
                    <Flex border="1px" p="2" borderColor={"gray.200"}>
                      {EmailIdentitie.IdentityType}
                    </Flex>
                  </FormControl>
                </Stack>
                <HStack mt={4}>
                  <Spacer />
                  {isAddMode ? (
                    <PrimaryButton
                      onClick={onClickCreate}
                      disabled={IdentitieName === ""}
                    >
                      登録
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton onClick={onClickDelete}>削除</PrimaryButton>
                  )}
                </HStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
});
