import { FC, memo, useMemo, useState } from "react";
import { Center, Flex, FormControl, HStack, Radio, RadioGroup, Spinner, Stack, Th, Tr } from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { TableContent } from "../organisms/layout/TableContent";
import { DatapointRow } from "../organisms/datarow/DatapointRow";
import { Datapoint } from "../../types/api/metricDataResult";
import { ApexOptions } from "apexcharts";
type Props = {
  loadingDatapoints: boolean;
  datapoints: Array<Datapoint>;
  options: ApexOptions;
  series: ApexAxisChartSeries;
  statistics: string[];
};

export const DatapointsDrawer: FC<Props> = memo((props) => {
  const { loadingDatapoints, datapoints, options, series, statistics } = props;
  const [viewtype, setViewtype] = useState<
    | "area"
    | "line"
    | "bar"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "candlestick"
    | "boxPlot"
    | "radar"
    | "polarArea"
    | "rangeBar"
    | "rangeArea"
    | "treemap"
    | undefined
  >(undefined);

  const onChangViewType = (nextValue: string | undefined) => {
    if (nextValue) {
      if (nextValue === "line") setViewtype("line");
      else if (nextValue === "table") setViewtype(undefined);
    } else setViewtype(undefined);
  };

  return (
    <>
      <FormControl>
        <RadioGroup onChange={onChangViewType} value={viewtype || "table"}>
          <HStack flexWrap="wrap" spacing="5">
            <Radio value="table">一覧</Radio>
            <Radio value="line">グラフ</Radio>
          </HStack>
        </RadioGroup>
      </FormControl>
      {loadingDatapoints ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : viewtype ? (
        <Stack bg="white" width={"full"} p="5" className="mixed-chart" h={"50VH"}>
          <Chart options={options} series={series} type={viewtype} height={"100%"} />
        </Stack>
      ) : (
        <TableContent
          heightOffset="50px"
          headerRows={
            <Tr key="header">
              <Th width="30%">datetime</Th>
              {statistics.map((statistic) => (
                <Th width="50%">{statistic}</Th>
              ))}
              <Th width="20%">units</Th>
            </Tr>
          }
          bodyRows={datapoints.map((obj) => (
            <DatapointRow
              key={obj.Timestamp}
              timestamp={obj.Timestamp}
              sum={statistics.includes("Sum") ? obj.Sum : undefined}
              average={statistics.includes("Average") ? obj.Average : undefined}
              maximum={statistics.includes("Maximum") ? obj.Maximum : undefined}
              minimum={statistics.includes("Minimum") ? obj.Minimum : undefined}
              unit={obj.Unit}
            />
          ))}
        ></TableContent>
      )}
    </>
  );
});
