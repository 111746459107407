import { useCallback, useState } from "react";
import axios from "axios";
import { MailTemplate } from "../types/api/mailTemplate";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useMailTemplate = () => {
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [mailTemplates, setMailTemplates] = useState<Array<MailTemplate>>([]);

  const MAIL_TEMPLATE_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const mailTemplateAdd = useCallback(
    (
      TemplateName: string,
      SubjectPart: string,
      TextPart: string,
      HtmlPart: string
    ) => {
      setLoading(true);
      var mailTemplate = {
        TemplateName: TemplateName,
        SubjectPart: SubjectPart,
        TextPart: TextPart,
        HtmlPart: HtmlPart,
      };
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .post<any>(MAIL_TEMPLATE_MANAGER_URL + "/template", mailTemplate, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (res) => {
            if (res.status === 200) {
              console.log(res.data);
              showMessage({ title: "追加しました。", status: "success" });
              setLoading(false);
            } else if (res.status === 403) {
              showMessage({ title: "権限エラー:" + res.data, status: "error" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .then(() => {
            getMailTemplates();
          })
          .catch((msg) => {
            showMessage({
              title: `テンプレートが作成できません。(${msg})`,
              status: "error",
            });
            setLoading(false);
          });
      });
    },
    []
  );

  const mailTemplateEdit = useCallback(
    (
      TemplateName: string,
      SubjectPart: string,
      TextPart: string,
      HtmlPart: string
    ) => {
      setLoading(true);
      var mailTemplate = {
        SubjectPart: SubjectPart,
        TextPart: TextPart,
        HtmlPart: HtmlPart,
      };
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .put<any>(
            MAIL_TEMPLATE_MANAGER_URL + "/template/" + TemplateName,
            mailTemplate,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(async (res) => {
            if (res.status === 200) {
              showMessage({ title: "更新しました。", status: "success" });
              setLoading(false);
            } else if (res.status === 403) {
              showMessage({ title: "権限エラー:" + res.data, status: "error" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .then(() => {
            getMailTemplates();
          })
          .catch((msg) => {
            showMessage({
              title: `テンプレートが更新できません。(${msg})`,
              status: "error",
            });
            setLoading(false);
          });
      });
    },
    []
  );

  const mailTemplateDelete = useCallback((TemplateName: string) => {
    setLoading(true);
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .delete<any>(MAIL_TEMPLATE_MANAGER_URL + "/template/" + TemplateName, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          if (res.status === 204) {
            console.log(res.data);
            showMessage({ title: "削除しました。", status: "success" });
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .then(() => {
          getMailTemplates();
        })
        .catch((msg) => {
          showMessage({
            title: `テンプレートが削除できません(${msg})`,
            status: "error",
          });
          setLoading(false);
        });
    });
  }, []);

  const getMailTemplates = useCallback(() => {
    setLoading(true);

    let atr: string = "";
    if (selectTenant != "999999") {
      atr = "?tenant=" + selectTenant;
    }
    new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
      axios
        .get<Array<MailTemplate>>(
          MAIL_TEMPLATE_MANAGER_URL + "/templates" + atr,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            setMailTemplates(
              res.data.sort((a, b) => {
                if (a.TemplateName.toLowerCase() > b.TemplateName.toLowerCase())
                  return 1;
                else return -1;
              })
            );
            setLoading(false);
          } else if (res.status === 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(() =>
          showMessage({
            title: "テンプレートの取得に失敗しました",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    });
  }, []);

  const sendTemplateMail = useCallback(
    (
      FromEmailAddress: string,
      ToEmailAddress: string,
      TemplateName: string,
      TemplateData: { [name: string]: string }
    ) => {
      setLoading(true);
      var testMailParam = {
        sourceAddress: FromEmailAddress,
        // "template": TemplateName,
        toAddresses: ToEmailAddress,
        data: TemplateData,
      };

      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .post<any>(
            MAIL_TEMPLATE_MANAGER_URL +
              "/template/" +
              TemplateName +
              "/test_send",
            testMailParam,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(async (res) => {
            console.log(res);
            if (res.status === 200) {
              console.log(res.data);
              showMessage({ title: "送信しました。", status: "success" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .catch((msg) => {
            console.log(msg);
            if (msg.response.status === 400) {
              showMessage({
                title: "送信できません。:" + msg.response.data,
                status: "error",
              });
              setLoading(false);
            } else {
              showMessage({
                title: `送信できません。(${msg})`,
                status: "error",
              });
              setLoading(false);
            }
          });
      });
    },
    []
  );

  return {
    getMailTemplates,
    mailTemplateAdd,
    mailTemplateEdit,
    mailTemplateDelete,
    sendTemplateMail,
    loading,
    mailTemplates,
  };
};
