import { memo, FC } from "react";
import {
  Badge,
  Box,
  Flex,
  HStack,
  Link,
  Spacer,
  Stack,
  TableRowProps,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { EditIcon, CheckCircleIcon, NotAllowedIcon } from "@chakra-ui/icons";

type Props = {
  userName: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  confirmedStatus: string;
  role: string;
  onClick: (userName: string) => void;
};

export const UserRow: FC<Props> = memo((props) => {
  const {
    userName,
    firstName,
    lastName,
    enabled,
    confirmedStatus,
    role,
    onClick,
  } = props;

  return (
    <Tr key={userName} bg={enabled ? "gray.50" : "gray.200"}>
      <Td>
        <HStack>
          <Link onClick={() => onClick(userName)} isExternal>
            <Box>
              {`${lastName} ${firstName}`}
              <EditIcon mx="2" color={"gray"} fontSize="sm" />
              <Text fontSize={"sm"}>{userName}</Text>
            </Box>
          </Link>
          <Spacer />
          {confirmedStatus == "FORCE_CHANGE_PASSWORD" && (
            <Badge colorScheme="gray" ml={2}>
              確認待ち
            </Badge>
          )}
          {(confirmedStatus == "CONFIRMED" ||
            confirmedStatus == "RESET_REQUIRED") && (
            <Badge colorScheme="green" ml={2}>
              確認済
            </Badge>
          )}
        </HStack>
      </Td>
      <Td>
        {role === ""
          ? ""
          : role.lastIndexOf("Admin") > 0
          ? "Tenant Admin"
          : "Tenant User"}
      </Td>
      <Td>
        {enabled ? (
          <Flex align={"center"}>
            <CheckCircleIcon color={"green.400"} mr="1" />
            有効
          </Flex>
        ) : (
          <Flex align={"center"}>
            <NotAllowedIcon color={"red.500"} mr="1" />
            無効
          </Flex>
        )}
      </Td>
    </Tr>
  );
});
