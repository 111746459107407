import { useCallback, useState } from "react";
import axios from "axios";
import {
  ResponseGetMetricStatistics,
  Datapoint,
} from "../types/api/metricDataResult";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useDataLakeBucket = () => {
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();
  const [datapoints, setDatapoints] = useState<Array<Datapoint>>([]);

  const DATACATALOG_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getBucketsizeBytes = useCallback(
    (
      bucket: string,
      metric: string = "Count",
      statistics: string[] = ["Sum"],
      starttime: string,
      endtime: string,
      period: number
    ) => {
      setLoading(true);

      const atr: string =
        "?bucket=" +
        bucket +
        "&metric=" +
        metric +
        "&statistics=" +
        statistics.join(",") +
        "&starttime=" +
        starttime +
        "&endtime=" +
        endtime +
        "&period=" +
        String(period);
      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .get<ResponseGetMetricStatistics>(
            DATACATALOG_MANAGER_URL + "/datacatalog/bucketsizebytes" + atr,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(async (res) => {
            if (res.status === 200) {
              setDatapoints(res.data.Datapoints);
              setDatapoints(
                res.data.Datapoints.sort((a, b) => {
                  if (a.Timestamp > b.Timestamp) return 1;
                  else return -1;
                })
              );
              setLoading(false);
            } else if (res.status === 403) {
              showMessage({ title: "権限エラー:" + res.data, status: "error" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .catch(() =>
            showMessage({
              title: "テーブルの取得に失敗しました",
              status: "error",
            })
          )
          .finally(() => setLoading(false));
      });
    },
    []
  );

  return {
    getBucketsizeBytes,
    loading,
    datapoints,
  };
};
