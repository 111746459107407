import { useCallback, useState } from "react";
import axios from "axios";
import { MessageConfig, Config } from "../types/api/messageConfig";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";
import moment from "moment-timezone";

export type ConfigArrayItem = { messageId: string; config: Config };
export const useMessageConfig = () => {
  const { showMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const { loginUser, tokenRefresh, selectTenant } = useLoginUser();

  const [configs, setConfigs] = useState<Array<ConfigArrayItem>>([]);
  const MANAGER_API_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  const getMessageConfigs = useCallback(
    (tenantId: string, entityId: string) => {
      setLoading(true);

      new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
        axios
          .get<MessageConfig>(
            `${MANAGER_API_URL}/alert/configs/${tenantId}/${entityId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(async (res) => {
            if (res.status === 200) {
              // Convert to an array of { messageId, config }
              if(res.data.configs) {
                let configsArray: ConfigArrayItem[] = Object.keys(
                  res.data.configs
                ).map((key) => {
                  return {
                    messageId: key,
                    config: res.data.configs[key],
                  };
                });
                // Sort the array by messageId
                configsArray = configsArray.sort((a, b) => {
                  return a.messageId > b.messageId ? 1 : -1; // Ascending order
                });
                setConfigs(configsArray);
              }else{
                setConfigs([]);
              }
              setLoading(false);
            } else if (res.status === 403) {
              showMessage({ title: "権限エラー:" + res.data, status: "error" });
              setLoading(false);
            } else {
              throw new Error(res.statusText);
            }
          })
          .catch((msg) => {
            showMessage({
              title: `監視設定の取得に失敗しました。(${msg})`,
              status: "error",
            });
          }
          )
          .finally(() => setLoading(false));
      });
    },
    []
  );

  const messageConfigEdit = useCallback(
    (
      tenantId: string,
      entityId: string,
      entityType: string,
      configItem: ConfigArrayItem,
      sendMethod : "POST" | "PUT"
    ) => {
      setLoading(true);
      configItem.config.updateUser = loginUser?.userName ?? "";
      configItem.config.updateTime = moment()
        .tz("Asia/Tokyo")
        .format("YYYY-MM-DDTHH:mm:SS");
      const messageId = configItem.messageId;
      let atr: string = "";
      if (selectTenant != "999999") {
        atr = "?tenant=" + selectTenant;
      }
      if (sendMethod === "PUT") {
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
          axios
            .put<any>(
              MANAGER_API_URL +
                `/alert/configs/${tenantId}/${entityId}/${messageId}${atr}`,
              configItem.config,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then(async (res) => {
              if (res.status === 200) {
                showMessage({ title: "更新しました。", status: "success" });
                setLoading(false);
              } else if (res.status === 403) {
                showMessage({
                  title: "権限エラー:" + res.data,
                  status: "error",
                });
                setLoading(false);
              } else {
                throw new Error(res.statusText);
              }
            })
            .then(() => {
              getMessageConfigs(tenantId, entityId);
            })
            .catch((msg) => {
              showMessage({
                title: `監視設定が更新できません。(${msg})`,
                status: "error",
              });
              setLoading(false);
            });
        });
      } else {
        const sendBody: MessageConfig = {
          id: `${entityId}#${tenantId}`,
          tenant_id: tenantId,
          type: entityType,
          configs: {
            m00001: configItem.config,
          },
        };
        new Promise((resolve) => resolve(tokenRefresh())).then((token) => {
          axios
            .post<any>(
              MANAGER_API_URL + `/alert/configs/${tenantId}/${entityId}${atr}`,
              sendBody,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then(async (res) => {
              if (res.status === 200) {
                console.log(res.data);
                showMessage({ title: "追加しました。", status: "success" });
                setLoading(false);
              } else if (res.status === 403) {
                showMessage({
                  title: "権限エラー:" + res.data,
                  status: "error",
                });
                setLoading(false);
              } else {
                throw new Error(res.statusText);
              }
            })
            .then(() => {
              getMessageConfigs(tenantId, entityId);
            })
            .catch((msg) => {
              showMessage({
                title: `監視設定が作成できません。(${msg})`,
                status: "error",
              });
              setLoading(false);
            });
        });
      }
    },
    []
  );

  return {
    getMessageConfigs,
    messageConfigEdit,
    loading,
    configs,
  };
};
