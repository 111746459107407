import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spacer,
  Box,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  IconButton,
  Badge,
  Flex,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

import { Group, UsersInfo } from "../../../types/api/group";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { AddIcon, CloseIcon, Search2Icon } from "@chakra-ui/icons";
import { User } from "../../../types/api/user";

type Props = {
  group: Group;
  isOpen: boolean;
  onInsert: (title: string, description: string, users: UsersInfo[]) => void;
  onUpdate: (
    group_id: string,
    title: string,
    description: string,
    users: UsersInfo[]
  ) => void;
  onDelete: (group_id: string) => void;
  onClose: () => void;
  tenantUsers: User[];
};

export const GroupDetailModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, group, tenantUsers } = props;
  const isAddMode: boolean = group.group_id ? false : true;

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [userName, setUserName] = useState("");
  const [users, setUsers] = useState<UsersInfo[]>([]);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    onModalReset();
  }, [group]);

  const onChangeId = (e: ChangeEvent<HTMLInputElement>) =>
    setId(e.target.value);

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) =>
    setTitle(e.target.value);

  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) =>
    setDescription(e.target.value);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) =>
    setUserName(e.target.value);

  const onClickUpdate = () => {
    props.onUpdate(id, title, description, users);
    props.onClose();
  };

  const onClickCreate = () => {
    props.onInsert(title, description, users);
    props.onClose();
  };

  const onClickDelete = () => {
    if (window.confirm(`グループ「${title}（${id}）」を削除しますか？`)) {
      props.onDelete(id);
      props.onClose();
    }
  };
  const onChangeCell =
    (index: number, key: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const _cells = [...users];
      _cells[index] = { ..._cells[index], [key]: event.target.value };
      setUsers(_cells);
    };
  const onClickListAdd = (userName: string) => {
    if (userName === "") return;
    const empty: UsersInfo = {
      userName: userName,
    };
    setUsers([...users, empty]);
    setUserName("");
  };
  const onClickListDelete = (index: number) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onModalReset = () => {
    setSearchValue("");
    if (group) {
      setId(group.group_id ?? "");
      setTitle(group.title ?? "");
      setDescription(group.description);
      setUsers(group.users);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>グループ設定</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Tabs>
            <TabList>
              <Tab>詳細</Tab>
              <Tab>メンバー</Tab>
              {isAddMode ? <></> : <Tab>その他</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>
                      グループID
                      <Badge colorScheme="gray" ml={2}>
                        変更不可
                      </Badge>
                    </FormLabel>
                    <Input
                      readOnly={true}
                      value={id}
                      placeholder="groupID"
                      onChange={onChangeId}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>グループ名</FormLabel>
                    <Input
                      value={title}
                      placeholder="groupName"
                      onChange={onChangeTitle}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>備考</FormLabel>
                    <Input
                      value={description}
                      placeholder="description"
                      onChange={onChangeDescription}
                    />
                  </FormControl>
                </Stack>
                <HStack mt={4}>
                  <Spacer />
                  {isAddMode ? (
                    <PrimaryButton onClick={onClickCreate}>登録</PrimaryButton>
                  ) : (
                    <PrimaryButton onClick={onClickUpdate}>更新</PrimaryButton>
                  )}
                </HStack>
              </TabPanel>
              <TabPanel>
                <Stack>
                  <Flex mx="1">
                    <InputGroup size={"sm"}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<Search2Icon color="gray.300" />}
                      />
                      <Input
                        value={searchValue}
                        placeholder="search userID (email)"
                        onChange={onChangeSearch}
                      />
                    </InputGroup>
                  </Flex>
                  <FormControl>
                    {/**
                    <HStack>
                      <Input type="email" value={userName} placeholder="Mailアドレスを入力" onChange={onChangeInput} />
                      <PrimaryButton onClick={()=>onClickListAdd(userName)}>追加</PrimaryButton>
                    </HStack>                     
                     */}
                    <Flex fontSize={"sm"} m={1}>
                      選択中のユーザー
                    </Flex>
                    <Flex
                      border={"solid"}
                      borderWidth={1}
                      borderColor={"gray.300"}
                    >
                      <TableContainer>
                        <Table size={"sm"}>
                          <Thead>
                            <Tr>
                              <Th textAlign={"center"}>削除</Th>
                              <Th>ユーザーID</Th>
                              <Th>氏名</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {users.map((cell, i) =>
                              searchValue === "" ||
                              cell.userName.lastIndexOf(searchValue) >= 0 ? (
                                <Tr key={i}>
                                  <Td textAlign={"center"}>
                                    <IconButton
                                      onClick={() => onClickListDelete(i)}
                                      variant="outline"
                                      colorScheme="red"
                                      aria-label="削除"
                                      size={"xs"}
                                      icon={<CloseIcon />}
                                    />
                                  </Td>
                                  <Td>{cell.userName}</Td>
                                  <Td>
                                    {tenantUsers.find((u) => {
                                      return u.userName === cell.userName;
                                    })
                                      ? `${
                                          tenantUsers.find((u) => {
                                            return u.userName === cell.userName;
                                          })?.lastName +
                                          " " +
                                          tenantUsers.find((u) => {
                                            return u.userName === cell.userName;
                                          })?.firstName
                                        }`
                                      : `不明なユーザー`}
                                  </Td>
                                </Tr>
                              ) : (
                                <></>
                              )
                            )}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Flex>
                    <Flex fontSize={"sm"} m={1} mt={3}>
                      他のユーザー
                    </Flex>
                    <Flex
                      border={"solid"}
                      borderWidth={1}
                      borderColor={"gray.300"}
                    >
                      <TableContainer>
                        <Table size={"sm"}>
                          <Thead>
                            <Tr>
                              <Th textAlign={"center"}>追加</Th>
                              <Th>ユーザーID</Th>
                              <Th>氏名</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {tenantUsers
                              .filter((obj) => {
                                return (
                                  searchValue === "" ||
                                  obj.userName.indexOf(searchValue) >= 0
                                );
                              })
                              .map((cell, i) => (
                                <Tr key={users.length + i}>
                                  {users.find((u) => {
                                    return u.userName === cell.userName;
                                  }) ? (
                                    <></>
                                  ) : (
                                    <>
                                      <Td textAlign={"center"}>
                                        <IconButton
                                          onClick={() =>
                                            onClickListAdd(cell.userName)
                                          }
                                          variant="outline"
                                          colorScheme="green"
                                          aria-label="追加"
                                          size={"xs"}
                                          icon={<AddIcon />}
                                        />
                                      </Td>
                                      <Td>{cell.userName}</Td>
                                      <Td>
                                        {cell.lastName + " " + cell.firstName}
                                      </Td>
                                    </>
                                  )}
                                </Tr>
                              ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Flex>
                  </FormControl>
                </Stack>
                <HStack mt={4}>
                  <Spacer />
                  {isAddMode ? (
                    <PrimaryButton onClick={onClickCreate}>登録</PrimaryButton>
                  ) : (
                    <PrimaryButton onClick={onClickUpdate}>更新</PrimaryButton>
                  )}
                </HStack>
              </TabPanel>
              <TabPanel>
                <Stack mt={4} mx={4} spacing={4}>
                  <PrimaryButton onClick={onClickDelete}>
                    グループの削除
                  </PrimaryButton>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
});
