import { memo, FC } from "react";
import { Link, Td, Textarea, Tr } from "@chakra-ui/react";
import { EditIcon, EmailIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";

type Props = {
  type: string;
  count: number;
  onClick: (type: string) => void;
};

export const OrionTypeRow: FC<Props> = memo((props) => {
  const {
    type,
    count,
    onClick,
  } = props;

  return (
    <Tr key={type} bg="gray.50">
      <Td>
        <Link onClick={() => onClick(type)} isExternal>
          {type}
          <EditIcon mx="2" color={"gray"} fontSize="sm" />
        </Link>
      </Td>
      <Td>{count}</Td>
      {/* <Td>
        <Box
          border="1px"
          p="2"
          borderColor={"gray.300"}
          dangerouslySetInnerHTML={{ __html: htmlPart }}
        ></Box>
      </Td> */}
    </Tr>
  );
});
