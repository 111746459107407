import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  Badge,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { ApiKey } from "../../../types/api/apikey";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { KeyInfo, UsagePlan } from "../../../types/api/usageplan";

type Props = {
  apiKey: ApiKey | undefined;
  isOpen: boolean;
  isAdmin?: boolean;
  onUpdate: (apiKey: ApiKey) => void;
  receiveDeveloperUsagePlan: UsagePlan;
  receiveDeveloperUsagePlanKeys: Array<KeyInfo>;
  onReceiveDeveloperUsagePlanInsertKey: (
    usagePlanId: string,
    keyId: string
  ) => void;
  onReceiveDeveloperUsagePlanDeleteKey: (
    usagePlanId: string,
    keyId: string
  ) => void;
  developerUsagePlan: UsagePlan;
  developerUsagePlanKeys: Array<KeyInfo>;
  onDeveloperUsagePlanInsertKey: (usagePlanId: string, keyId: string) => void;
  onDeveloperUsagePlanDeleteKey: (usagePlanId: string, keyId: string) => void;
  onClose: () => void;
};

export const DevPortalApiKeyDetailModal: FC<Props> = memo((props) => {
  const {
    isOpen,
    onClose,
    apiKey,
    receiveDeveloperUsagePlan,
    receiveDeveloperUsagePlanKeys,
    onReceiveDeveloperUsagePlanInsertKey,
    onReceiveDeveloperUsagePlanDeleteKey,
    developerUsagePlan,
    developerUsagePlanKeys,
    onDeveloperUsagePlanInsertKey,
    onDeveloperUsagePlanDeleteKey,
  } = props;

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [
    receiveDeveloperUsagePlanEnabled,
    setReceiveDeveloperUsagePlanEnabled,
  ] = useState<boolean>(true);
  const [developerUsagePlanEnabled, setDeveloperUsagePlanEnabled] =
    useState<boolean>(true);
  useEffect(() => {
    onModalReset();
  }, [apiKey]);

  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) =>
    setDescription(e.target.value);

  const onClickUpdate = () => {
    if (apiKey) {
      apiKey.name = name;
      if (description) {
        if (description !== "") {
          apiKey.description = description;
        } else {
          delete apiKey.description;
        }
      } else {
        delete apiKey.description;
      }
      props.onUpdate(apiKey);

      //
      if (receiveDeveloperUsagePlanEnabled) {
        if (receiveDeveloperUsagePlan && receiveDeveloperUsagePlan.id) {
          if (
            !receiveDeveloperUsagePlanKeys?.some((key) => key.id === apiKey.id)
          ) {
            onReceiveDeveloperUsagePlanInsertKey(
              receiveDeveloperUsagePlan.id,
              apiKey.id
            );
          }
        } else {
          setReceiveDeveloperUsagePlanEnabled(false);
        }
      } else {
        if (receiveDeveloperUsagePlan && receiveDeveloperUsagePlan.id) {
          if (
            receiveDeveloperUsagePlanKeys?.some((key) => key.id === apiKey.id)
          ) {
            onReceiveDeveloperUsagePlanDeleteKey(
              receiveDeveloperUsagePlan.id,
              apiKey.id
            );
          }
        }
      }

      //
      if (developerUsagePlanEnabled) {
        if (developerUsagePlan && developerUsagePlan.id) {
          if (!developerUsagePlanKeys?.some((key) => key.id === apiKey.id)) {
            onDeveloperUsagePlanInsertKey(developerUsagePlan.id, apiKey.id);
          }
        }
      } else {
        if (developerUsagePlan && developerUsagePlan.id) {
          if (developerUsagePlanKeys?.some((key) => key.id === apiKey.id)) {
            onDeveloperUsagePlanDeleteKey(developerUsagePlan.id, apiKey.id);
          }
        }
      }

      props.onClose();
    }
  };

  const onModalReset = () => {
    if (apiKey) {
      setId(apiKey.id ?? "");
      setName(apiKey.name ?? "");
      setDescription(apiKey.description ?? "");
      setReceiveDeveloperUsagePlanEnabled(
        receiveDeveloperUsagePlanKeys?.some((key) => key.id === apiKey.id)
      );
      setDeveloperUsagePlanEnabled(
        developerUsagePlanKeys?.some((key) => key.id === apiKey.id)
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>ApiKey詳細</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Tabs>
            <TabList>
              <Tab>詳細</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>
                      ID
                      <Badge colorScheme="gray" ml={2}>
                        変更不可
                      </Badge>
                    </FormLabel>
                    <Input readOnly={true} value={id} placeholder="groupID" />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      名称
                      <Badge colorScheme="gray" ml={2}>
                        変更不可
                      </Badge>
                    </FormLabel>
                    <Input readOnly={true} value={name} placeholder="name" />
                  </FormControl>
                  <FormControl>
                    <FormLabel>備考</FormLabel>
                    <Input
                      value={description}
                      placeholder="description"
                      onChange={onChangeDescription}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>データ受信使用量プラン</FormLabel>
                    <Checkbox
                      isChecked={receiveDeveloperUsagePlanEnabled}
                      onChange={(e) =>
                        setReceiveDeveloperUsagePlanEnabled(e.target.checked)
                      }
                    />
                  </FormControl>

                  {developerUsagePlan.id ? (
                    <>
                      <FormControl>
                        <FormLabel>テナント毎 使用量プラン</FormLabel>
                        <Checkbox
                          isChecked={developerUsagePlanEnabled}
                          onChange={(e) =>
                            setDeveloperUsagePlanEnabled(e.target.checked)
                          }
                        />
                      </FormControl>
                    </>
                  ) : (
                    <></>
                  )}
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <PrimaryButton onClick={onClickUpdate}>更新</PrimaryButton>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
