import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  HStack,
  Tabs,
  TabPanels,
  TabPanel,
  Spacer,
  Badge,
  Tab,
  TabList,
  InputGroup,
  Select,
  Flex,
  InputLeftElement,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  AlertIcon,
  Alert,
  RadioGroup,
  Radio,
  Switch,
} from "@chakra-ui/react";

import { ConfigArrayItem } from "../../../hooks/useMessageConfigs";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { mailConst, MessageMetric } from "../../../types/api/messageConfig";
import { Metric } from "../../../types/api/customMetric";

import { AddIcon } from "@chakra-ui/icons";
import { CloseIcon, Search2Icon } from "@chakra-ui/icons";
type Props = {
  configArrayItem: ConfigArrayItem;
  customMetrics: Array<Metric>;
  isOpen: boolean;
  onUpdate: (configArrayItem: ConfigArrayItem) => void;
  onClose: () => void;
};

type StateType = (typeof mailConst.state)[keyof typeof mailConst.state];

type EditMetric = {
  metric: MessageMetric;
  mainKind: "EntityId" | "EntityType" | "Driver" | "Unknown";
  mainValue: String;
  selected: boolean;
};

export const MessageConfigDetailModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, configArrayItem } = props;
  const isAddMode: boolean = configArrayItem.messageId === "new";

  const [configName, setConfigName] = useState("");

  const [state, setState] = useState<StateType>("enable");

  // 検索条件
  const [searchValue, setSearchValue] = useState("");
  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  // 送信元アドレス、送信先アドレス
  const [FromEmailAddress, setFromEmailAddress] = useState("");
  const [FromEmailAddressError, setFromEmailAddressError] = useState("");

  const [ToEmailAddress, setToEmailAddress] = useState("");
  const [ToEmailAddressError, setToEmailAddressError] = useState("");

  const [interval, setInterval] = useState("");
  const [intervalError, setIntervalError] = useState("");

  const [condition2, setCondition2] = useState("");
  const [condition2Error, setCondition2Error] = useState("");

  const [comparison, setComparison] = useState("");

  const [emailSubject, setEmailSubject] = useState("");

  const [allMetrics, setAllMetrics] = useState<Array<EditMetric>>([]);

  useEffect(() => {
    onModalReset();
  }, [configArrayItem]);

  const onChangeConfigName = (e: ChangeEvent<HTMLInputElement>) => setConfigName(e.target.value);
  const onChangeEmailSubject = (e: ChangeEvent<HTMLInputElement>) => setEmailSubject(e.target.value);

  const onChangeComparison = (e: ChangeEvent<HTMLSelectElement>) => {
    setComparison(e.target.value);
  };

  // モーダルのリセット（初期表示）
  const onModalReset = () => {
    if (configArrayItem) {
      setConfigName(configArrayItem.config.name ?? "");
      setState(configArrayItem.config.state);
      setEmailSubject(configArrayItem.config.notifications.templateData.subject ?? "");
      setFromEmailAddress(configArrayItem.config.notifications.sourceAddress);
      setToEmailAddress(configArrayItem.config.notifications.toAddressList.join(","));
      setInterval(configArrayItem.config.conditions.interval ?? "");
      setCondition2(configArrayItem.config.conditions.items[0]?.condition2 ?? "");
      setComparison(configArrayItem.config.conditions.items[0]?.comparison ?? "");

      // 選択中のメトリクスと選択可能なメトリクスを結合
      const messageMetrics: EditMetric[] =
        configArrayItem.config.Metrics?.map((cell) => {
          // Dimensionsのままだと使いにくいので、EntityId, EntityType, DriverNameのメイン項目を設定
          const EntityId = cell.Dimensions.find((d) => d.Name === "EntityId")?.Value;
          const EntityType = cell.Dimensions.find((d) => d.Name === "EntityType")?.Value;
          const DriverName = cell.Dimensions.find((d) => d.Name === "DriverName")?.Value;
          if (EntityId !== undefined) {
            return { metric: cell, selected: true, mainKind: "EntityId", mainValue: EntityId };
          }
          if (EntityType !== undefined) {
            return { metric: cell, selected: true, mainKind: "EntityType", mainValue: EntityType };
          }
          if (DriverName !== undefined) {
            return { metric: cell, selected: true, mainKind: "Driver", mainValue: DriverName };
          }
          return { metric: cell, selected: true, mainKind: "Unknown", mainValue: JSON.stringify(cell.Dimensions) };
        }) ?? [];

      const selectableMetrics: EditMetric[] = props.customMetrics
        // 無関係のメトリクスを除外
        .filter((cell) =>
          cell.Dimensions.some(
            (dimension) =>
              dimension.Name === "EntityId" || dimension.Name === "EntityType" || dimension.Name === "DriverName"
          )
        )
        .map((cell) => {
          // Dimensionsのままだと使いにくいので、EntityId, EntityType, DriverNameのメイン項目を設定
          const EntityId = cell.Dimensions.find((d) => d.Name === "EntityId")?.Value;
          const EntityType = cell.Dimensions.find((d) => d.Name === "EntityType")?.Value;
          const DriverName = cell.Dimensions.find((d) => d.Name === "DriverName")?.Value;
          if (EntityId !== undefined) {
            return {
              metric: { ...cell, Comment: EntityId },
              selected: false,
              mainKind: "EntityId",
              mainValue: EntityId,
            };
          }
          if (EntityType !== undefined) {
            return {
              metric: { ...cell, Comment: EntityType },
              selected: false,
              mainKind: "EntityType",
              mainValue: EntityType,
            };
          }
          if (DriverName !== undefined) {
            return {
              metric: { ...cell, Comment: DriverName },
              selected: false,
              mainKind: "Driver",
              mainValue: DriverName,
            };
          }
          return {
            metric: { ...cell, Comment: JSON.stringify(cell.Dimensions) },
            selected: false,
            mainKind: "Unknown",
            mainValue: JSON.stringify(cell.Dimensions),
          };
        });

      // 選択中のメトリクスと選択可能なメトリクスを結合しソート
      const uniqueMetrics = [
        ...messageMetrics,
        ...selectableMetrics.filter(
          (cell) => !messageMetrics.some((m) => m.mainKind === cell.mainKind && m.mainValue === cell.mainValue)
        ),
      ].sort((a, b) => {
        if (a.mainValue.toLowerCase() < b.mainValue.toLowerCase()) return -1;
        return 1;
      });

      setAllMetrics(uniqueMetrics);
    }

    setSearchValue("");
    setFromEmailAddressError("");
    setToEmailAddressError("");
    setIntervalError("");
    setCondition2Error("");

  };

  // データの更新
  const onClickUpdate = () => {
    configArrayItem.config.name = configName;
    configArrayItem.config.state = state;
    configArrayItem.config.Metrics = allMetrics.filter((m) => m.selected).map((m) => ({ ...m.metric }));
    configArrayItem.config.notifications.sourceAddress = FromEmailAddress.trim();
    configArrayItem.config.notifications.toAddressList = ToEmailAddress.split(/\s+/)
      .join("")
      .split(",")
      .filter((email) => email !== "");
    configArrayItem.config.notifications.templateData.subject = emailSubject;
    configArrayItem.config.conditions.interval = interval;
    configArrayItem.config.conditions.items = [{ condition1: "sum", condition2: condition2, comparison: comparison }];
    props.onUpdate(configArrayItem);
    props.onClose();
  };

  // 削除
  const onClickDelete = () => {
    if (window.confirm(`通知設定「${configName}」を削除しますか？`)) {
      configArrayItem.config.state = "deleted";
      configArrayItem.config.notifications.sourceAddress = "";
      configArrayItem.config.notifications.toAddressList = [];
      props.onUpdate(configArrayItem);
      props.onClose();
    }
  };

  // fromEmailAddressのチェック
  const checkFromEmailAddress = (fromEmailAddress: string) => {
    // メールアドレスの形式チェック
    if (fromEmailAddress.trim() !== "") {
      const emailError = !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(fromEmailAddress);
      setFromEmailAddressError(emailError ? "メールアドレスの形式が不正です" : "");
    } else {
      setFromEmailAddressError("");
    }
  };

  // toEmailAddressのチェック
  const checkToEmailAddress = (toEmailAddress: string) => {
    // メールアドレスの形式チェック
    if (toEmailAddress.trim() !== "") {
      const emailList = toEmailAddress.split(/\s+/).join("").split(",");
      const emailError = emailList.some(
        (email) => !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) && email !== ""
      );
      setToEmailAddressError(emailError ? "メールアドレスの形式が不正です" : "");
    } else {
      setToEmailAddressError("");
    }
  };

  // intervalのチェック
  const checkInterval = (interval: string) => {
    setIntervalError(!/^[1-9]\d*$/.test(interval) ? "1以上の整数値を入力してください" : "");
  };

  // condition2のチェック
  const checkCondition2 = (condition2: string) => {
    setCondition2Error(!/^\d+$/.test(condition2) ? "整数値を入力してください" : "");
  };

  // 監視対象の編集
  const onChangeCell = (index: number, key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const _cells = [...allMetrics];
    _cells[index] = { ..._cells[index], metric: { ..._cells[index].metric, [key]: event.target.value } };
    setAllMetrics(_cells);
  };
  const onChangeSwitch = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const _cells = [...allMetrics];
    _cells[index] = { ..._cells[index], selected: event.target.checked };
    setAllMetrics(_cells);
  };

  // 送信元アドレス、送信先アドレスの編集
  const onChangeFromEmailAddress = (e: ChangeEvent<HTMLInputElement>) => setFromEmailAddress(e.target.value);
  const onChangeToEmailAddress = (e: ChangeEvent<HTMLInputElement>) => setToEmailAddress(e.target.value);

  // 監視対象の行表示
  const renderRow = (cell: EditMetric, i: number) => {
    const visible =
      cell.metric.Dimensions.some(
        (dimension) => dimension.Name !== "Tenant" && dimension.Value.toLowerCase().includes(searchValue.toLowerCase())
      ) || (cell.metric.Comment ?? "").toLowerCase().includes(searchValue.toLowerCase());
    return (
      <Tr key={i} hidden={!visible}>
        <Td textAlign={"center"}>
          <FormControl display="flex" alignItems="center">
            <Switch id="email-alerts" isChecked={cell.selected} onChange={onChangeSwitch(i)} />
          </FormControl>
        </Td>
        <Td>{cell.mainKind}</Td>
        <Td><Flex overflowX={"auto"} overflowY={"hidden"} maxWidth={"25rem"} whiteSpace={"nowrap"} >{cell.mainValue}</Flex></Td>
        <Td>
          <Input
            minWidth={"15rem"}
            size={"sm"}
            onChange={onChangeCell(i, "Comment")}
            value={cell.metric.Comment ?? ""}
            disabled={!cell.selected}
          />
        </Td>
      </Tr>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      trapFocus={false}
      size={"5xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>監視メール設定</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Stack>
            <Tabs>
              <TabList>
                <Tab>通知設定</Tab>
                <Tab>監視対象設定</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack spacing={4}>
                    <FormControl>
                      <FormLabel>
                        通知設定名
                        <Badge colorScheme="red" ml={2}>
                          必須
                        </Badge>
                      </FormLabel>
                      <HStack>
                        <InputGroup>
                          <Input value={configName} placeholder="ConfigName" onChange={onChangeConfigName} />
                        </InputGroup>
                        <RadioGroup value={state} onChange={setState}>
                          <Radio value={"enable"}>有効</Radio>
                          <Radio value={"disable"}>無効</Radio>
                        </RadioGroup>
                      </HStack>
                    </FormControl>
                    <FormControl>
                      <FormLabel>
                        メール件名
                        <Badge colorScheme="red" ml={2}>
                          必須
                        </Badge>
                      </FormLabel>
                      <Input value={emailSubject} onChange={(e) => onChangeEmailSubject(e)} placeholder={"Subject"} />
                    </FormControl>
                    <FormControl>
                      <HStack>
                        <FormLabel width="8rem">送信元アドレス</FormLabel>
                        <Input
                          type="email"
                          value={FromEmailAddress}
                          onChange={(e) => onChangeFromEmailAddress(e)}
                          onBlur={(e) => checkFromEmailAddress(e.target.value)}
                          placeholder={"email"}
                        />
                      </HStack>
                      {FromEmailAddressError && (
                        <FormLabel pl="8rem">
                          <Alert status="error" variant="top-accent">
                            <AlertIcon />
                            {FromEmailAddressError}
                          </Alert>
                        </FormLabel>
                      )}
                      <FormLabel pl="8rem">
                        <Flex>
                          <Text>※検証済の送信元アドレスを指定してください。</Text>
                        </Flex>
                      </FormLabel>
                      <HStack>
                        <FormLabel width="8rem">送信先アドレス</FormLabel>
                        <Input
                          value={ToEmailAddress}
                          onChange={(e) => onChangeToEmailAddress(e)}
                          onBlur={(e) => checkToEmailAddress(e.target.value)}
                          placeholder={"mail1,mail2,mail3,..."}
                        />
                      </HStack>
                      {ToEmailAddressError && (
                        <FormLabel pl="8rem">
                          <Alert status="error" variant="top-accent">
                            <AlertIcon />
                            {ToEmailAddressError}
                          </Alert>
                        </FormLabel>
                      )}
                      <FormLabel pl="8rem">
                        <Flex>
                          <Text>※複数の場合は「,」で区切って設定してください。</Text>
                        </Flex>
                      </FormLabel>
                    </FormControl>
                    <FormControl>
                      <HStack>
                        <FormLabel width="8rem">監視間隔</FormLabel>
                        <Input
                          type="number"
                          width="8rem"
                          value={interval}
                          onChange={(e) => setInterval(e.target.value)}
                          onBlur={(e) => {
                            checkInterval(e.target.value);
                          }}
                        />
                        <FormLabel>時間</FormLabel>
                        {intervalError && (
                          <FormLabel pl="1rem">
                            <Alert status="error" variant="left-accent">
                              <AlertIcon />
                              {intervalError}
                            </Alert>
                          </FormLabel>
                        )}
                      </HStack>
                    </FormControl>
                    <FormControl>
                      <HStack>
                        <FormLabel width="8rem">判定式</FormLabel>
                        <HStack>
                          <FormLabel whiteSpace="nowrap">データ件数</FormLabel>
                          <Select value={comparison} onChange={onChangeComparison}>
                            {Object.entries(mailConst.comparison).map(([key, value]) => (
                              <option key={key} value={key}>
                                {value}
                              </option>
                            ))}
                          </Select>
                          <Input
                            type="number"
                            value={condition2}
                            onChange={(e) => setCondition2(e.target.value)}
                            onBlur={(e) => {
                              checkCondition2(e.target.value);
                            }}
                          />
                        </HStack>
                        {condition2Error && (
                          <FormLabel>
                            <Alert status="error" variant="left-accent">
                              <AlertIcon />
                              {condition2Error}
                            </Alert>
                          </FormLabel>
                        )}
                      </HStack>
                    </FormControl>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack>
                    <Flex mx="1">
                      <InputGroup size={"sm"}>
                        <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.300" />} />
                        <Input
                          value={searchValue}
                          placeholder="search: EntityID,EntityType,DriverName,SendMessage"
                          onChange={onChangeSearch}
                        />
                      </InputGroup>
                    </Flex>
                    <FormControl>
                      <Flex fontSize={"sm"} m={1}>
                        監視対象
                      </Flex>
                      <Flex border={"solid"} borderWidth={1} borderColor={"gray.300"}>
                        <TableContainer>
                          <Table size={"sm"}>
                            <Thead>
                              <Tr>
                                <Th textAlign={"center"}>選択</Th>
                                <Th>分類</Th>
                                <Th>内容</Th>
                                <Th width="100%">送信メッセージ</Th>
                              </Tr>
                            </Thead>
                            <Tbody>{allMetrics.map((cell, i) => renderRow(cell, i))}</Tbody>
                          </Table>
                        </TableContainer>
                      </Flex>
                    </FormControl>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <HStack mt={4}>
              <Spacer />
              {!isAddMode && state === "disable" && <PrimaryButton onClick={onClickDelete}>削除</PrimaryButton>}
              {state !== "deleted" && (
                <PrimaryButton
                  onClick={onClickUpdate}
                  disabled={
                    configName === "" ||
                    emailSubject === "" ||
                    FromEmailAddressError !== "" ||
                    ToEmailAddressError !== "" ||
                    intervalError !== "" ||
                    condition2Error !== ""
                  }
                >
                  {isAddMode ? "追加" : "更新"}
                </PrimaryButton>
              )}
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
});
